import styled, {css} from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


const Colores = {
    border: "#E8EAEE",
    inputborder: "#3C8DBC",
    error: `linear-gradient(90deg,rgba(255,0,0,1), #bb2929  70%)`,
    success: "#1ed12d",
    line: "#B6BFCF"
}
const Formn = styled.form`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
  

    @media (max-width: 800px) {
        grid-template-columns: 1fr;
    }
`;
const Label = styled.label`
    display: block;
    font-weight: 700;
    padding 1.1vh;
    min-height: 40px;
    cursor: pointer;
    ${props => props.valid === 'false' && css`
		color: ${Colores.error};
	`}
`;
const LabelCIvu = styled.label`
  
    font-size: 18px;
    padding 10px;
    display: block;
    color:  #444444;
  
`;

const GroupInput = styled.div`
    position: relative;
    z-index: 90;
`;

const Input = styled.input`
    width: 100%;
    background: #fff;
    border-radius: 3px;
    height: 34px;
    line-height: 45px;
    padding: 0 40px 0 10px;
    transition: .3s ease all;
    border: 1px solid ${Colores.line};

    &:focus{
        border: 1px solid ${Colores.inputborder};
        outline: none;
        box-shadow: 3px 0px 30px rgba(163,163,163, 0.4);
    }

    ${props => props.valid === 'true' && css`
		border: 3px solid transparent;
	`}

	${props => props.valid === 'false' && css`
        border: 3px solid ${Colores.error} !important;
    
	`}
`;

const LeyendError = styled.p`
    font-size: 12px;
    margin-botton: 0;
    color: ${Colores.error};
    display: none;
    ${props => props.valid === 'true' && css`
		display: none;
	`}

    ${props => props.valid === 'false' && css`
        display:block;
    
	`}
`;

const IconValidation = styled(FontAwesomeIcon)`
    position: absolute;
    right: 10px;
    bottom: 14px;
    z-index: 100;
    font-size: 16px;
    opacity: 0;
    ${props => props.valid === 'true' && css`
        opacity: 1;
        color: ${Colores.success};
	`}

    ${props => props.valid === 'false' && css`
        opacity: 1;
        color: ${Colores.error};
    
	`}
`;

const ContainerTerms = styled.div`
	grid-column: span 2;

	input {
		margin-right: 10px;
	}

    @media (max-width: 800px){
		grid-column: span 1;
	}
`;

const ContainerButtonCA = styled.div`
	display: flex;
	// flex-direction: column;
	// align-items: center;
	grid-column: span 2;
    

	@media (max-width: 800px){
		grid-column: span 1;
	}
`;

const Buttonn = styled.button`
	height: 34px;
	line-height: 45px;
	width: 40%;
	background: #00A65A;
	color: #FFFFFF;
	font-weight: bold;
	border: none;
	border-radius: 3px;
	cursor: pointer;
	transition: .1s ease all;
    padding-bottom: 3%;
	&:hover {
		box-shadow: 3px 0px 30px rgba(163,163,163, 1);
	}
`;
const MsjSuccess = styled.p`
	font-size: 14px;
	color: ${Colores.success};
`;

const MsjFail = styled.div`
	height: 45px;
	line-height: 45px;
	background: ${Colores.error};
	padding: 0px 15px;
	border-radius: 3px;
	grid-column: span 2;
	p {
		margin: 0;
	} 
	b {
		margin-left: 10px;
	}
`;
 const SelectSW = styled.select`
    width: 100%;
    background: #fff;
    border-radius: 3px;
    height: 34px;
    line-height: 45px;
    padding: 0 40px 0 10px;
    transition: .3s ease all;
    border: 1px solid ${Colores.line};

    &:focus{
    border: 1px solid ${Colores.inputborder};
    outline: none;
    box-shadow: 3px 0px 30px rgba(163,163,163, 0.4);
}
`;
const SelectPM = styled.select`
    width: 100%;
    background: #fff;
    border-radius: 3px;
    height: 34px;
    line-height: 45px;
    padding: 0 40px 0 10px;
    transition: .3s ease all;
    border: 1px solid ${Colores.line};

    &:focus{
    border: 1px solid ${Colores.inputborder};
    outline: none;
    box-shadow: 3px 0px 30px rgba(163,163,163, 0.4);
}
`;
const SelectT = styled.select`
    width: 100%;
    background: #fff;
    border-radius: 3px;
    height: 34px;
    line-height: 45px;
    padding: 0 40px 0 10px;
    transition: .3s ease all;
    border: 1px solid ${Colores.line};

    &:focus{
    border: 1px solid ${Colores.inputborder};
    outline: none;
    box-shadow: 3px 0px 30px rgba(163,163,163, 0.4);
}
`;

const InputState = styled.input`
    width: 100%;
    background: #fff;
    border-radius: 3px;
    height: 34px;
    line-height: 45px;
    padding: 0 40px 0 10px;
    transition: .3s ease all;
    border: 1px solid ${Colores.line};
    margin-right: 2%;

   

    &:focus{
        border: 1px solid ${Colores.inputborder};
        outline: none;
        box-shadow: 3px 0px 30px rgba(163,163,163, 0.4);
    }

    ${props => props.valid === 'true' && css`
		border: 3px solid transparent;
	`}

	${props => props.valid === 'false' && css`
        border: 3px solid ${Colores.error} !important;
    
	`}
`;
const InputZip = styled.input`
    width: 100%;
    background: #fff;
    border-radius: 3px;
    height: 34px;
    line-height: 45px;
    padding: 0 40px 0 10px;
    transition: .3s ease all;
    border: 1px solid ${Colores.line};
    margin-right: 2%;

   

    &:focus{
        border: 1px solid ${Colores.inputborder};
        outline: none;
        box-shadow: 3px 0px 30px rgba(163,163,163, 0.4);
    }

    ${props => props.valid === 'true' && css`
		border: 3px solid transparent;
	`}

	${props => props.valid === 'false' && css`
        border: 3px solid ${Colores.error} !important;
    
	`}
`;
const SelectService = styled.select`
    width: 45.09%;
    background: #fff;   
    border-radius: 3px;
    height: 34px;
    line-height: 45px;
    padding: 0 40px 0 10px;
    transition: .3s ease all;
    border: 1px solid ${Colores.line};
    margin-left: 1%;
 
    
    


&:focus{
border: 1px solid ${Colores.inputborder};
outline: none;
box-shadow: 3px 0px 30px rgba(163,163,163, 0.4);
}
`;
const SelectPhysiTer = styled.select`
    width: 38.57%;
    background: #fff;
    border-radius: 3px;
    height: 34px;
    line-height: 45px;
    padding: 0 40px 0 10px;
    transition: .3s ease all;
    border: 1px solid ${Colores.line};
    margin-left:  2%;
    
    &:focus{
    border: 1px solid ${Colores.inputborder};
    outline: none;
    box-shadow: 3px 0px 30px rgba(163,163,163, 0.4);
}
`;
const InputCCnumber = styled.input`
    width: 100%;
    background: #fff;
    border-radius: 3px;
    height: 34px;
    line-height: 34px;
    padding: 0 40px 0 10px;
    transition: .3s ease all;
    border: 1px solid ${Colores.line};
    margin-right:  2%;

    &:focus{
        border: 1px solid ${Colores.inputborder};
        outline: none;
        box-shadow: 3px 0px 30px rgba(163,163,163, 0.4);
    }

    ${props => props.valid === 'true' && css`
		border: 3px solid transparent;
	`}

	${props => props.valid === 'false' && css`
        border: 3px solid ${Colores.error} !important;
    
	`}
`;
const Selectll = styled.select`
    width: 48%;
    background: #fff;
    border-radius: 3px;
    height: 34px;
    line-height: 45px;
    padding: 0 40px 0 10px;
    transition: .3s ease all;
    border: 1px solid ${Colores.line};
    margin-right: 2%;

    &:focus{
    border: 1px solid ${Colores.inputborder};
    outline: none;
    box-shadow: 3px 0px 30px rgba(163,163,163, 0.4);
}
`;
const PService = styled.p`
    width:  48%;
    border-radius: 3px;
    height: 35px;
    border: 1px solid ${Colores.line};
    &:focus{
        border: 3px solid ${Colores.border};
        outline: none;
        box-shadow: 3px 0px 30px rgba(163,163,163, 0.4);
    }
`;

const Imglogo = styled.img`
    width:  204px;
    height: 40px;
  
`;
const UlNavVar = styled.ul`
    margin-left: 5%;
`;
const LiNavVarR = styled.li`
    display:inline-block;
    margin-left: 86.5%;
    margin-top: -23px;
    color: #555555;

`;
const LiNavVarI = styled.li`
    display:inline-block;
    margin-left: 60%;
    margin-top: 20px;
    color: #555555;
`;
const Titulo = styled.p`
 
    color: #333333;
    font-size: 24px;
`;
const LiBreadCrumb = styled.li`
    background:#E4E4E4
 
`;
const LabelDash = styled.label`
    background:#E4E4E4;
    font-size: 12px;
    color:#444444;
`;



export {
        Formn,
        Label,
        GroupInput,
        Input,
        LeyendError,
        IconValidation,
        ContainerTerms,
        ContainerButtonCA,
        Buttonn,
        MsjFail,
        MsjSuccess,
        SelectPM, 
        SelectT,
        SelectSW, 
        InputState,
        SelectService,
        SelectPhysiTer, 
        InputCCnumber,
        Selectll,
        LabelCIvu,
        PService, 
        Imglogo, 
        UlNavVar,
        LiNavVarR,
        LiNavVarI,
        Titulo,
        LiBreadCrumb,
        LabelDash,
        InputZip
    };