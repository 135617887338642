import React from "react";
import axios from "axios";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Swal from "sweetalert2";

/**
 * Componente encargado de registrar un servicio
 */
class ServiceRegister extends React.Component {

    /**
     * Función constructora para inicializar el estado del componente.
     *
     * @param props - El objeto de props pasado al componente.
     */
    constructor(props) {
        super(props);
        this.state = {
            role: -1,
            token: "",
            show: false,
            loading: false,
            service: {
                serviceId: 0,
                serviceName: "",
                serviceDescription: "",
                serviceValue: 0,
                active: true,
                referralPayment: 0,
                duration: 0
            },
            initial: props.serviceObj,
            validated: false,
            validForm: true,
            edit: props.edit
        }
    }

    /**
     * Actualiza el componente después de que las props hayan cambiado.
     *
     * @param prevProps - Las props anteriores del componente.
     */
    componentDidUpdate(prevProps) {
        // Verificar si los props relevantes han cambiado
        if (this.props.serviceObj !== prevProps.serviceObj) {
            this.setState({ initial: this.props.serviceObj })
            if (this.state.edit) {
                this.setState({ service: this.props.serviceObj })
            }
        }

    }

    /**
     * Función componentDidMount.
     *
     * Esta función se llama cuando el componente se ha montado en el DOM. Recupera el token del almacenamiento local,
     * extrae el encabezado, la carga útil y la firma del token, decodifica la carga útil y establece el token y el rol en el
     * estado del componente. Si el estado 'edit' es verdadero, también establece el estado 'service' en el valor 'initial'.
     *
     */
    componentDidMount() {
        const token = localStorage.getItem('loggedIvuUser')
        const [header, payload, signature] = token.split('.');

        // Decodificar la carga útil (payload) que contiene la información del usuario
        const decodedPayload = JSON.parse(atob(payload));

        const role = decodedPayload['role']
        this.setState({ token: token, role: role })

        if (this.state.edit) {
            this.setState({ service: this.state.initial })
        }
    }

    /**
* Metodo utilizado para desplegar el modal
*/
    handleShow = () => {
        this.setState({ show: true })

    };

    /**
    * Metodo utilizado para ocultar el modal
    */
    handleClose = () => {
        if (this.state.edit) {
            this.setState({ show: false, service: this.state.initial, validated: false, validForm: true })
        } else {
            this.setState({ show: false, validated: false, validForm: true })
            this.reset()
        }
    };

    /**
     * Restablece el estado del objeto del servicio.
     */
    reset() {
        this.setState({
            service: {
                serviceId: 0,
                serviceName: "",
                serviceDescription: "",
                serviceValue: 0,
                active: true,
                referralPayment: 0,
                duration: 0
            }
        })
    }

    /**
* Metodo utilizado para validar el formulario 
* @param {*} e Elemento que disparó el evento
* @returns True si el formulario se encuentra correcto, False en caso contrario.
*/
    valdidateForm = (e) => {
        const form = document.getElementById("formUser");
        this.setState({
            validated: true
        })
        if (form.checkValidity() === false) {
            e.preventDefault();
            e.stopPropagation();

            this.setState({
                validForm: false
            })
            return false
        } else {

            this.setState({
                validForm: true
            })
            return true
        }
    }

    /**
     * Metodo que actualiza el valor de los atributos del estado de acuerdo a lo ingresado en el formulario
     * @param {*} e evento del input
     */
    handleChange = (e) => {
        var { name } = e.target;
        let value = e.target.type === "checkbox" ? e.target.checked : e.target.value

        if (name === "serviceValue" || name === "referralPayment" || name === "duration") {
            value = value
        }
        this.setState((prevState) => ({
            service: { ...prevState.service, [name]: value }
        }));

        if (name !== "active") {
            this.valdidateForm(e)
        }

    };

    /**
     * Metodo encargado de guardar el servicio nuevo
     * @param {*} e evento disparado por el formulario
     */
    handleSubmit = (e) => {
        e.preventDefault();
        e.stopPropagation();


        if (!this.state.edit) {
            const config = {
                method: 'post',
                url: global.URLBack + `/dashBoard/service`,
                headers: {
                    'Authorization': 'Bearer ' + this.state.token,
                    "Content-Type": "application/json",
                },
                data: this.state.service,
                withCredentials: true
            }
            this.setState({ loading: true })
            axios(config).then(response => {
                this.setState({ loading: false })
                if (Number(response.status) === 201) {
                    Swal.fire({
                        icon: 'success',
                        title: 'Servicio creado exitosamente'
                    })
                } else {
                    Swal.fire({
                        title: "Ha ocurrido un erro al crear el servicio",
                        icon: "error"
                    })
                }
                this.handleClose()
                this.props.updateServices(response.data)
            }).catch(err => {
                this.setState({ loading: false })
                Swal.fire({
                    title: "Ha ocurrido un erro al crear el servicio",
                    icon: "error"
                })
            })
        } else {
            const config1 = {
                method: 'put',
                url: global.URLBack + `/dashBoard/service/${this.state.service.serviceId}`,
                headers: {
                    'Authorization': 'Bearer ' + this.state.token,
                    "Content-Type": "application/json",
                },
                data: this.state.service,
                withCredentials: true
            }
            this.setState({ loading: true })
            axios(config1).then(res => {

                this.setState({ loading: false })
                if (Number(res.status) === 200) {
                    Swal.fire({
                        icon: 'success',
                        title: 'Servicio actualizado exitosamente'
                    })
                    this.handleClose()
                    this.props.updateServices(res.data)
                } else {
                    Swal.fire({
                        title: "Ha ocurrido un erro al actualizar el servicio",
                        icon: "error"
                    })
                }
            }).catch(err => {
                this.setState({ loading: false })
                Swal.fire({
                    title: "Ha ocurrido un erro al actualizar el servicio",
                    icon: "error"
                })
            })
        }

    }

    /**
   * Renderiza el componente.
   *
   * @return  El componente renderizado.
   */
    render() {
        const { show, edit, validated, service, role } = this.state

        return (
            <React.Fragment>
                {edit ?
                    <div className="editButton" style={{ cursor: "pointer" }} onClick={this.handleShow} >   <svg
                        viewBox="0 0 24 24"
                        width="25"
                        height="25"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="m3.99 16.854-1.314 3.504a.75.75 0 0 0 .966.965l3.503-1.314a3 3 0 0 0 1.068-.687L18.36 9.175s-.354-1.061-1.414-2.122c-1.06-1.06-2.122-1.414-2.122-1.414L4.677 15.786a3 3 0 0 0-.687 1.068zm12.249-12.63 1.383-1.383c.248-.248.579-.406.925-.348.487.08 1.232.322 1.934 1.025.703.703.945 1.447 1.025 1.934.058.346-.1.677-.348.925L19.774 7.76s-.353-1.06-1.414-2.12c-1.06-1.062-2.121-1.415-2.121-1.415z"
                            fill="#000000"
                        />
                    </svg></div> :
                    <Button variant="success" onClick={this.handleShow} className="mt-3"> <h5>Nuevo Servicio</h5></Button>
                }
                <Modal
                    show={show}
                    onHide={this.handleClose}
                >
                    <Modal.Header closeButton>


                        {!edit ?
                            <div className="row w-100">
                                <Modal.Title id="example-custom-modal-styling-title" className="mod-title" >
                                    Registrar servicio
                                </Modal.Title>
                            </div>

                            :
                            <div className="row w-100">
                                <Modal.Title id="example-custom-modal-styling-title" className="mod-title" >
                                    Editar servicio
                                </Modal.Title>
                            </div>
                        }

                    </Modal.Header>
                    <Modal.Body>
                        {this.state.loading &&
                            <div className="overlay">
                                <div className="spinner2"></div>
                            </div>
                        }
                        <Form noValidate className="form-style" id="formUser" validated={validated} >
                            <Form.Group className="mb-3" >
                                <Form.Label>Nombre *</Form.Label>
                                <Form.Control type="text" value={service.serviceName} required name="serviceName" onChange={this.handleChange} readOnly={Number(role) === 3} disabled={Number(role) === 3} />
                                <Form.Control.Feedback type="invalid" >
                                    Por favor ingrese el nombre del servicio
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group className="mb-3" >
                                <Form.Label>Descripción *</Form.Label>
                                <Form.Control type="text" value={service.serviceDescription} required name="serviceDescription" onChange={this.handleChange} readOnly={Number(role) === 3} disabled={Number(role) === 3} />
                                <Form.Control.Feedback type="invalid" >
                                    Por favor ingrese la descripión del servicio
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group className="mb-3" >
                                <Form.Label>Valor *</Form.Label>
                                <InputGroup className="mb-3">
                                    <InputGroup.Text>$</InputGroup.Text>
                                    <Form.Control type="number" value={service.serviceValue} required name="serviceValue" onChange={this.handleChange} readOnly={Number(role) === 3} disabled={Number(role) === 3} />
                                    <Form.Control.Feedback type="invalid" >
                                        Por favor ingrese el valor del servicio
                                    </Form.Control.Feedback>
                                </InputGroup>
                            </Form.Group>
                            <Form.Group className="mb-3" >
                                <Form.Label>Pago Referido *</Form.Label>
                                <InputGroup className="mb-3">
                                    <InputGroup.Text>$</InputGroup.Text>
                                    <Form.Control type="number" value={service.referralPayment} required name="referralPayment" onChange={this.handleChange} readOnly={Number(role) === 3} disabled={Number(role) === 3} />
                                    <Form.Control.Feedback type="invalid" >
                                        Por favor ingrese el valor del pago referido del servicio
                                    </Form.Control.Feedback>
                                </InputGroup>
                            </Form.Group>
                            <Form.Group className="mb-3" >
                                <Form.Label>Duración (Días) *</Form.Label>
                                <Form.Control type="number" value={service.duration} required name="duration" min={1} max={365} onChange={this.handleChange} readOnly={Number(role) === 3} disabled={Number(role) === 3} />
                                <Form.Control.Feedback type="invalid" >
                                    Por favor ingrese el nombre del servicio
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group className="mb-3" >
                                <Form.Label>Estado *</Form.Label>
                                <label className="switch-2">
                                    <input type="checkbox" class="toggle" checked={service.active} name="active" onChange={this.handleChange} readOnly={Number(role) === 3} disabled={Number(role) === 3}></input>
                                    <span class="slider-2"></span>
                                    <span class="card-side"></span>
                                </label>
                                <Form.Control.Feedback type="invalid" >
                                    Por favor ingrese el estado del servicio
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="danger" onClick={this.handleClose}>Cancelar</Button>
                        {Number(role) !== 3 ?
                            <Button variant="success" onClick={this.handleSubmit} disabled={!this.state.validForm} >Guardar cambios</Button>
                            : null
                        }
                    </Modal.Footer>
                </Modal >
            </React.Fragment>
        )

    }

}

export default ServiceRegister;