import Navbar from "../componentes/Navbar";
import React from "react";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTachometerAlt, faCheckCircle } from "../../node_modules/@fortawesome/free-solid-svg-icons";
import "../elements/ResetPassword.css";
import axios from "axios";
import '../componentes/GlobalVariables'

class ConfirmPassword extends React.Component {

  constructor(){
    super();
    this.state = {
      input: {},
      msg:{},
      idUser: 0,
      validateToken:true, 
      success: false   
    };
    this.handleChange= this.handleChange.bind(this);
    this.handleSubmit= this.handleSubmit.bind(this);
    
  }

  async componentDidMount(){
    
    const token = this.props.match.params.token
    const config = {
      method: 'get',
      url: global.URLBack + `/login/recoverPassword/validateToken/`+ token,
      headers: { 
        'Content-Type': 'text/plain'
      },
    }
    

    await axios(config)
    .then(res => {
      
      this.setState({
        idUser: res.data.idUser
      }        
        )      
    }).catch(err => {
     
      this.setState({
        validateToken: false
      })
    })
  }


  handleChange = (e) => {
    var pwd = this.state.input;
    pwd[e.target.name] = e.target.value;
    this.setState({
      pwd
    })
  }

  validation(){
    var msg={};
    if (this.state.input["password"] !== this.state.input["confirm_password"]) {
      msg["password"] = "Las contraseñas no coinciden";
      this.setState({
        msg:msg
      })
      return false
    }
    this.setState({
      msg:""
    })
    return true
  }



  handleSubmit(e) {
    
    e.preventDefault();
    if(this.validation()){      

      const config = {
        method: 'put',
        url: global.URLBack + `/login/recoverPassword/updatePassword`,
        headers: { 
          'Content-Type': 'application/json'
        },
        data: {
          idUser: this.state.idUser,
          newPasword: this.state.input["password"],
          token: this.props.match.params.token
        }
      }

      axios(config)
      .then(res => {
        this.setState({success: true})
        
      }).catch(err => {

      });
      var input={}
      input["password"]="";
      input["confirm_password"]="";
    };
  };


  render() {
    return (
      <div>
        {this.state.validateToken ?         
        <div>
          <Navbar />
          {this.state.success ? (<div id= "alePaswword" className="alert alert-success" role="alert"><FontAwesomeIcon icon ={faCheckCircle} /> 
  Contraseña actualizada correctamente 
</div> ): null}
          <div id="container-rp" className="container">
            <section id="content-header-rp" className="content-header">
              <h1 id="h1-grid">
                Reiniciar Contraseña
                <Breadcrumbs
                  className="breadcrumbs"
                  separator=">"
                  aria-label="breadcrumb"
                >
                  <Link className="link-rp" color="inherit" to="/">
                    <FontAwesomeIcon className="icon" icon={faTachometerAlt} />
                    Panel
                  </Link>
                  <Link className="link-rp" color="inherit" to="/resetPassword">
                    Reiniciar Contraseña
                  </Link>
                </Breadcrumbs>
              </h1>
            </section>
            <section className="content">
              <div className="row">
                <div id="col-card-rp" className="col-md-offset-3 col-md-6">
                  <div className="card">
                    <h5 className="card-header">Crear nueva contraseña</h5>
                    <div className="card-body">
                      <h6 className="card-subtitle mb-2 text-muted">
                        Por favor, introduzca una nueva contraseña para su cuenta
                      </h6>
                      <form onSubmit={this.handleSubmit}>
                        <label className="label_confpassw">Contraseña</label>
                        <input
                          className="form-control"
                          name="password"
                          placeholder="Contraseña"
                          type="password"
                          required
                          onChange={this.handleChange}
                        />
                        <label className="label_confpassw">
                         Confirmar contraseña
                        </label>
                        <input
                          className="form-control"
                          name="confirm_password"
                          placeholder=" Confirmar contraseña"
                          type="password"
                          required
                          onChange={this.handleChange}
                        />
                        <div className="text-danger">
                          {this.state.msg.password}
                        </div>
                        <div className="content-btn-rp">
                          <button
                            id="btn-rp"
                            className="btn btn-success btn-block "
                            type="submit"
                            value="Reiniciar Contraseña"
                            onClick={this
                              .handleSubmit
                              .bind(this)}
                          >
                            Cambiar Contraseña
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <div></div>
          </div>
          <footer>© 2021 IvuControlPR</footer>
        </div>
          : <div> {window.alert("No se ha podido restablecer la contraseña\nEl tiempo para restablecer la contraseña ha caducado.")}{window.location.replace("/")} </div>}
      </div>
    );
  }
}
export default ConfirmPassword;
