import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import Home from '../pages/Home';
import Address from '../pages/Address';
import BusinessInfo from '../pages/BusinessInfo';
import BusinessRegister from '../pages/BusinessRegister';
import FormAddress from '../pages/FormAddress';
import Profile from '../pages/Profile';

import UpdateAddress from '../pages/UpdateAddress';
import Users from '../pages/Users';
import ConfirmPassword from '../pages/ConfirmPassword';
import Register from '../pages/Register';
import ResetPassword from '../pages/ResetPassword';
import Terms from '../pages/Terms';
import AdminDashboard from '../pages/AdminDashboard';
import UsersTerminals from '../pages/UsersTerminals';
import MonthActivity from '../pages/monthActivity';
import UnreportedClient from '../pages/UnreportedClients';
import Last90daysActivity from '../pages/last90daysActivity';
import PayBill from '../pages/payBill';
import NoPayClients from '../pages/NoPayClients';
import TransactionHistory from '../pages/TransactionHistory';
import PayReport from '../pages/PayReport';
import UserDashboard from '../pages/UserDashboard';
import UserPayHistory from '../pages/UserPayHistory';
import AdminUsers from '../pages/AdminUsers';
import LogHistory from '../pages/LogHistory';
import Resellers from '../pages/Resellers';
import ResellerPayReport from '../pages/ResellerPayReport';
import PayCommissions from '../pages/PayCommissions';
import PricesAndServices from '../pages/PricesAndServices';
import ResellerDashboard from '../pages/ResellerDashboard';
import AccountCreated from '../pages/AccountCreated';
import ReportActivity from '../pages/ReportActivity';

function Routes() {



    //const AuthorizationContext = React.createContext();
    const token = localStorage.getItem('loggedIvuUser')
    let role = -1
    // Dividir el token en partes (encabezado, carga útil, firma)
    if (token !== null) {
        const [header, payload, signature] = token.split('.');

        // Decodificar la carga útil (payload) que contiene la información del usuario
        const decodedPayload = JSON.parse(atob(payload));
        role = decodedPayload['role']
    }




    return (
        <BrowserRouter>
            <Switch>

                {/*  <AuthorizationContext.Provider value={token}>
            <PublicRoute />
             { !!token ? <><PrivateRoute /></> :
              <Route path='*' component={Home} /> }
            </AuthorizationContext.Provider>
 */}

                <Route
                    exact
                    path="/"
                    render={() => {
                        if ((Number(role) === 2 || Number(role) === 3) && !!token) {
                            return <AdminDashboard />;
                        } else if (Number(role) === 1 && !!token) {
                            return <UserDashboard />;
                        } else {
                            if (Number(role) === 4 && !!token) {
                                return <ResellerDashboard />
                            }
                            return <Route path="*" component={Home} />;
                        }
                    }}
                />


                <Route exact path="/register" render={()=><Register key={1}/>} />
                <Route exact path="/register/:username" render={()=><Register key={2}/>} />
                <Route exact path="/resetPassword" component={ResetPassword} />
                <Route path="/confirmPassword/:token" component={ConfirmPassword} />
                <Route exact path="/register/new/accountCreated" render={()=><AccountCreated/>} />
                <Route path="/terms" component={Terms} />
                {/* <Route exact path="/dashboard" render={() => !!token ? <Dashboard /> : <Route path='*' component={Home} />} /> */}
                <Route exact path="/dashboard" render={() => Number(role) === 1 && !!token ? <UserDashboard /> : <Route path='*' component={Home} />} />
                <Route exact path="/dashboard/businessinfo/:idAddress" render={() => Number(role) === 1 && !!token ? <Route path='/dashboard/businessinfo/:idAddress' component={BusinessInfo} /> : <Route path='*' component={Home} />} />
                <Route exact path="/dashboard/address/addAddress" render={() => Number(role) === 1 && !!token ? <FormAddress /> : <Route path='*' component={Home} />} />
                <Route exact path="/dashboard/address/addAddress/:id" render={() => Number(role) === 1 && !!token ? <Route path="/dashboard/address/addAddress/:id" component={FormAddress} /> : <Route path='*' component={Home} />} />
                <Route exact path="/dashboard/address/:idBusiness" render={() => Number(role) === 1 && !!token ? <Route path='/dashboard/address/:idBusiness' component={Address} /> : <Route path='*' component={Home} />} />
                <Route exact path="/dashboard/address/updateAddress/:id" render={() => Number(role) === 1 && !!token ? <Route path='/dashboard/address/updateAddress/:id' component={UpdateAddress} /> : <Route path='*' component={Home} />} />
                <Route exact path="/dashboard/profile" render={() => Number(role) === 1 && !!token ? <Profile /> : <Route path='*' component={Home} />} />
                <Route path="/dashboard/businessRegister" render={() => Number(role) === 1 && !!token ? <BusinessRegister /> : <Route path='*' component={Home} />} />

                <Route exact path="/dashboard/payHistory" render={() => Number(role) === 1 && !!token ? <UserPayHistory /> : <Route path='*' component={Home} />} />
                <Route exact path="/dashboard/monthActivity/:merchantNumber" render={() => (Number(role) === 1) && !!token ? <MonthActivity /> : <Route path='*' component={Home} />}></Route>

                <Route exact path="/dashboard/admin" render={() => (Number(role) === 2 || Number(role) === 3) && !!token ? <AdminDashboard /> : <Route path='*' component={Home} />} />
                <Route exact path="/admin/users" render={() => (Number(role) === 2 || Number(role) === 3) && !!token ? <Users /> : <Route path='*' component={Home} />} />
                <Route exact path="/admin/usersTerminals" render={() => (Number(role) === 2 || Number(role) === 3) && !!token ? <UsersTerminals /> : <Route path='*' component={Home} />} />
                <Route exact path="/admin/unreportedClients" render={() => (Number(role) === 2 || Number(role) === 3) && !!token ? <UnreportedClient /> : <Route path='*' component={Home} />}></Route>
                
                <Route exact path="/admin/reportActivity" render={() => (Number(role) === 2 || Number(role) === 3) && !!token ? <ReportActivity key={1} /> : <Route path='*' component={Home} />}></Route>
                <Route exact path="/admin/reportActivity/:merchantNumber" render={() => (Number(role) === 2 || Number(role) === 3) && !!token ? <ReportActivity key={1} /> : <Route path='*' component={Home} />}></Route>
                
                <Route exact path="/admin/monthActivity" render={() => (Number(role) === 2 || Number(role) === 3) && !!token ? <MonthActivity key={1} /> : <Route path='*' component={Home} />}></Route>
                <Route exact path="/admin/monthActivity/:merchantNumber" render={() => (Number(role) === 2 || Number(role) === 3) && !!token ? <MonthActivity key={2} /> : <Route path='*' component={Home} />}></Route>
                <Route exact path="/admin/lastActivity" render={() => (Number(role) === 2 || Number(role) === 3) && !!token ? < Last90daysActivity /> : <Route path='*' component={Home} />}></Route>
                <Route exact path="/admin/payBill" render={() => (Number(role) === 2 || Number(role) === 3) && !!token ? < PayBill key={1} /> : <Route path='*' component={Home} />}></Route>
                <Route exact path="/admin/payBill/:merchantNumber" render={() => (Number(role) === 2 || Number(role) === 3) && !!token ? < PayBill key={2} /> : <Route path='*' component={Home} />}></Route>

                <Route exact path="/admin/noPayClients" render={() => (Number(role) === 2 || Number(role) === 3) && !!token ? <NoPayClients /> : <Route path='*' component={Home} />}></Route>
                <Route exact path="/admin/transactionHistory" render={() => (Number(role) === 2 || Number(role) === 3) && !!token ? <TransactionHistory /> : <Route path='*' component={Home} />}></Route>
                <Route exact path="/admin/payReport" render={() => (Number(role) === 2 || Number(role) === 3) && !!token ? <PayReport /> : <Route path='*' component={Home} />}></Route>
                <Route exact path="/admin/adminUsers" render={() => (Number(role) === 2) && !!token ? <AdminUsers /> : <Route path='*' component={Home} />} />
                <Route exact path="/admin/logHistory" render={() => (Number(role) === 2) && !!token ? <LogHistory key={1} /> : <Route path='*' component={Home} />} />
                <Route exact path="/admin/logHistory/:idUser" render={() => (Number(role) === 2) && !!token ? <LogHistory key={2} /> : <Route path='*' component={Home} />} />
                <Route exact path="/admin/resellers" render={() => (Number(role) === 2 || Number(role) === 3) && !!token ? <Resellers /> : <Route path='*' component={Home} />} />
                <Route exact path="/admin/resellerPayReport" render={() => (Number(role) === 2 || Number(role) === 3) && !!token ? <ResellerPayReport key={1} /> : <Route path='*' component={Home} />} />
                <Route exact path="/admin/resellerPayReport/:idUser" render={() => (Number(role) === 2 || Number(role) === 3) && !!token ? <ResellerPayReport key={2} /> : <Route path='*' component={Home} />} />
                <Route exact path="/admin/payCommissions" render={() => (Number(role) === 2 || Number(role) === 3) && !!token ? <PayCommissions /> : <Route path='*' component={Home} />} />
                <Route exact path="/admin/servicesAndPrices" render={() => (Number(role) === 2 || Number(role) === 3) && !!token ? <PricesAndServices /> : <Route path='*' component={Home} />} />

                <Route exact path="/dashboard/reseller" render={() => (Number(role) === 4) && !!token ? <ResellerDashboard /> : <Route path='*' component={Home} />} />
            </Switch>
        </BrowserRouter>
    )
}

/* function isAdmin() {
    var tokenRefresh = localStorage.getItem('loggedIvuUser')
    var role = localStorage.getItem('role')
    console.log(role);
    if (tokenRefresh === null) {
        return false
    }
    var decodedHeader = jwt_decode(tokenRefresh);
    if (decodedHeader['idUser'] == 1) {
        return true;
    }
    return false
} */
export default Routes;