import React from 'react';
import Menu from '@material-ui/core/Menu';
import { faSignInAlt } from "../../node_modules/@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon} from "../../node_modules/@fortawesome/react-fontawesome";
import FormLogin from './FormLogin';
export default function SimpleMenu(props) {

  
  

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  
  return (
    <div>
      {props.login && !props.cargando ?  <div id="div-ml">
        <a href="#/" id="simple-menu-ml" aria-controls="simple-menu"  onClick={handleClick}>
          <span>{props.nombre}</span>
        </a>
      </div> : null} {!props.login && !props.cargando ? <div id="div-ml">
        <a href="#/" id="simple-menu-ml" aria-controls="simple-menu" onClick={handleClick}>
          <FontAwesomeIcon icon={faSignInAlt}/>
          Ingresar
        </a>
      </div> : null }
     
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose} style={{marginTop:"1.7vw"}}  
      >
 
          <FormLogin login2={props.login}
          role2={props.SupUser}
          name = {props.nombre}
          lastLogin2 = {props.lastLogin}
          logout = {props.handleclick2}
          islogout2 = {props.islogout}/>
      </Menu>
    </div>
  );
}