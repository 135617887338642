import Navbar from "../componentes/Navbar";
import React from "react";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import { Link } from "react-router-dom";
import { FontAwesomeIcon, } from "@fortawesome/react-fontawesome";
import { faTachometerAlt, faCheckCircle, faExclamationTriangle, faArrowLeft } from "../../node_modules/@fortawesome/free-solid-svg-icons";
import "../elements/ResetPassword.css";
import avatar from "../img/avatar.jpg";
import '../componentes/GlobalVariables';
import InputMask from 'react-input-mask';
import axios from "axios";
import Swal from "sweetalert2";
import Button from 'react-bootstrap/Button';


/**
 * Componente encargado de enseñar a el usuario su información de perfil, permitirle editarla, cambiar de contraseña y la foto de perfil
 */
class Profile extends React.Component {

  /**
   * Función constructora que inicializa el estado del componente.
   *
   * @param  props - El objeto props que contiene las propiedades iniciales del componente.

   */
  constructor(props) {
    super(props);
    this.state = {
      logindash: false,
      validFormInfo: false,
      user: {
        lastLogin: "",
        name: ""
      },
      data:
      {
        lastName: "PEREZ ",
        lastLogin: "2021-08-02 12:15",
        dateofBirth: "2000-01-01",
        phone: "234568",
        mobilephone: "123456789",
        userName: "PEPITO ",
        secondlastname: "PAZ",
        email: "pomeo1628@gmail.com",
        registerDate: "2021-08-2"
      },
      password: {
        currentPassword: "",
        newPassword: "",
        confirmPassword: ""
      },

      updateUser: {
        email: "pepito@gmail.com",
        lastName: "PEREZ ",
        dateofBirth: "2000-01-01",
        phone: "234568",
        mobilephone: "123456789",
        userName: "PEPITO ",
        secondlastname: "PAZ",
      },
      bandera: false,
      bandera2: false,
      error: null,
      success: null,
      msg: "",
      expressiones: {
        password: /^.{4,30}$/, // 4 a 30 digitos.
        name: /^[a-zA-ZÀ-ÿ\s]{3,40}$/, // Letras y espacios, pueden llevar acentos.
        email: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
      },
      image: '',
      urlImage: ''
    }
    this.handleChangeInfo = this.handleChangeInfo.bind(this);
    this.handleOnImageChange = this.handleOnImageChange.bind(this)
  }

  /**
   * Metodo encargado de guardar en una variable la imagen cargada por el usuario
   * @param {*} e evento disparado por el input
   */
  handleOnImageChange = (e) => {
    let files = e.target.files;

    this.setState({ image: files[0] });
  }
  /**
   * Metodo encargado de valida la contraseña
   * @returns True si el campo está correcto, False en caso contrario
   */
  validation = () => {

    if (
      this.state.password.newPassword === "" ||
      !this.state.expressiones.password.test(this.state.password.newPassword)
    ) {
      this.setState({
        valid: false,
        error: "La contraseña tiene que ser de 4 a 30 caracteres.",
      });
      return false;
    } else {
      this.setState({
        valid: true,
        error: "",
      });
    }
    if (
      this.state.password.confirmPassword !== this.state.password.newPassword ||
      !this.state.expressiones.password.test(this.state.data.password2) || this.state.password.confirmPassword === ""
    ) {
      this.setState({
        valid: false,
        error: "Ambas contraseñas deben ser iguales.",
      });
      return false;
    } else {
      this.setState({
        valid: true,
        error: "",
      });
    }

    return true

  }

  /**
   * Metodo encargado de validar los campos del formulario
   * @returns True si los campos están correctos, False en caso contrario
   */
  validationInfo = () => {

    if (
      this.state.updateUser.userName === "" ||
      !this.state.expressiones.name.test(this.state.updateUser.userName)
    ) {
      this.setState({
        valid: false,
        error: "El nombre debe tener mínimo 3 letras",
      });
      return false;
    } else {
      this.setState({
        valid: true,
        error: "",
      });
    }
    if (
      this.state.updateUser.lastName === "" ||
      !this.state.expressiones.name.test(this.state.updateUser.lastName)
    ) {
      this.setState({
        valid: false,
        error: "El primer apellido debe tener mínimo 3 letras",
      });
      return false;
    } else {
      this.setState({
        valid: true,
        error: "",
      });
    }
    if (
      this.state.updateUser.secondlastname === "" ||
      !this.state.expressiones.name.test(this.state.updateUser.secondlastname)
    ) {
      this.setState({
        valid: false,
        error: "El segundo apellido debe tener mínimo 3 letras",
      });
      return false;
    } else {
      this.setState({
        valid: true,
        error: "",
      });
    }
    if (
      this.state.updateUser.mobilephone === ""
    ) {
      this.setState({
        valid: false,
        error:
          "El número de celular debe ser de 10 dígitos.",
      });
      return false;
    } else {
      this.setState({
        valid: true,
        error: "",
      });
    }
    if (
      this.state.updateUser.phone === ""
    ) {
      this.setState({
        valid: false,
        error:
          "El número de teléfono debe ser de 10 dígitos.",
      });
      return false;
    } else {
      this.setState({
        valid: true,
        error: "",
      });
    }
    if (
      this.state.updateUser.email === "" ||
      !this.state.expressiones.email.test(this.state.updateUser.email)
    ) {
      this.setState({
        valid: false,
        error: "El correo ingresado es invalido",
      });
      return false;
    } else {
      this.setState({
        valid: true,
        error: "",
      });
    }
    return true

  }

  handleChange(event) {

    let nombre = event.target.name
    let value = event.target.value
    this.setState(prevState => ({
      password: {
        ...prevState.password, [nombre]: value,
      }
    }))
    this.setState({
      error: null,
      success: null
    })

  }

  /**
   * Actualiza la información del usuario basándose en el objetivo del evento.
   *
   * @param {Evento} event - El evento que activó la función.
   */
  handleChangeInfo(event) {

    let nombre = event.target.name
    let value = event.target.value
    this.setState(prevState => ({
      updateUser: {
        ...prevState.updateUser, [nombre]: value
      }
    }))

  }

  /**
   * Método del ciclo de vida del componente que se llama después de que el componente se ha renderizado en el DOM.
   * Obtiene el token del almacenamiento local y envía una solicitud GET al servidor para obtener la última información de inicio de sesión.
   * Si la solicitud es exitosa, actualiza el estado con los datos del usuario y establece el estado "logindash" en true.
   * Si la solicitud falla, redirige al usuario a la página de inicio si no están en la página de inicio o de registro.
   * Envía otra solicitud GET al servidor para obtener la información del perfil del usuario.
   * Si la solicitud es exitosa, actualiza el estado con los datos del perfil del usuario.
   * Si la solicitud falla, no hace nada.
   *
   * @return  Una promesa que se resuelve cuando las solicitudes están completas.
   */
  async componentDidMount() {
    const token = localStorage.getItem('loggedIvuUser')
    const config = {
      headers: {
        'Authorization': 'Bearer ' + token
      },
      method: 'get',
      url: global.URLBack + `/login/login/getLastLogin`,
      withCredentials: true
    }

    await axios(config)
      .then(res => {
        this.setState({
          user: res.data
        })

        this.setState({ logindash: true })

      }).catch(err => {

        if ((!(window.location.pathname === '/' || window.location.pathname === '/register') && this.state.logindash === false)) {
          this.props.history.push("/")
        }
      })

    const config2 = {
      method: 'get',
      url: global.URLBack + `/dashBoard/profile/getInfoUser`,
      headers: {
        'Content-Type': 'text/plain',
        'Authorization': 'Bearer ' + token
      },

      withCredentials: true
    }

    await axios(config2)
      .then(res => {
        this.setState({
          data: res.data.userInfo,
          updateUser: {
            email: res.data.userInfo.email,
            lastName: res.data.userInfo.lastName,
            dateofBirth: res.data.userInfo.dateofBirth.substring(0, 10),
            phone: res.data.userInfo.phone,
            mobilephone: res.data.userInfo.mobilephone,
            userName: res.data.userInfo.userName,
            secondlastname: res.data.userInfo.secondlastname,
          },
          urlImage: res.data.userInfo.nameImage
        })

      }).catch(err => {

      })

  }


  handleClick = (e) => {
    e.preventDefault();
    this.setState({
      bandera: !this.state.bandera,
    });

  };

  /**
   * Metodo encargado de actualizar la contraseña del usuario
   * @param {*} e 
   */
  handleChangePassword = (e) => {
    const token = localStorage.getItem('loggedIvuUser')
    e.preventDefault();
    this.setState({
      error: null,
      success: null
    })
    if (this.validation()) {
      this.setState({
        bandera: !this.state.bandera,
      });

      const config = {
        method: 'put',
        url: global.URLBack + `/dashBoard/profile/changePassword`,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        },
        data: JSON.stringify(this.state.password),
        withCredentials: true
      }

      axios(config)
        .then(res => {

          this.setState({
            success: true,
            error: false,
            msg: "Contraseña actualizada exitosamente"
          })
        }).catch(err => {

          this.setState({
            success: false,
            error: true,
            msg: "Ocurrió un error al cambiar contraseña"
          })
        })

      this.setState({
        password: {
          currentPassword: "",
          newPassword: "",
          confirmPassword: ""
        }
      })
    } else {
      this.setState({
        success: false,
        error: true,
        msg: "Ocurrió un error al cambiar contraseña"
      })
    }

  }


  handleClick2 = (e) => {
    e.preventDefault();
    this.setState({
      bandera2: !this.state.bandera2,
    });
  };

  /**
   * Metodo encargado de guardar la imagen de perfil del usuario
   * @param {*} e evento
   */
  handleUploadImage = (e) => {
    e.preventDefault();
    this.setState({
      bandera2: !this.state.bandera2,
      error: null,
      success: null
    });

    if (this.state.image !== '') {

      const token = localStorage.getItem('loggedIvuUser')
      const formData = new FormData();
      formData.append(
        'image',
        this.state.image
      );
      var nameFile = this.state.image.name


      const config = {
        method: 'put',
        url: global.URLBack + `/dashBoard/profile/saveImage/` + nameFile.substring(nameFile.lastIndexOf(".") + 1, nameFile.length),
        headers: {
          "Content-Type": "multipart/form-data",
          'Authorization': 'Bearer ' + token
        },
        data: formData,
        withCredentials: true
      }


      axios(config)
        .then(res => {
          window.location.reload()

        }).catch(err => {
          this.setState({
            success: true,
            error: false,
            msg: "Fallo al subir imagen"
          })

        })
    }
  };

  /**
   * Metodo encargado de actualizar la información del perfil de usuario
   * @param {*} e evento disparado por el formulario
   */
  handleSubmitInfo = (e) => {
    e.preventDefault();
    if (this.validationInfo()) {
      this.setState(
        { validFormInfo: true }
      )
      const token = localStorage.getItem('loggedIvuUser')

      const config = {
        method: 'put',
        url: global.URLBack + `/dashBoard/profile/updateUserInfo`,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        },
        data: JSON.stringify(this.state.updateUser),
        withCredentials: true
      }


      axios(config)
        .then(res => {
          if (res !== undefined) {
            Swal.fire({
              title: res.data.msg,
              icon: "success"
            }).then((result) => {
              if (result.isConfirmed) {
                window.location.reload()
              }
            })
          }


        }).catch(err => {
          this.setState({
            success: false,
            error: true,
            msg: "Fallo al actualizar información"
          })


        })
    }

  }

  /**
   * Renderiza el componente.
   *
   * @return  El componente renderizado.
   */
  render() {
    return (
      <div>
        <Navbar loginD={this.state.logindash} nameD={this.state.user.name} lastLoginD={this.state.user.lastLogin} />
        <div id="container-rp" className="container">
          <section id="content-header-rp" className="content-header">
            <h1 id="h1-grid">
              
              <Breadcrumbs
                className="breadcrumbs"
                separator=">"
                aria-label="breadcrumb"
              >
                <Link className="link-rp" color="inherit" to="/dashboard">
                  <FontAwesomeIcon className="icon" icon={faTachometerAlt} />
                  Panel
                </Link>
                <Link
                  className="link-rp"
                  color="inherit"
                  to="/dashboard/profile"
                >
                  Perfil
                </Link>
              </Breadcrumbs>
            </h1>
          </section>
          {this.state.error !== "" && this.state.valid === false ? (<div className="alert alert-danger" role="alert"><FontAwesomeIcon icon={faExclamationTriangle} />
            {this.state.error}
          </div>) : null}
          {this.state.error === true && this.state.success === false ? (<div className="alert alert-danger" role="alert"><FontAwesomeIcon icon={faExclamationTriangle} />
            {this.state.msg}
          </div>) : null}
          {this.state.error === false && this.state.success === true ? (<div className="alert alert-success" role="alert"><FontAwesomeIcon icon={faCheckCircle} />
            {this.state.msg}
          </div>) : null}
          <div className="row">
            <Link to="/dashboard">
              <Button variant="outline-primary">
                <FontAwesomeIcon icon={faArrowLeft} /> Regresar a menú principal</Button>
            </Link>
            <br /><br />
            <h3>Perfil</h3>
            <div className="col-6">
              <div className="card">
                <div className="card-body">
                  {this.state.urlImage === '' ? (<div className="imagen-profile">
                    <img className="profile-user-img" alt="imgdefault" src={avatar} />
                  </div>) : (<div className="imagen-profile">
                    <img className="profile-user-img" alt="imgProfile" src={global.URLBack + `/dashBoard/profile/downloadImageProfile/` + this.state.urlImage} />
                  </div>)}
                  <h5 id="nombre-profile" className="card-title">
                    {this.state.data.userName + " " + this.state.data.lastName + " " + this.state.data.secondlastname}
                  </h5>
                  <ul className="list-group">
                    {/*  <li className="list-group-item">
                      <b>Email</b>
                      <a href="#/" className="email-profile" disabled>{this.state.data.email} </a>
                    </li> */}
                    <li className="list-group-item">
                      <b>Miembro desde</b>
                      <a href="#/" className="email-profile">{this.state.data.registerDate}</a>
                    </li>
                    <li className="list-group-item">
                      <b>Última vez</b>
                      <a href="#/" className="email-profile">{this.state.data.lastLogin}</a>
                    </li>
                    {this.state.bandera && !this.state.bandera2 ? (
                      <div id="form-group-contraseñas">
                        {" "}
                        <div >
                          <form className="form-group" >
                            <label for="Ca">Contraseña Actual</label>
                            <input
                              className="form-control"
                              id="Ca"
                              type="password"
                              placeholder="Contraseña Actual"
                              name="currentPassword" value={this.state.password.currentPassword} onChange={this.handleChange.bind(this)}
                            ></input>
                            <label for="Nc">Nueva Contraseña</label>
                            <input
                              className="form-control"
                              id="Nc"
                              type="password"
                              placeholder="Nueva Contraseña"
                              name="newPassword" value={this.state.password.newPassword} onChange={this.handleChange.bind(this)}
                            ></input>
                            <label for="Cc">Confirmar Contraseña</label>
                            <input
                              className="form-control"
                              id="Cc"
                              type="password"
                              placeholder="Confirmar Contraseña"
                              name="confirmPassword" value={this.state.password.confirmPassword} onChange={this.handleChange.bind(this)}
                            ></input>
                          </form>
                        </div>{" "}
                        <div className="form-group-secundarios">
                          <button
                            id="btnSecundario"
                            className="btn btn-danger"
                            onClick={this.handleClick}
                          >
                            Cancelar
                          </button>
                          <button
                            className="btn btn-success"
                            onClick={this.handleChangePassword}
                          >

                            Cambiar Contraseña
                          </button>{" "}
                        </div>
                      </div>
                    ) : (
                      <div></div>
                    )}
                    {!this.state.bandera && !this.state.bandera2 ? (
                      <form className="form-group-principales">
                        <button
                          id="btnPrincipal"
                          className="btn btn-primary"
                          onClick={this.handleClick}
                        >
                          Cambiar contraseña
                        </button>
                        <button
                          className="btn btn-primary"
                          onClick={this.handleClick2}
                        >
                          Cambiar foto de Perfil
                        </button>
                      </form>
                    ) : null}
                    {!this.state.bandera && this.state.bandera2 ? (
                      <div className="form-group-secundarios">
                        <form className="form-group">
                          <div className="div-form-bi">
                            <label for="file1"> Seleccionar Imagen</label>
                            <input type="file" id="file1" placeholder="Seleccionar archivo" accept="image/*" onChange={this.handleOnImageChange} />
                          </div>
                        </form>{" "}
                        <button
                          id="btnSecundario2"
                          className="btn btn-danger"
                          onClick={this.handleClick2}
                        >
                          Cancelar
                        </button>
                        <button
                          className="btn btn-success"
                          onClick={this.handleUploadImage}
                        >
                          Subir Imagen
                        </button>{" "}
                      </div>
                    ) : null}
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-6">
              <div className="card">
                <div className="card-body">
                  <h5 className="card-title">Mi información</h5>
                  <hr />
                  <div>
                    <form className="form-group">
                      <label for="email">Email</label>
                      <input onChange={this.handleChangeInfo}
                        className="form-control"
                        id="email"
                        type="email"
                        name="email"
                        placeholder="Email"
                        value={this.state.updateUser.email}
                      ></input>
                      <label for="Name-profile">Nombre</label>
                      <input onChange={this.handleChangeInfo}
                        className="form-control"
                        id="Name-profile"
                        type="text"
                        name="userName"
                        placeholder="Nombre"
                        value={this.state.updateUser.userName}
                      ></input>
                      <label for="LastName1">Primer Apellido</label>
                      <input onChange={this.handleChangeInfo}
                        className="form-control"
                        id="LastName1"
                        type="text"
                        name="lastName"
                        value={this.state.updateUser.lastName}
                        placeholder="Primer Apellido"
                      ></input>
                      <label for="LastName2">Segundo Apellido</label>
                      <input onChange={this.handleChangeInfo}
                        className="form-control"
                        id="LastName2"
                        type="text"
                        name="secondlastname"
                        placeholder="Segundo Apellido"
                        value={this.state.updateUser.secondlastname}
                      ></input>
                      <label for="telefono">Teléfono</label>
                      <InputMask className="form-control"
                        onChange={this.handleChangeInfo}
                        id="tel"
                        name="phone"
                        type="text"
                        mask="999-999-9999"
                        placeholder="Número de teléfono móvil."
                        value={this.state.updateUser.phone}
                      ></InputMask>
                      <label for="celular">Celular</label>
                      <InputMask
                        onChange={this.handleChangeInfo}
                        className="form-control"
                        id="cel"
                        type="text"
                        name="mobilephone"
                        mask="999-999-9999"
                        placeholder="Ingrese Celular"
                        value={this.state.updateUser.mobilephone}
                      ></InputMask>
                      <label for="fechaN">Fecha de Nacimiento</label>
                      <input onChange={this.handleChangeInfo}
                        className="form-control"
                        id="LastName"
                        type="date"
                        value={this.state.updateUser.dateofBirth}
                        name="dateofBirth"
                      ></input>
                      <button type="submit" className="btn btn-primary" onClick={this.handleSubmitInfo}>Actualizar información</button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <footer>© 2021 IvuControlPR</footer>
      </div>
    );
  }
}
export default Profile;
