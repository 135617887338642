import React from "react";
import Navbar from "../componentes/Navbar";
import axios from "axios";
import '../elements/ResellerDashboard.css';
import ReactPaginate from 'react-paginate';
import Table from 'react-bootstrap/Table';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Logo from "../img/logo.png";
import FileInf from "../img/Infografia.png";

/**
 * Componente encargado de desplegar la pagina principal de un reseller
 */
class ResellerDashboard extends React.Component {

    /**
     * Función constructora para la clase.
     *
     * @param props - El objeto props pasado al constructor.
     */
    constructor(props) {
        super(props);
        this.state = {
            role: -1,
            token: "",
            currentPage: 0,
            perPage: 10, // Número de elementos por página
            pageCount: 0,
            loading: false,
            accounts: [],
            logo: "",
            QR: "",
            reseller: {},
            infografia: ""
        }
    }

    /**
     * Método del ciclo de vida del componente que se llama después de que se monta el componente.
     * Obtiene el token del almacenamiento local y decodifica la carga útil.
     * Establece el token y el rol en el estado del componente.
     * Realiza una solicitud HTTP GET para obtener los datos del revendedor utilizando el token.
     * Si la solicitud es exitosa, establece los datos del revendedor en el estado del componente.
     * Realiza otra solicitud HTTP GET para obtener la imagen de perfil del revendedor utilizando la imagenprofile del revendedor.
     * Si la solicitud es exitosa, crea un objeto Blob a partir de los bytes de la imagen y lo establece como logo en el estado del componente.
     * Realiza otra solicitud HTTP GET para obtener las cuentas del revendedor utilizando el token.
     * Si la solicitud es exitosa, establece las cuentas y calcula la pageCount según el número de cuentas y el valor perPage en el estado del componente.
     * Realiza otra solicitud HTTP GET para obtener el código QR del revendedor utilizando el token.
     * Si la solicitud es exitosa, crea un objeto Blob a partir de los bytes del código QR y lo establece como QR en el estado del componente.
     * Realiza otra solicitud HTTP GET para obtener la imagen de infografía del revendedor.
     * Si la solicitud es exitosa, crea un objeto Blob a partir de los bytes de la imagen y lo establece como infografia en el estado del componente.
     *
     * @return {void}
     */
    componentDidMount() {
        const token = localStorage.getItem('loggedIvuUser')
        const [header, payload, signature] = token.split('.');



        // Decodificar la carga útil (payload) que contiene la información del usuario
        const decodedPayload = JSON.parse(atob(payload));

        const role = decodedPayload['role']
        const idUser = decodedPayload['idUser']
        this.setState({ token: token, role: role })


        const config = {
            method: 'get',
            url: global.URLBack + `/dashBoard/reseller/${idUser}`,
            headers: {
                'Authorization': 'Bearer ' + token
            },
            withCredentials: true
        }

        axios(config).then(res => {
            if (Number(res.status) === 200) {

                this.setState({ reseller: res.data })
                const config3 = {
                    method: 'get',
                    url: global.URLBack + `/dashBoard/reseller/imageprofile/${res.data.imageprofile}`,
                    headers: {
                        'Authorization': 'Bearer ' + token,
                        "Content-Type": "application/json",
                    },
                    withCredentials: true,
                    responseType: 'arraybuffer',
                }

                axios(config3).then(response => {

                    // Crear un objeto Blob a partir de los bytes de la imagen
                    const blob = new Blob([response.data], { type: response.headers['content-type'] });
                    // Crear una URL que apunte al Blob
                    const imageUrl = URL.createObjectURL(blob);

                    this.setState({
                        logo: imageUrl
                    })
                }).catch(error => {

                })


            }
        }).catch(err => {

        })

        const config2 = {
            method: 'get',
            url: global.URLBack + `/dashBoard/reseller/accounts/${idUser}`,
            headers: {
                'Authorization': 'Bearer ' + token
            },
            withCredentials: true
        }

        this.setState({ loading: true })
        axios(config2).then(response => {
            this.setState({ loading: false })
            if (Number(response.status) === 200) {
                var pageC = Math.ceil(response.data.length / this.state.perPage);
                this.setState({ accounts: response.data, pageCount: pageC })
            }
        }).catch(err => {
            this.setState({ loading: false })
            localStorage.removeItem('loggedIvuUser')
            window.location.reload()
        })

        const configQR = {
            method: 'get',
            url: global.URLBack + `/dashBoard/reseller/qr/${idUser}`,
            headers: {
                'Authorization': 'Bearer ' + token,
                "Content-Type": "application/json",
            },
            withCredentials: true,
            responseType: 'arraybuffer',
        }

        axios(configQR).then(responseQR => {

            if (Number(responseQR.status) === 200) {
                // Crear un objeto Blob a partir de los bytes de la imagen
                const blob = new Blob([responseQR.data], { type: responseQR.headers['content-type'] });
                // Crear una URL que apunte al Blob
                const imageUrl = URL.createObjectURL(blob);

                this.setState({ QR: imageUrl })
            }
        }).catch(err => {
            this.setState({ loading: false })
            localStorage.removeItem('loggedIvuUser')
            window.location.reload()
        })

        const configInfografia = {
            method: 'get',
            url: global.URLBack + `/dashBoard/reseller/imageprofile/infografia.png`,
            headers: {
                'Authorization': 'Bearer ' + token
            },
            withCredentials: true,
            responseType: 'arraybuffer',
        }

        axios(configInfografia).then(res => {
            if (Number(res.status) === 200) {

                // Crear un objeto Blob a partir de los bytes de la imagen
                const blob = new Blob([res.data], { type: res.headers['content-type'] });
                // Crear una URL que apunte al Blob
                const imageUrl = URL.createObjectURL(blob);
                this.setState({
                    infografia: imageUrl
                })
            }
        })


    }

    /**
     * Renderiza la barra de navegación basada en el estado actual.
     *
     * @return El componente de barra de navegación renderizado.
     */
    renderNavbar() {
        // Puedes acceder al estado actualizado aquí y pasar el valor a Navbar
        const { role } = this.state;

        if (Number(role) !== -1) {
            return <Navbar SupUser={role} />;
        }

    }

    /**
    * Metodo utilizado para imprimir el reporte
    */
    handlePrint = () => {

        const iframe = document.createElement('iframe');
        iframe.style.display = 'none';
        document.body.appendChild(iframe);

        const content = `
          <html>
            <head>
            </head>
            <body>
              ${this.getPrintableContent()}
            </body>
          </html>
        `;

        const doc = iframe.contentWindow.document;
        doc.open();
        doc.write(content);
        doc.close();

        // Iniciar la impresión
        iframe.contentWindow.print();
    };

    /**
       * Metodo utilizado para retornar el html que se muestra al imprimir un reporte
       * @returns html que se va a imprimir
       */
    getPrintableContent = () => {

        const { accounts } = this.state
        const accountList = accounts.map((account, index) => (
            `
            <tr key=${index}>
            <td>${account.merchantName}</td>
            <td>${account.merchantId}</td>
            <td>${account.expirationDate}</td>
            <td>${account.lastTransmission != undefined ? account.lastTransmission : ""}</td>
         </tr>
        `
        ))
        const formattedTransactions = accountList.join("");
        return `
   
    <!DOCTYPE html>
    <html lang="en">
    <head>
      <meta charset="UTF-8">
      <meta name="viewport" content="width=device-width, initial-scale=1.0">
      <title>Reporte de Cuentas</title>
      <style>
      body {
        font-family: Arial, sans-serif;
        background-color: #f5f5f5;
        margin: 0;
        padding: 0;
      }
  
      .report-container {
        background-color: #ffffff;
        border-radius: 8px;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
        padding: 20px;
        margin: 20px auto;
      }
  
      .report-header {
        text-align: center;
        font-size: 24px;
        margin-bottom: 20px;
        color: #3498db;
      }
  
      .transaction-row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #ccc;
        padding: 10px 0;
      }
  
      .transaction-label {
        color: #3498db;
        width: 150px;
      }
  
      .transaction-data {
        flex: 1;
      }
      .transaction-table {
        width: 100%;
        border-collapse: collapse;
        margin-top: 20px;
      }
  
      .transaction-table th,
      .transaction-table td {
        border: 1px solid #ccc;
        padding: 10px;
        text-align: left;
      }
  
      .transaction-label {
        color: black;
        font-weight: bold;
      }
      </style>
    </head>
    <body>
    <div class="printable-content">
    <!-- Contenido que deseas imprimir -->
    <div className="print-only">
    <img src="${Logo}" alt="Logo" width="200px" height="60px" />
    <img src="${this.state.logo}" style="float:right" alt="Logo" width="80px" height="80px" />
      <div class="report-container">   
      

        <h1 class="report-header">Reporte de cuentas</h1>
        <div class="transaction-row">
      
        <span class="transaction-data"></span>
        </div>
        <table class="transaction-table">
        <tr>
          <th class="transaction-label">Nombre</th>
          <th class="transaction-label">MerchantID</th>
          <th class="transaction-label">Fecha Expiración</th>
          <th class="transaction-label">Ultima transmisión</th>
        </tr>
       ${formattedTransactions}
      </table>
    
      </div>
      
    </div>
    </div>
    </body>
    </html>
    
  
    `;
    };

    /**
     * Metodo encargado de descargar el codigo QR 
     */
    handleDownload = () => {
        try {
            // Crea un enlace de descarga
            const link = document.createElement('a');
            link.download = 'QR_' + this.state.reseller.username + '.png';
            link.href = this.state.QR;

            // Simula un clic en el enlace para iniciar la descarga
            link.click();
        } catch (error) {
            console.error('Error al descargar la imagen:', error);
        }
    };

    /**
     * Metodo encargado de descargar la infografía al presionar el boton como configurar
     */
    downloadFile = () => {
        // Ruta de la imagen que deseas descargar
        const imageUrl = this.state.infografia;

        // Crea un elemento de enlace temporal
        const link = document.createElement('a');

        // Establece la URL del enlace al URL de la imagen
        link.href = imageUrl;

        // Establece el atributo 'download' para que el navegador sugiera un nombre de archivo al usuario al descargar
        link.download = 'Como_Configurar.png';

        // Simula un clic en el enlace para iniciar la descarga
        document.body.appendChild(link);
        link.click();

        // Elimina el enlace temporal del DOM
        document.body.removeChild(link);
    };


    /**
* Metodo utilizado para manejar la paginación de la tabla
* @param {*} param0 Pagina seleccionada
*/
    handlePageChange = ({ selected }) => {
        this.setState({
            currentPage: selected,
            selectedElement: null, // Reinicia el elemento seleccionado al cambiar de página
        });
    };

    /**
   * Renderiza el componente.
   *
   * @return  El componente renderizado.
   */
    render() {
        const { currentPage, perPage, accounts, QR } = this.state;
        const offset = currentPage * perPage;
        var pageC = this.state.pageCount
        var currentPageData = []
        if (accounts.length > 0) {
            currentPageData = accounts.slice(offset, offset + perPage);
        } else {
            currentPageData = accounts
        }

        return (
            <React.Fragment>
                {this.renderNavbar()}
                <div className='mainn3'>
                    <div className="sonn3">
                        <p className='titulo' style={{ marginInlineStart: "10%" }}><strong>Cuentas</strong></p>
                        <img alt="logo" style={{ marginInlineEnd: "10%" }} height={70} width={70} src={this.state.logo}></img>
                    </div>

                    <div className="sonn3">
                        {this.state.loading ? <div className="spinner"></div> :
                            <React.Fragment>
                                <div className="row w-100">
                                    <div className="col-7">
                                        {currentPageData.length > 0 ?
                                            <React.Fragment>
                                                <Table responsive>

                                                    <thead className="thead-dark">
                                                        <tr>
                                                            <th scope="col">Nombre</th>
                                                            <th scope="col">MerchantID</th>
                                                            <th scope="col">Fecha Expiración</th>
                                                            <th scope="col">Ultima transmisión</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {currentPageData.map((account, index) => (
                                                            <tr key={index}>
                                                                <td>{account.merchantName}</td>
                                                                <td>{account.merchantId}</td>
                                                                <td>{account.expirationDate}</td>
                                                                <td>{account.lastTransmission}</td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </Table>
                                                <div className="d-flex justify-content-center">
                                                    <ReactPaginate
                                                        pageCount={pageC}
                                                        onPageChange={this.handlePageChange}
                                                        containerClassName="pagination"
                                                        activeClassName="active"
                                                        forcePage={this.state.currentPage}
                                                    />
                                                </div>
                                            </React.Fragment> :
                                            <Alert variant="primary" >
                                                Aun no cuenta con cuentas registradas
                                            </Alert>
                                        }

                                    </div>

                                    <div className="col h-100" >

                                        <img alt="QR" height={350} width={350} src={QR}></img>
                                        <button className="buttonB" onClick={this.handleDownload}>
                                            Descarga
                                        </button>
                                        <h3 className="mt-3">www.ivucontrolpr.com/register/{this.state.reseller.username}</h3>
                                        <div className="row mt-5">
                                            <div className="col">
                                                <div style={{ textAlign: 'center' }}>
                                                    <Button variant="outline-secondary" onClick={this.downloadFile}>
                                                        <svg
                                                            version="1.1"
                                                            id="Layer_1"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            xmlnsXlink="http://www.w3.org/1999/xlink"
                                                            viewBox="0 0 508 508"
                                                            xmlSpace="preserve"
                                                            fill="#000000"
                                                            height={150}
                                                            width={150}
                                                        >
                                                            <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                                                            <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
                                                            <g id="SVGRepo_iconCarrier">
                                                                <circle cx="254" cy="254" r="254" fill="#324A5E"></circle>
                                                                <path d="M396,88.8v330c0,7.6-6,13.6-13.6,13.6H175.2v-47.2c0-7.6-6-13.6-13.6-13.6h-47.2V88.8 c0-7.6,6-13.6,13.6-13.6h254.8C390,75.6,396,81.6,396,88.8z" fill="#FFFFFF"></path>
                                                                <path d="M175.2,432.4H128c-7.6,0-13.6-6-13.6-13.6V372h47.2c7.6,0,13.6,6,13.6,13.6L175.2,432.4L175.2,432.4z " fill="#E6E9EE"></path>
                                                                <g>
                                                                    <path d="M175.2,432.4H128c-7.6,0-13.6-6-13.6-13.6V372L175.2,432.4z" fill="#CED5E0"></path>
                                                                    <rect x="296" y="156.4" fill="#CED5E0" width="25.2" height="47.2"></rect>
                                                                </g>
                                                                <rect x="260.4" y="119.2" fill="#FFD05B" width="25.2" height="84.4"></rect>
                                                                <rect x="224.8" y="134.8" fill="#84DBFF" width="25.2" height="68.4"></rect>
                                                                <rect x="189.6" y="105.2" fill="#FF7058" width="25.2" height="98"></rect>
                                                                <rect x="180.4" y="203.6" fill="#324A5E" width="149.6" height="7.2"></rect>
                                                                <g>
                                                                    <rect x="150" y="292" fill="#E6E9EE" width="210.8" height="10.4"></rect>
                                                                    <rect x="150" y="266.8" fill="#E6E9EE" width="210.8" height="10.4"></rect>
                                                                    <rect x="150" y="241.6" fill="#E6E9EE" width="210.8" height="10.4"></rect>
                                                                    <rect x="150" y="316.8" fill="#E6E9EE" width="210.8" height="10.4"></rect>
                                                                    <rect x="208.8" y="342" fill="#E6E9EE" width="152" height="10.4"></rect>
                                                                </g>
                                                                <circle cx="365.2" cy="328" r="76" fill="#4CDBC4"></circle>
                                                                <path d="M413.2,290.8c-4-4-10.8-4-15.2,0l-51.6,52l-14-14c-4-4-10.8-4-15.2,0c-4,4-4,10.8,0,15.2l14,14 c0,0,3.2,3.2,7.6,7.6c4,4,10.8,4,15.2,0l59.2-59.6C417.6,301.6,417.6,295.2,413.2,290.8z" fill="#FFFFFF"></path>
                                                            </g>
                                                        </svg>
                                                        <h5 style={{ marginTop: '10px' }}>Como Configurar</h5>
                                                    </Button>
                                                </div>
                                            </div>
                                            <div className="col">
                                                <div style={{ textAlign: 'center' }}>
                                                    <Button variant="outline-secondary" onClick={this.handlePrint}>
                                                        <svg
                                                            version="1.1"
                                                            id="Layer_1"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            xmlnsXlink="http://www.w3.org/1999/xlink"
                                                            viewBox="0 0 400 400"
                                                            xmlSpace="preserve"
                                                            style={{ fill: '#000000' }}
                                                            height={150}
                                                            width={150}
                                                        >
                                                            <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                                                            <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
                                                            <g id="SVGRepo_iconCarrier">
                                                                <g id="XMLID_807_">
                                                                    <g id="XMLID_808_">
                                                                        <polygon id="XMLID_75_" fill="#ACABB1" points="90,320 90,0 290,0 350,60 350,320 "></polygon>
                                                                        <polygon id="XMLID_809_" fill="#818085" points="290,0 350,60 290,60 "></polygon>
                                                                    </g>
                                                                    <g id="XMLID_810_">
                                                                        <polygon id="XMLID_72_" fill="#EEEEEF" points="70,360 70,40 270,40 330,100 330,360 "></polygon>
                                                                        <polygon id="XMLID_811_" fill="#DEDDE0" points="270,40 330,100 270,100 "></polygon>
                                                                    </g>
                                                                    <polygon id="XMLID_68_" fill="#78B9EB" points="50,400 50,80 250,80 310,140 310,400 "></polygon>
                                                                    <polygon id="XMLID_812_" fill="#5A8BB0" points="310,253.75 50,400 310,400 "></polygon>
                                                                    <rect id="XMLID_813_" x="110" y="200" fill="#DEDDE0" width="140" height="20"></rect>
                                                                    <rect id="XMLID_814_" x="110" y="240" fill="#DEDDE0" width="140" height="20"></rect>
                                                                    <rect id="XMLID_815_" x="110" y="160" fill="#FFFFFF" width="70" height="20"></rect>
                                                                    <rect id="XMLID_816_" x="110" y="200" fill="#FFFFFF" width="70" height="20"></rect>
                                                                    <rect id="XMLID_817_" x="110" y="240" fill="#FFFFFF" width="70" height="20"></rect>
                                                                    <rect id="XMLID_818_" x="110" y="280" fill="#DEDDE0" width="140" height="20"></rect>
                                                                    <rect id="XMLID_819_" x="110" y="280" fill="#FFFFFF" width="70" height="20"></rect>
                                                                    <rect id="XMLID_820_" x="110" y="320" fill="#DEDDE0" width="140" height="20"></rect>
                                                                    <rect id="XMLID_821_" x="110" y="320" fill="#FFFFFF" width="70" height="20"></rect>
                                                                    <polygon id="XMLID_822_" fill="#1E2E3B" points="250,80 310,140 250,140 "></polygon>
                                                                </g>
                                                            </g>
                                                        </svg>
                                                        <h5 style={{ marginTop: '10px' }}>Reporte de cuentas</h5>
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </React.Fragment>
                        }
                    </div>
                </div>

            </React.Fragment>
        )
    }
}

export default ResellerDashboard