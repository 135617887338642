import React from "react";
import Modal from 'react-bootstrap/Modal';
import '../elements/EditUser.css'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen } from "../../node_modules/@fortawesome/free-solid-svg-icons"
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';
import axios from "axios";
import Table from 'react-bootstrap/Table';

import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';

const Swal = require('sweetalert2')

/**
 * Componente que actua como modal para realizarle actualizaciones a la información del cliente
 * 
 */
class ModalEditUser extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            validated: false,
            userEdit: { oldMerchantId: "", fiscalterminals: 0, service: "IVU PRO[99.00/AÑO]", state: "PR" },
            initial: {},
            scLoad: "",
            mcLoad: "",
            tokenuser: props.tokenuser,
            validForm: true,
            showToast: false,
            toastMessage: "",
            toastTitle: "",
            token: "",
            services: [],
            towns: [],
            softwareList: [],
            selectSoftware: false,
            merchanIdList: props.merchantIDList,
            numMerchantOk: true,
            merchantID: props.merchantID,
            role: -1,
            paymentMethods: [],
            resellers: []
        };
    }

    /**
     * Metodo encargado de incluir el valor de terminal adicional en caso en el que desde la base de datos provenga con 0.
     * Además, obtiene la lista de servicios disponibles a ofrecer
     */
    async componentDidMount() {
        const token = localStorage.getItem('loggedIvuUser')
        const [header, payload, signature] = token.split('.');

        // Decodificar la carga útil (payload) que contiene la información del usuario
        const decodedPayload = JSON.parse(atob(payload));

        const role = decodedPayload['role']
        this.setState({ token: token, role: role })



        const config = {
            method: 'get',
            url: global.URLBack + `/dashBoard/service`,
            headers: {
                'Authorization': 'Bearer ' + token
            },
            withCredentials: true
        }

        await axios(config)
            .then(res => {
                this.setState({
                    services: res.data
                })
                const config2 = {
                    method: 'get',
                    url: global.URLBack + `/dashBoard/superUser/paymentMethods`,
                }

                axios(config2).then(response2 => {
                    this.setState({ paymentMethods: response2.data })
                }).catch(error => {
                    Swal.fire({
                        title: "Ocurrio un error al cargar los medios de pago por favor intente actualizar el cliente más tarde",
                        icon: "error"
                    })
                    this.handleClose()
                })
            }).catch(err => {
                Swal.fire({
                    title: "Ocurrio un error al cargar los servicios por favor intente actualizar el cliente más tarde",
                    icon: "error"
                })
                this.handleClose()
            })

        const config2 = {
            method: "get",
            url: global.URLBack + `/query/register/loadData/3`,
        };
        await axios(config2)
            .then(response => {
                this.setState({
                    towns: response.data.towns,
                    softwareList: response.data.software
                })
            })
    }

    /**
     * Carga datos desde el servidor y actualiza el estado en consecuencia.
     *
     */
    loadData() {
        const configUser = {
            method: 'get',
            url: global.URLBack + `/dashBoard/superUser/user/` + this.state.merchantID,
            headers: {
                'Authorization': 'Bearer ' + this.state.token
            },
            withCredentials: true
        }
        this.setState({ loading: true })
        axios(configUser).then(res => {
            this.setState({ loading: false })
            this.setState({
                userEdit: res.data,
                initial: res.data
            })
            /*  if (this.state.userEdit.additionalTerminal === 0) {
                 this.setState((prevState) => ({
                     userEdit: { ...prevState.userEdit, additionalTerminal: 99 }
                 }))
             } */
            if (this.state.userEdit.paymethod !== undefined) {
                this.state.userEdit.paymethod.toUpperCase()
            }
            if (this.state.userEdit.software !== undefined) {
                this.state.userEdit.software.toUpperCase()
            }
            if (this.state.userEdit.software === undefined || this.state.userEdit.software.length === 0) {
                this.setState({
                    selectSoftware: true
                })
            } else {
                this.setState({
                    selectSoftware: false
                })
            }

            const config2 = {
                method: 'get',
                url: global.URLBack + `/dashBoard/reseller`,
                headers: {
                    'Authorization': 'Bearer ' + this.state.token
                },
                withCredentials: true
            }

            axios(config2).then(response => {
                if (Number(response.status) === 200) {
                    this.setState({
                        resellers: response.data
                    })
                }
            }).catch(error => {
                this.setState({ loading: false })
                localStorage.removeItem('loggedIvuUser')
            })
        }, () => {
            /* if (this.state.userEdit.additionalTerminal === 0) {
                this.setState((prevState) => ({
                    userEdit: { ...prevState.userEdit, additionalTerminal: 99 }
                }))
            } */
            if (this.state.userEdit.paymethod !== undefined) {
                this.state.userEdit.paymethod.toUpperCase()
            }
            if (this.state.userEdit.software !== undefined) {
                this.state.userEdit.software.toUpperCase()
            }
            if (this.state.userEdit.software === undefined || this.state.userEdit.software.length === 0) {
                this.setState({
                    selectSoftware: true
                })
            } else {
                this.setState({
                    selectSoftware: false
                })
            }
        }).catch(err => {
            this.setState({ loading: false })
            localStorage.removeItem('loggedIvuUser')
            //localStorage.removeItem('role')

        })


    }

    /**
     * Metodo utilizado para desplegar el modal
     */
    handleShow = () => {
        this.setState({ show: true })
        this.loadData()
    };

    componentDidUpdate(prevProps) {
        if (prevProps.merchantID !== this.props.merchantID) {
            this.setState({merchantID:this.props.merchantID})
        }
    }

    /**
    * Metodo utilizado para ocultar el modal
    */
    handleClose = () => {
        this.setState({ show: false, userEdit: this.state.initial, validated: false, validForm: true })
    };

    /**
     * Metodo utilizado para capturar los cambios realizados en cualquiera de los atributos del cliente y asignar este nuevo valor
     * en la variable de estado userEdit
     * @param {*} e input que activó el evento, a partir de el se obtiene el nombre de ese input y su valor
     */
    handleChange = (e) => {
        var { name, value } = e.target;
        if (value === "on") {
            value = 1
        } else if (value === "off") {
            value = 0
        }

        this.setState((prevState) => ({
            userEdit: { ...prevState.userEdit, [name]: value }
        }));

        this.valdidateForm(e)
    };

    /**
     * Metodo utilizado para verificar si se deben actualizar el atributo initialValues en caso de que haya cambiado desde las props
     * 
     * Esto se realiza debido a que cuando realiza una actualización y se cierra el modal, este no se renderiza de nuevo si se vuelve a abrir,
     * por lo tanto, se verifica manualmente si la información a cambiado para actualizarla
     * @param {*} prevProps Atributo que representa las props anteriores
     */
    /*     componentDidUpdate(prevProps) {
            if (prevProps.initialValues !== this.props.initialValues) {
                this.updateStateFromProps(this.props);
            }
        } */

    /**
     * Metodo utilizado para actualizar el valor de los atributos de estado initialValues y userEdit con lo que provenga de las props
     * @param {*} props valores de esos mismos atributos que provienen del componente padre (Users.js)
     */
    /*    updateStateFromProps(props) {
           // Actualiza el estado del componente con las nuevas props recibidas desde el padre
           this.setState({ initialValues: props.initialValues, userEdit: {  oldMerchantId: "", fiscalterminals: 0, service: "IVU PRO[99.00/AÑO]" } });
       } */

    /**
   * Metodo utilizado para actualizar el formato MC
   * @param {*} files archivo 
   */
    updateMC(files) {
        Swal.fire({
            title: "Si desea modificar el registro de comerciante se estará validando la modificación y posteriormente se cambiará en el sistema",
            icon: "warning",
            showCancelButton: true,
            showConfirmButton: true,
            cancelButtonText: "Cancelar",
            reverseButtons: true
        }).then((response) => {
            if (response.isConfirmed) {
                const formData = new FormData();
                formData.append("pdf", files[0]);
                let urlService = "/dashBoard/business/saveFormMC/"
                let verb = "Put"
                const config = {
                    method: verb,
                    url:
                        global.URLBack +
                        urlService +
                        this.state.userEdit.id_add,
                    headers: {
                        "Content-Type": "multipart/form-data",
                        "Authorization": "Bearer " + this.state.token
                    },
                    data: formData,
                    withCredentials: true
                };
                axios(config)
                    .then((res) => {
                        if (res.status === 200) {
                            this.setState((prevState) => ({
                                userEdit: { ...prevState.userEdit, id_merch: res.data.id_merch }
                            }), () => {
                                Swal.fire(
                                    {
                                        title: res.data.msg,
                                        icon: 'success'
                                    }
                                )
                            })

                        }
                    })
                    .catch((err) => {
                        Swal.fire(
                            {
                                title: 'Ocurrio un error al guardar el archivo, intentelo nuevamente.',
                                text: 'Recuerde que el archivo no puede ser muy pesado',
                                icon: 'error'
                            }
                        )
                    });
            }
        })

    }

    /**
     * Metodo utilizado para cargar los formularios MS o SC
     * @param {*} e input que activó el evento y contiene la información de los archivos
     */
    handleFiles = (e) => {

        const { name, files } = e.target;
        if (files.length > 0) {
            const formData = new FormData();
            let urlService = ""
            let verb = "post"

            if (name === "mcLoad") {
                urlService = "/dashBoard/business/saveFormMC/"

                if (this.state.userEdit.id_merch !== 0) {
                    urlService = "/dashBoard/business/updateFormMC/"
                    verb = "Put"
                }

            }
            if (name === "scLoad") {

                urlService = "/dashBoard/business/saveFormSC/"
                if (this.state.userEdit.id_form !== 0) {
                    verb = "put"
                }
            }

            formData.append("pdf", files[0]);

            const config = {
                method: verb,
                url:
                    global.URLBack +
                    urlService +
                    this.state.userEdit.id_add,
                headers: {
                    "Content-Type": "multipart/form-data",
                    "Authorization": "Bearer " + this.state.userEdit.tokenUser
                },
                data: formData,
                withCredentials: true
            };

            axios(config)
                .then((res) => {
                    if (res.status === 200) {
                        if (name === "mcLoad") {
                            if (verb === "post") {
                                this.setState((prevState) => ({
                                    userEdit: { ...prevState.userEdit, idFormMC: res.data.id_merch }
                                }), () => {
                                    Swal.fire(
                                        {
                                            title: res.data.msg,
                                            icon: 'success'
                                        }
                                    )
                                    /**TODO: Cambiar esto por lo que llegue del servicio */
                                    this.props.updateForms(this.state.userEdit)
                                })
                            } else {
                                Swal.fire(
                                    {
                                        title: res.data.msg,
                                        icon: 'success'
                                    }
                                )
                                /**TODO: Cambiar esto por lo que llegue del servicio */
                                this.props.updateForms(this.state.userEdit)
                            }

                        }
                        if (name === "scLoad") {
                            if (verb === "post") {
                                this.setState((prevState) => ({
                                    userEdit: { ...prevState.userEdit, idFormSc: this.state.userEdit.id_form !== 0 ? this.state.userEdit.id_form : res.data.id_form }
                                }),
                                    () => {
                                        Swal.fire(
                                            {
                                                title: res.data.msg,
                                                icon: 'success'
                                            }
                                        )
                                        /**TODO: Cambiar esto por lo que llegue del servicio */
                                        this.props.updateForms(this.state.userEdit)
                                    });
                            } else {
                                Swal.fire(
                                    {
                                        title: res.data.msg,
                                        icon: 'success'
                                    }
                                )
                                /**TODO: Cambiar esto por lo que llegue del servicio */
                                this.props.updateForms(this.state.userEdit)
                            }
                        }
                        Swal.fire(
                            {
                                title: res.data.msg,
                                icon: 'success'
                            }
                        )

                    }
                })
                .catch((err) => {
                    if (verb === "post") {
                        if (name === "mcLoad") {
                            this.setState((prevState) => ({
                                userEdit: { ...prevState.userEdit, idFormMC: 0 }
                            }));
                        }
                        if (name === "scLoad") {
                            this.setState((prevState) => ({
                                userEdit: { ...prevState.userEdit, idFormSc: 0 }
                            }));
                        }
                    }

                    Swal.fire(
                        {
                            title: 'Ocurrio un error al guardar el archivo, intentelo nuevamente.',
                            text: 'Recuerde que el archivo no puede ser muy pesado',
                            icon: 'error'
                        }
                    )
                });

        } else {
            Swal.fire({
                title: "Debe seleccionar un archivo",
                icon: "info"
            })
        }

    }

    /**
     * Metodo utilizado para enviarle al componente padre la información del formulario MC guardado para que pueda tener la opción de descargarlo
     * desde su componente sin tener que recargar la página
     */
    handleClickMC() {
        this.props.handleDownloadMC(this.state.userEdit.idFormMC, this.state.userEdit.merchantNumber, this.state.tokenuser);
    }

    /**
      * Metodo utilizado para enviarle al componente padre la información del formulario SC guardado para que pueda tener la opción de descargarlo
      * desde su componente sin tener que recargar la página
      */
    handleClickSC() {
        this.props.handleDownloadSC(this.state.userEdit.idFormSc, this.state.userEdit.merchantNumber, this.state.tokenuser);
    }

    /**
     * Metodo utilizado para activar o desactivar a un cliente
     */
    handelActivation() {
        let activeValue = this.state.userEdit.active
        let newValue = 0
        if (activeValue === 0) {
            newValue = 1
        }
        const config = {
            method: "put",
            url:
                global.URLBack +
                `/dashBoard/superUser/active/` +
                this.state.userEdit.merchantID + '/' + newValue,
            headers: {
                'Authorization': 'Bearer ' + this.state.token
            },
            withCredentials: true
        };

        axios(config).then(reponse => {
            if (reponse) {
                if (activeValue === 0) {
                    Swal.fire({
                        title: 'Cliente activado correctamente',
                        icon: 'success'
                    })
                    this.setState((prevState) => ({
                        userEdit: { ...prevState.userEdit, active: 1 }
                    }), () => {
                        this.props.updateForms(this.state.userEdit)
                    });

                } else {
                    Swal.fire({
                        title: 'Cliente desactivado correctamente',
                        icon: 'success'
                    })
                    this.setState((prevState) => ({
                        userEdit: { ...prevState.userEdit, active: 0 }
                    }), () => {
                        this.props.updateForms(this.state.userEdit)
                    });
                }
            } else {
                if (activeValue === 1) {
                    Swal.fire({
                        title: 'Ocurrio un error al intentar activar el cliente',
                        icon: 'error'
                    })
                    this.setState((prevState) => ({
                        userEdit: { ...prevState.userEdit, active: 0 }
                    }));
                } else {
                    Swal.fire({
                        title: 'Ocurrio un error al intentar desactivar el cliente',
                        icon: 'error'
                    })
                    this.setState((prevState) => ({
                        userEdit: { ...prevState.userEdit, active: 1 }
                    }));
                }
            }
        }).catch(err => {
            Swal.fire({
                title: 'Ocurrio un error al intentar cambiar el estado de activo del cliente, intentelo nuevamente.',
                icon: 'error'
            })
        })
    }

    /**
     * Metodo utilizado para validar el formulario de edición del usuario
     * @param {*} e Elemento que disparó el evento
     * @returns True si el formulario se encuentra correcto, False en caso contrario.
     */
    valdidateForm = (e) => {
        const form = document.getElementById("formInfo");
        this.setState({
            validated: true
        })
        if (form.checkValidity() === false) {
            e.preventDefault();
            e.stopPropagation();
            this.setState({
                validForm: false
            })
            return false
        } else {
            this.setState({
                validForm: true
            })
            return true
        }
    }

    /**
     * Metodo que verifica si en la respuesta que entrega el back a la hora de guardar una actualización hay algun atributo nulo o undefined
     * En caso de ser así significa que el back no guardó la actualización de ese campo en particular por algún error
     * @param {*} response Json que contiene la respuesta del back (Estructura del cliente actualizado)
     * @returns Lista de atributos que no pudieron ser actualizados
     */
    atributeNull(response) {
        let keyWithError = []
        for (let key in response) {
            if (key === undefined || key === null) {
                keyWithError.append(key)
            }
        }
        return keyWithError
    }

/*     convertirFormatoFecha = (fechaEnFormatoYYYYMMDD) => {
        const partesFecha = fechaEnFormatoYYYYMMDD.split("-");
        const nuevaFecha = partesFecha[2] + "-" + partesFecha[1] + "-" + partesFecha[0];
        return nuevaFecha;
    }; */

    /**
     * Metodo utilizado para realizar la actualización del cliente
     * @param {*} e elemento (form) que activó el evento
     */
    handleSubmit = (e) => {
        let valid = true
        valid = this.valdidateForm(e)
        e.preventDefault();
        this.state.merchanIdList.forEach(element => {
            if (this.state.userEdit.merchantID === element && this.state.userEdit.merchantID !== this.state.merchantID) {
                this.setState({
                    validForm: false,
                    numMerchantOk: false
                }, () => {

                    Swal.fire({
                        title: "El número de comerciante ya existe"
                    })
                })
                this.valdidateForm(e)
                valid = false
            }
        });


        if (valid) {
            let terminales = 0
            if (this.state.userEdit.terminales !== undefined) {
                terminales = this.state.userEdit.terminales.length
            }
            var service = this.state.userEdit.service
            if (this.state.userEdit.service === "") {
                service = "IVU PRO[99.00/AÑO]"
            }

            var state = this.state.userEdit.state
            if (state === "") {
                state = "PR"
            }

            this.setState((prevState) => ({
                userEdit: { ...prevState.userEdit, oldMerchantId: prevState.merchantID, fiscalterminals: terminales, service: service, state: state }
            }),
                () => {
                    var zipcode = this.state.userEdit.zipcode
                    if (zipcode.toString().length < 5) {
                        zipcode = "00" + zipcode.toString()
                    }
                    let userEditado = {
                        "idUser": this.state.userEdit.idUser,
                        /**Mirar si lo que va en el merchantID nuevo es merchantID o merchantNumber */
                        "merchantID": this.state.userEdit.merchantID,
                        "oldMerchantId": this.state.userEdit.oldMerchantId,
                        "idRegister": this.state.userEdit.idRegister,
                        "username": this.state.userEdit.username,
                        "lastName": this.state.userEdit.lastName,
                        "secondLastName": this.state.userEdit.secondLastName,
                        "merchantName": this.state.userEdit.merchantName,
                        "address": this.state.userEdit.address,
                        "id_add": this.state.userEdit.id_add,
                        "phone": this.state.userEdit.phone,
                        "email": this.state.userEdit.email,
                        "software": this.state.userEdit.software,
                        "paymethod": this.state.userEdit.paymethod,
                        "active": this.state.userEdit.active,
                        "service": this.state.userEdit.service,
                        "fiscalterminals": this.state.userEdit.fiscalterminals,
                        "idtown": this.state.userEdit.idtown,
                        "zipcode": zipcode,
                        "state": this.state.userEdit.state,
                        "terminals2to5": Number(this.state.userEdit.terminals2to5),
                        "terminals6to9": Number(this.state.userEdit.terminals6to9),
                        "terminals10": Number(this.state.userEdit.terminals10),
                        "idReseller": this.state.userEdit.idReseller,
                        "expirationDate": this.state.userEdit.expirationDate
                    }
                  
                    const config = {
                        method: "put",
                        url: global.URLBack + `/dashBoard/superUser/updateClient`,
                        headers: {
                            "Content-Type": "application/json",
                            'Authorization': 'Bearer ' + this.state.token
                        },
                        data: userEditado,
                        withCredentials: true,
                    };

                    axios(config)
                        .then((res) => {
                            this.setState((prevState) => ({
                                userEdit: { ...prevState.userEdit, idRegister: res.data.idRegister, idUser: res.data.idUser, id_add: res.data.id_add }
                            }), () => {
                                this.props.updateForms(this.state.userEdit)
                                this.setState({
                                    userEdit: this.state.userEdit
                                })
                            })
                            this.handleClose()
                            let keyErrors = this.atributeNull(res)
                            if (keyErrors.length === 0) {
                                Swal.fire({
                                    title: 'Cliente actualizado correctamente',
                                    icon: 'success'
                                }

                                )
                                if (this.state.userEdit.software === undefined || this.state.userEdit.software.length === 0) {
                                    this.setState({
                                        selectSoftware: true
                                    })
                                } else {
                                    this.setState({
                                        selectSoftware: false
                                    })
                                }
                            } else {
                                let messageError = ""
                                keyErrors.forEach(element => {
                                    messageError = messageError + " " + element
                                });
                                Swal.fire({
                                    title: 'Cliente parcialmente actualizado',
                                    text: 'Los elementos de ' + messageError + ' no pudieron ser actualizados',
                                    icon: 'warning'
                                })
                            }



                        }).catch((err) => {
                        
                            this.handleClose()
                            Swal.fire({
                                icon: 'error',
                                title: 'Oops...',
                                text: 'Algo salio mal al intentar editar el cliente, intentalo nuevamente.'
                            })
                        })
                }
            );
        }

    };

    /**
     * Metodo utilizado para ocultar un Toast
     */
    toggleShowA = () => {
        this.setState({
            showToast: false
        })
    }

    /**
     * Metodo utilizado para enviar el correo de notificación de atraso
     */
    sendNotification = () => {
        if (this.state.userEdit.afterNotification !== "") {
            Swal.fire({
                title: 'Está seguro que desea enviar la notificación de atraso?',
                icon: 'warning',
                showCancelButton: true,
                reverseButtons: true,
                confirmButtonText: 'Enviar notificación',
                cancelButtonText: 'Cancelar'
            }).then((result) => {
                if (result.isConfirmed) {
                    const config = {
                        method: "post",
                        url:
                            global.URLBack + `/dashBoard/superUser/sendDelayEmail/` +
                            this.state.userEdit.idUser,
                        headers: {
                            "Authorization": "Bearer " + this.state.token
                        },
                        withCredentials: true
                    };
                    axios(config).then(res => {
                        if (res !== undefined) {
                            Swal.fire({
                                title: "Notificación enviada correctamente",
                                icon: 'success'
                            })
                        }
                    }).catch((err) => {
                        Swal.fire({
                            title: "Ocurrió un error al enviar la notificación",
                            text: 'Por favor intentelo más tarde',
                            icon: 'error'
                        })
                    })
                }
            })

        } else {
            Swal.fire({
                title: "Solo puede enviar la notificación de atraso cuando las otras notificaciones hayan sido enviadas",
                icon: 'error'
            })
        }
    }

    /**
     * Metodo de renderizado
     * @returns html que contiene el botón que despliega el modal y el modal
     */
    render() {
        const { show, role } = this.state;
        const { validated } = this.state
        var { userEdit } = this.state;
        return (
            <>
                <button className="editButton" onClick={this.handleShow} ><FontAwesomeIcon icon={faPen} size="lg" /></button>


                <Modal
                    show={show}
                    onHide={this.handleClose}
                    dialogClassName="my-modal"
                >
                    <Modal.Header closeButton>
                        <svg
                            viewBox="0 0 24 24"
                            fill="currentColor"
                            height="3em"
                            width="3em"
                            color='#26c8e5 '
                        >
                            <path d="M19.148 2.971A2.008 2.008 0 0017.434 2H6.566c-.698 0-1.355.372-1.714.971L2.143 7.485A.995.995 0 002 8a3.97 3.97 0 001 2.618V19c0 1.103.897 2 2 2h14c1.103 0 2-.897 2-2v-8.382A3.97 3.97 0 0022 8a.995.995 0 00-.143-.515l-2.709-4.514zm.836 5.28A2.003 2.003 0 0118 10c-1.103 0-2-.897-2-2 0-.068-.025-.128-.039-.192l.02-.004L15.22 4h2.214l2.55 4.251zM10.819 4h2.361l.813 4.065C13.958 9.137 13.08 10 12 10s-1.958-.863-1.993-1.935L10.819 4zM6.566 4H8.78l-.76 3.804.02.004C8.025 7.872 8 7.932 8 8c0 1.103-.897 2-2 2a2.003 2.003 0 01-1.984-1.749L6.566 4zM10 19v-3h4v3h-4zm6 0v-3c0-1.103-.897-2-2-2h-4c-1.103 0-2 .897-2 2v3H5v-7.142c.321.083.652.142 1 .142a3.99 3.99 0 003-1.357c.733.832 1.807 1.357 3 1.357s2.267-.525 3-1.357A3.99 3.99 0 0018 12c.348 0 .679-.059 1-.142V19h-3z" />
                        </svg>
                        <Modal.Title id="example-custom-modal-styling-title" className="mod-title" >
                            Editar Cliente
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {this.state.showToast &&
                            < React.Fragment >
                                <ToastContainer
                                    className="p-3"
                                    position="middle-center"
                                    style={{ zIndex: 1 }}
                                >
                                    <Toast show={this.state.showToast} onClose={this.toggleShowA}>
                                        <Toast.Header>
                                            <img src="holder.js/20x20?text=%20" className="rounded me-2" alt="" />
                                            <strong className="me-auto">{this.state.toastTitle}</strong>
                                        </Toast.Header>
                                        <Toast.Body>{this.state.toastMessage}</Toast.Body>
                                    </Toast>
                                </ToastContainer>
                            </React.Fragment >
                        }
                        {this.state.loading ?
                            <div className="spinner"></div>
                            :
                            <div className="row">
                                <div className="col-5">
                                    <h5 >Fecha Creado: {userEdit.registerDate}</h5>

                                    <Form noValidate className="form-style" id="formInfo" validated={validated} >
                                        <Form.Group className="mb-3" >
                                            <Form.Label>Fecha de Expiración *</Form.Label>
                                            <Form.Control type="text" value={userEdit.expirationDate} required name="expirationDate" pattern="^(?!0000-00-00)\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01])$" onChange={this.handleChange} readOnly={Number(role) === 3} />
                                            <Form.Control.Feedback type="invalid" >
                                                Por favor ingrese una fecha valida (yyyy-mm-dd)
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group className="mb-3" >
                                            <Form.Label>Nombre *</Form.Label>
                                            <Form.Control type="text" value={userEdit.username} required pattern="^(?!\s*$).+" name="username" minLength={3} onChange={this.handleChange} readOnly={Number(role) === 3} />
                                            <Form.Control.Feedback type="invalid" >
                                                Por favor ingrese el nombre del cliente. Debe ser mínimo de 3 caracteres.
                                            </Form.Control.Feedback>
                                        </Form.Group>  <Form.Group className="mb-3" >
                                            <Form.Label>Primer Apellido *</Form.Label>
                                            <Form.Control type="text" value={userEdit.lastName} required name="lastName" pattern="^(?!\s*$).+" minLength={3} onChange={this.handleChange} readOnly={Number(role) === 3} />
                                            <Form.Control.Feedback type="invalid" >
                                                Por favor ingrese el primer apellido del cliente. Debe ser mínimo de 3 caracteres.
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group className="mb-3" >
                                            <Form.Label>Segundo Apellido *</Form.Label>
                                            <Form.Control type="text" value={userEdit.secondLastName} required name="secondLastName" pattern="^(?!\s*$).+" minLength={3} onChange={this.handleChange} readOnly={Number(role) === 3} />
                                            <Form.Control.Feedback type="invalid" >
                                                Por favor ingrese el segundo apellido del cliente. Debe ser mínimo de 3 caracteres.
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group className="mb-3" >
                                            <Form.Label>Negocio *</Form.Label>
                                            <Form.Control type="text" value={userEdit.merchantName} required name="merchantName" pattern="^(?!\s*$).+" minLength={4} onChange={this.handleChange} readOnly={Number(role) === 3} />
                                            <Form.Control.Feedback type="invalid" >
                                                Por favor ingrese el nombre del negocio con mínimo 4 caracteres
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group className="mb-3" >
                                            <Form.Label>Dirección *</Form.Label>
                                            <Form.Control type="text" value={userEdit.address} required name="address" pattern="^(?!\s*$).+" onChange={this.handleChange} readOnly={Number(role) === 3} />
                                            <Form.Control.Feedback type="invalid" >
                                                Por favor ingrese la dirección
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Row className="mb-3">
                                            <Form.Group as={Col} controlId="idtown">
                                                <Form.Label>Ciudad</Form.Label>
                                                <Form.Select required name="idtown" value={userEdit.idtown} onChange={this.handleChange} readOnly={Number(role) === 3} disabled={Number(role) === 3}>
                                                    {

                                                        this.state.towns.map((town, indexT) => {
                                                            return (
                                                                <option key={indexT} value={town.id}>{town.value}</option>
                                                            )
                                                        })

                                                    }


                                                </Form.Select>

                                            </Form.Group>

                                            <Form.Group as={Col} controlId="state">
                                                <Form.Label>Estado</Form.Label>
                                                <Form.Control disabled value={userEdit.state === undefined || userEdit.state.length === 0 ? "PR" : userEdit.state} name="state" readOnly={Number(role) === 3} />
                                            </Form.Group>

                                            <Form.Group as={Col} controlId="zipcode">
                                                <Form.Label>Zip</Form.Label>
                                                <Form.Control type="text" required name="zipcode" value={userEdit.zipcode} maxLength={5} minLength={5} onChange={this.handleChange} readOnly={Number(role) === 3} />
                                                <Form.Control.Feedback type="invalid" >
                                                    Por favor ingrese el zip, recuerde que es de 5 digitos
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Row>

                                        <Form.Group className="mb-3" >
                                            <Form.Label>Telefono</Form.Label>
                                            {/*    <InputMask  type="text" mask="999-999-9999" value={userEdit.phone} required name="phone" onChange={this.handleChange}>
                                        </InputMask> */}
                                            <Form.Control type="text" mask="999-999-9999" id="phone" value={userEdit.phone} pattern="^(?!\s*$).+" required name="phone" maxLength={15} onChange={this.handleChange} readOnly={Number(role) === 3} />
                                            <Form.Control.Feedback type="invalid" >
                                                Por favor ingrese el telefono
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group className="mb-3" >
                                            <Form.Label>Correo *</Form.Label>
                                            <Form.Control type="email" value={userEdit.email} required name="email" pattern="^(?!\s*$).+" onChange={this.handleChange} readOnly={Number(role) === 3} />
                                            <Form.Control.Feedback type="invalid" >
                                                Por favor ingrese el correo
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group className="mb-3" >
                                            <Form.Label># de comerciante *</Form.Label>
                                            <Form.Control type="text" value={userEdit.merchantID} required name="merchantID" onChange={this.handleChange} pattern="[0-9]{11}" readOnly={Number(role) === 3} />
                                            <Form.Control.Feedback type="invalid" >
                                                Por favor ingrese el número de comerciante, recuerde que debe ser de 11 dígitos
                                                (Solo números)
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group className="mb-3" >
                                            <Form.Label>Servicio *</Form.Label>
                                            <Form.Select aria-label="Default select example" required name="service" onChange={this.handleChange} value={userEdit.service} readOnly={Number(role) === 3} disabled={Number(role) === 3}>
                                                {this.state.services
                                                    .filter(service => service.active === true)
                                                    .map((service, index) => (
                                                        <option key={index} value={service.serviceName}>
                                                            {service.serviceName}
                                                        </option>
                                                    ))
                                                }
                                            </Form.Select>
                                        </Form.Group>
                                        {this.state.selectSoftware ?
                                            <Form.Group className="mb-3" >
                                                <Form.Label>Software *</Form.Label>
                                                <Form.Select aria-label="Default select example" required name="software" onChange={this.handleChange} value={userEdit.software} readOnly={Number(role) === 3} disabled={Number(role) === 3}>
                                                    {this.state.softwareList
                                                        .map((software, index) => (
                                                            <option key={index} value={software.value}>
                                                                {software.value}
                                                            </option>
                                                        ))
                                                    }
                                                </Form.Select>
                                            </Form.Group> :
                                            <Form.Group className="mb-3" >
                                                <Form.Label>Software *</Form.Label>
                                                <Form.Control type="text" value={userEdit.software} required name="software" readOnly disabled />
                                                {/*  <Form.Select aria-label="Default select example" required name="software" onChange={this.handleChange} value={userEdit.software}>
      <option value="RETAIL MANAGER">Retail Manager</option>
      <option value="CLOVER">Clover</option>
      <option value="ALDELO">Aldelo</option>
      <option value="RM PAY">RM Pay</option>
      <option value="">Ninguno</option>
  </Form.Select>
  <Form.Control.Feedback type="invalid" >
      Por favor ingrese el software
  </Form.Control.Feedback> */}
                                            </Form.Group>
                                        }


                                        {userEdit.id_add !== 0 ?
                                            <div className="row">
                                                <div className="col-6">
                                                    <Form.Check // prettier-ignore
                                                        type='checkbox'
                                                        checked={userEdit.idFormMC !== 0}
                                                        name="idFormMC"
                                                        label='Certificado de registro de comerciante'
                                                        readOnly
                                                    />
                                                </div>

                                                <div className="col">
                                                    {/* {userEdit.idFormMC === 0 ? */}
                                                    <Button variant="info" className="button-download me-3" size="sm" >
                                                        <label >
                                                            <svg
                                                                viewBox="0 0 24 24"
                                                                fill="currentColor"
                                                                height="2em"
                                                                width="2em"
                                                                color='white'
                                                            >
                                                                <path d="M13 20H6a1 1 0 01-1-1V5a1 1 0 011-1h5v3a3 3 0 003 3h3v2a1 1 0 002 0V9v-.06a1.31 1.31 0 00-.06-.27v-.09a1.07 1.07 0 00-.19-.28l-6-6a1.07 1.07 0 00-.28-.19.32.32 0 00-.09 0 .88.88 0 00-.33-.11H6a3 3 0 00-3 3v14a3 3 0 003 3h7a1 1 0 000-2zm0-14.59L15.59 8H14a1 1 0 01-1-1zM8 8a1 1 0 000 2h1a1 1 0 000-2zm6 4H8a1 1 0 000 2h6a1 1 0 000-2zm6.71 5.29l-2-2a1 1 0 00-.33-.21 1 1 0 00-.76 0 1 1 0 00-.33.21l-2 2a1 1 0 001.42 1.42l.29-.3V21a1 1 0 002 0v-2.59l.29.3a1 1 0 001.42 0 1 1 0 000-1.42zM12 18a1 1 0 000-2H8a1 1 0 000 2z" />
                                                            </svg>
                                                            <input className="fales"
                                                                type="file"
                                                                id="file1"
                                                                name="mcLoad"
                                                                placeholder="Cargar"
                                                                accept="application/pdf"
                                                                onChange={(e) => this.handleFiles(e)}
                                                            />

                                                            Cargar
                                                        </label >
                                                    </Button>

                                                    <Button variant="info" className="button-download" size="sm" onClick={this.handleClickMC.bind(this)}>
                                                        <svg
                                                            viewBox="0 0 24 24"
                                                            fill="currentColor"
                                                            height="2em"
                                                            width="2em"
                                                            color='white'
                                                        >
                                                            <path d="M8 8a1 1 0 000 2h1a1 1 0 000-2zm5 12H6a1 1 0 01-1-1V5a1 1 0 011-1h5v3a3 3 0 003 3h3v2a1 1 0 002 0V9v-.06a1.31 1.31 0 00-.06-.27v-.09a1.07 1.07 0 00-.19-.28l-6-6a1.07 1.07 0 00-.28-.19.29.29 0 00-.1 0 1.1 1.1 0 00-.31-.11H6a3 3 0 00-3 3v14a3 3 0 003 3h7a1 1 0 000-2zm0-14.59L15.59 8H14a1 1 0 01-1-1zM14 12H8a1 1 0 000 2h6a1 1 0 000-2zm6.71 6.29a1 1 0 00-1.42 0l-.29.3V16a1 1 0 00-2 0v2.59l-.29-.3a1 1 0 00-1.42 1.42l2 2a1 1 0 00.33.21.94.94 0 00.76 0 1 1 0 00.33-.21l2-2a1 1 0 000-1.42zM12 18a1 1 0 000-2H8a1 1 0 000 2z" />
                                                        </svg>
                                                        Descargar
                                                    </Button>
                                                    {/* } */}
                                                </div>


                                            </div> : null
                                        }
                                        {userEdit.id_add !== 0 ?
                                            <div className="row mb-3">
                                                <div className="col">
                                                    <Form.Check // prettier-ignore
                                                        type='checkbox'
                                                        checked={userEdit.idFormSc !== 0}
                                                        name="idFormSc"
                                                        label='Formulario SC2916'
                                                        readOnly
                                                    />

                                                </div>

                                                <div className="col">
                                                    {/* {userEdit.idFormSc === 0 ? */}
                                                    <Button variant="info" className="button-download me-3" size="sm" >
                                                        <label >
                                                            <svg
                                                                viewBox="0 0 24 24"
                                                                fill="currentColor"
                                                                height="2em"
                                                                width="2em"
                                                                color='white'
                                                            >
                                                                <path d="M13 20H6a1 1 0 01-1-1V5a1 1 0 011-1h5v3a3 3 0 003 3h3v2a1 1 0 002 0V9v-.06a1.31 1.31 0 00-.06-.27v-.09a1.07 1.07 0 00-.19-.28l-6-6a1.07 1.07 0 00-.28-.19.32.32 0 00-.09 0 .88.88 0 00-.33-.11H6a3 3 0 00-3 3v14a3 3 0 003 3h7a1 1 0 000-2zm0-14.59L15.59 8H14a1 1 0 01-1-1zM8 8a1 1 0 000 2h1a1 1 0 000-2zm6 4H8a1 1 0 000 2h6a1 1 0 000-2zm6.71 5.29l-2-2a1 1 0 00-.33-.21 1 1 0 00-.76 0 1 1 0 00-.33.21l-2 2a1 1 0 001.42 1.42l.29-.3V21a1 1 0 002 0v-2.59l.29.3a1 1 0 001.42 0 1 1 0 000-1.42zM12 18a1 1 0 000-2H8a1 1 0 000 2z" />
                                                            </svg>
                                                            <input className="fales"
                                                                type="file"
                                                                id="file2"
                                                                name="scLoad"
                                                                placeholder="Cargar"
                                                                accept="application/pdf"
                                                                onChange={(e) => this.handleFiles(e)}
                                                            />

                                                            Cargar
                                                        </label >
                                                    </Button>
                                                    <Button variant="info" className="button-download" size="sm" onClick={this.handleClickSC.bind(this)}>
                                                        <svg
                                                            viewBox="0 0 24 24"
                                                            fill="currentColor"
                                                            height="2em"
                                                            width="2em"
                                                            color='white'
                                                        >
                                                            <path d="M8 8a1 1 0 000 2h1a1 1 0 000-2zm5 12H6a1 1 0 01-1-1V5a1 1 0 011-1h5v3a3 3 0 003 3h3v2a1 1 0 002 0V9v-.06a1.31 1.31 0 00-.06-.27v-.09a1.07 1.07 0 00-.19-.28l-6-6a1.07 1.07 0 00-.28-.19.29.29 0 00-.1 0 1.1 1.1 0 00-.31-.11H6a3 3 0 00-3 3v14a3 3 0 003 3h7a1 1 0 000-2zm0-14.59L15.59 8H14a1 1 0 01-1-1zM14 12H8a1 1 0 000 2h6a1 1 0 000-2zm6.71 6.29a1 1 0 00-1.42 0l-.29.3V16a1 1 0 00-2 0v2.59l-.29-.3a1 1 0 00-1.42 1.42l2 2a1 1 0 00.33.21.94.94 0 00.76 0 1 1 0 00.33-.21l2-2a1 1 0 000-1.42zM12 18a1 1 0 000-2H8a1 1 0 000 2z" />
                                                        </svg>
                                                        Descargar
                                                    </Button>
                                                    {/* } */}
                                                </div>
                                            </div> : null
                                        }
                                        <p className='mt-1'>Si desea actualizar la información presione "Cargar Archivo" </p>
                                        <div className="row">
                                            <div className="col-4">
                                                <p>Metodo de pago: *</p>
                                            </div>
                                            <div className="col">
                                                <Form.Select aria-label="Default select example" required name="paymethod" value={userEdit.paymethod} onChange={this.handleChange} readOnly={Number(role) === 3} disabled={Number(role) === 3}>
                                                    {this.state.paymentMethods.map((method, index) => (
                                                        <option key={index} value={method.code}>{method.name}</option>
                                                    ))
                                                    }
                                                </Form.Select>
                                                <Form.Control.Feedback type="invalid" >
                                                    Por favor seleccione un metodo de pago
                                                </Form.Control.Feedback>
                                            </div>
                                        </div>
                                        <div className="row mt-3">
                                            <div className="col-2">
                                                <p>Revendedor: </p>
                                            </div>
                                            <div className="col">
                                                <Form.Select aria-label="Default select example" required name="idReseller" value={userEdit.idReseller} onChange={this.handleChange} readOnly={Number(role) === 3} disabled={Number(role) === 3}>
                                                    {this.state.resellers.filter(reseller => reseller.status === true).map((reseller, index) => (
                                                        <option key={index} value={reseller.id}>{reseller.firstname + " " + reseller.lastname}</option>
                                                    ))
                                                    }
                                                    <option value="-1">Ninguno</option>
                                                </Form.Select>
                                                <Form.Control.Feedback type="invalid" >
                                                    Por favor seleccione un revendedor
                                                </Form.Control.Feedback>
                                            </div>

                                            <div className="col-5">
                                                <p><strong>Costo</strong></p>
                                                <Form.Group className="mb-3" >
                                                    <Form.Label>Terminales 2-5 *</Form.Label>
                                                    <InputGroup className="mb-3">
                                                        <InputGroup.Text>$</InputGroup.Text>
                                                        <Form.Control aria-label="Amount (to the nearest dollar)" type="number" value={userEdit.terminals2to5} required name="terminals2to5" onChange={this.handleChange} readOnly={Number(role) === 3} />

                                                        <Form.Control.Feedback type="invalid" >
                                                            Por favor ingrese el valor de la terminal adicional
                                                        </Form.Control.Feedback>
                                                    </InputGroup>
                                                </Form.Group>
                                                <Form.Group className="mb-3" >
                                                    <Form.Label>Terminales 6-9 *</Form.Label>
                                                    <InputGroup className="mb-3">
                                                        <InputGroup.Text>$</InputGroup.Text>
                                                        <Form.Control aria-label="Amount (to the nearest dollar)" type="number" value={userEdit.terminals6to9} required name="terminals6to9" onChange={this.handleChange} readOnly={Number(role) === 3} />

                                                        <Form.Control.Feedback type="invalid" >
                                                            Por favor ingrese el valor de la terminal adicional
                                                        </Form.Control.Feedback>
                                                    </InputGroup>
                                                </Form.Group>
                                                <Form.Group className="mb-3" >
                                                    <Form.Label>Terminales 10 O MÁS *</Form.Label>
                                                    <InputGroup className="mb-3">
                                                        <InputGroup.Text>$</InputGroup.Text>
                                                        <Form.Control aria-label="Amount (to the nearest dollar)" type="number" value={userEdit.terminals10} required name="terminals10" onChange={this.handleChange} readOnly={Number(role) === 3} />

                                                        <Form.Control.Feedback type="invalid" >
                                                            Por favor ingrese el valor de la terminal adicional
                                                        </Form.Control.Feedback>
                                                    </InputGroup>
                                                </Form.Group>
                                            </div>
                                        </div>
                                        {/*  <div className="row mt-3">
                                            <div className="col-6">
                                                <p>Precio de terminal adicional: </p>
                                            </div>
                                            <div className="col">
                                                <InputGroup className="mb-3">
                                                    <InputGroup.Text>$</InputGroup.Text>
                                                    <Form.Control aria-label="Amount (to the nearest dollar)" type="number" value={userEdit.additionalTerminal === 0 ? 99 : userEdit.additionalTerminal} required name="additionalTerminal" onChange={this.handleChange} readOnly={Number(role) === 3} />
                                                    <InputGroup.Text>.00</InputGroup.Text>
                                                    <Form.Control.Feedback type="invalid" >
                                                        Por favor ingrese el valor de la terminal adicional
                                                    </Form.Control.Feedback>
                                                </InputGroup>

                                            </div>
                                        </div> */}
                                    </Form>
                                </div>
                                <div className="col card-cont">
                                    <div className="card card-term" id="card-t" >
                                        <div className="card-det">
                                            <h4 className="text-t">Terminales Activos</h4>
                                            <h5 className="text-b">{userEdit.terminales !== undefined ?
                                                userEdit.terminales.length :
                                                0
                                            }</h5>
                                        </div>
                                    </div>

                                </div>
                                <div className="col-5">
                                    {/*    <h5 >Fecha Expiración: {userEdit.expirationDate}</h5> */}

                                    <div className="row">
                                        <div className="col">
                                            <Link to={`/admin/payBill/${userEdit.merchantNumber}`}>
                                                <div className="card card-term" id="pago">

                                                    <div className="card-det">
                                                        {Number(role) === 3 ?
                                                            <h5 className="text-t">Historial de Pagos</h5>
                                                            :
                                                            <h5 className="text-t">Postear pagos / Historial</h5>

                                                        }
                                                    </div>
                                                    <svg viewBox="0 0 1024 1024" className="icon centered-svg" xmlns="http://www.w3.org/2000/svg">
                                                        <g id="SVGRepo_bgCarrier" strokeWidth="0" />
                                                        <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round" />
                                                        <g id="SVGRepo_iconCarrier">
                                                            <path d="M512 512m-480 0a480 480 0 1 0 960 0 480 480 0 1 0-960 0Z" fill="#FFE8CD" />
                                                            <path d="M224 364.8c0-25.6 19.2-44.8 51.2-44.8h480c25.6 0 51.2 19.2 51.2 44.8v288c0 25.6-19.2 44.8-51.2 44.8H275.2c-25.6 0-51.2-19.2-51.2-44.8V364.8z" fill="#FF9D1C" />
                                                            <path d="M224 390.4h576v70.4h-576z" fill="#FFCA83" />
                                                            <path d="M633.6 608c0-12.8 12.8-25.6 25.6-25.6h70.4c12.8 0 25.6 12.8 25.6 25.6v25.6c0 12.8-12.8 25.6-25.6 25.6h-70.4c-12.8 0-25.6-12.8-25.6-25.6v-25.6z" fill="#FFFFFF" />
                                                        </g>
                                                    </svg>

                                                </div>



                                            </Link>
                                        </div>
                                        {Number(role) !== 3 ?
                                            <div className="col">
                                                {/* <Link to="/users"> */}
                                                <div className="card card-term" id="notifi" onClick={this.sendNotification}>

                                                    <div className="card-det">
                                                        {userEdit.afterNotification === "" ?
                                                            <h5 className="text-t">Notificación enviada</h5>
                                                            :
                                                            <h5 className="text-t">Enviar Notificación</h5>

                                                        }
                                                    </div>
                                                    <div className="row" >
                                                        <div className="col-5">
                                                            <svg viewBox="0 0 50 50" className="emailSvg" id="Message_And_Communication_Icons" version="1.1" xmlSpace="preserve" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" fill="#000000">
                                                                <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                                                                <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
                                                                <g id="SVGRepo_iconCarrier">
                                                                    <g>
                                                                        <g>
                                                                            <g>
                                                                                <polygon points="25.3,30.2 37.5,22.4 37.5,25.3 25.3,33" fill="#4DE0F9"></polygon>
                                                                            </g>
                                                                        </g>
                                                                        <g>
                                                                            <g>
                                                                                <polygon points="35.3,25.4 32.6,27.1 32.6,11 35.3,11" fill="#4DE0F9"></polygon>
                                                                            </g>
                                                                        </g>
                                                                        <g>
                                                                            <g>
                                                                                <rect height="3.9" fill="#4DE0F9" width="16.1" x="25.3" y="38.9"></rect>
                                                                            </g>
                                                                        </g>
                                                                        <g>
                                                                            <g>
                                                                                <polygon points="37.2,26.9 41.4,24.1 41.4,42.8 37.2,42.8" fill="#4DE0F9"></polygon>
                                                                            </g>
                                                                        </g>
                                                                        <g>
                                                                            <g>
                                                                                <polygon points="40.5,41.7 10,41.7 10,22.5 11.2,22.5 11.2,40.5 39.3,40.5 39.3,22.5 40.5,22.5" fill="#0D5FC3"></polygon>
                                                                            </g>
                                                                        </g>
                                                                        <g>
                                                                            <g>
                                                                                <path d="M25.3,32.4c-0.1,0-0.2,0-0.3-0.1L10.3,23c-0.2-0.1-0.3-0.3-0.3-0.5s0.1-0.4,0.3-0.5l5.7-3.6l0.6,1 l-4.9,3.1l13.5,8.6l13.5-8.6l-4.9-3.1l0.6-1l5.7,3.6c0.2,0.1,0.3,0.3,0.3,0.5s-0.1,0.4-0.3,0.5l-14.6,9.3 C25.5,32.4,25.4,32.4,25.3,32.4z" fill="#0D5FC3"></path>
                                                                            </g>
                                                                        </g>
                                                                        <g>
                                                                            <g>
                                                                                <rect height="4.6" fill="#4DE0F9" width="1.5" x="12.8" y="34.7"></rect>
                                                                            </g>
                                                                        </g>
                                                                        <g>
                                                                            <g>
                                                                                <rect height="1.5" fill="#4DE0F9" width="1.5" x="12.8" y="31.8"></rect>
                                                                            </g>
                                                                        </g>
                                                                        <g>
                                                                            <g>
                                                                                <polygon points="34.8,26.2 33.6,26.2 33.6,9.8 17,9.8 17,26 15.8,26 15.8,8.6 34.8,8.6" fill="#0D5FC3"></polygon>
                                                                            </g>
                                                                        </g>
                                                                        <g>
                                                                            <g>
                                                                                <polygon points="25.3,32.5 19.6,29 20.3,27.9 25.3,31.1 30.5,27.8 31.2,28.8" fill="#0D5FC3"></polygon>
                                                                            </g>
                                                                        </g>
                                                                        <g>
                                                                            <g>
                                                                                <path d="M28.8,22.9h-1.5c-0.5,0-1-0.4-1-1v-1.3H24c-0.5,0-1-0.4-1-1v-2.3c0-0.5,0.4-1,1-1h2.5 c0.5,0,1,0.4,1,1v4.3h1.1V15h-6.6v6.6H25v1.2h-3.3c-0.5,0-1-0.4-1-1v-7.1c0-0.5,0.4-1,1-1h7.1c0.5,0,1,0.4,1,1v7.1 C29.8,22.4,29.3,22.9,28.8,22.9z M24.3,19.4h2v-1.8h-2V19.4z" fill="#0D5FC3"></path>
                                                                            </g>
                                                                        </g>
                                                                    </g>
                                                                </g>
                                                            </svg>
                                                        </div>
                                                        <div className="col">
                                                            <Form.Check checked={userEdit.priorNotification !== ""} aria-label="aviso" label="Aviso" readOnly />
                                                            <Form.Check checked={userEdit.lastDayNotification !== ""} aria-label="vencido" label="Vencido" readOnly />
                                                            <Form.Check checked={userEdit.afterNotification !== ""} aria-label="atraso" label="Atraso" readOnly />
                                                        </div>
                                                    </div>

                                                </div>
                                                {/* </Link> */}
                                            </div> : null
                                        }

                                    </div>

                                    <div className="row table_edit" >
                                        {userEdit.terminales !== undefined && userEdit.terminales.length > 0 &&
                                            <Table striped responsive className="table_edit" >
                                                <thead>
                                                    <tr>
                                                        <th>Terminales</th>
                                                        <th >Software</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        userEdit.terminales.map((terminal, i) => {
                                                            return (
                                                                <tr key={i}>
                                                                    <td>{terminal.terminalNumber}</td>
                                                                    {terminal.software !== undefined ?
                                                                        <td>{terminal.software}</td> :
                                                                        <td></td>
                                                                    }

                                                                </tr>
                                                            )
                                                        })

                                                    }
                                                </tbody>
                                            </Table>
                                        }
                                    </div>

                                    <div className="row margin-btn" >
                                        {Number(role) !== 3 ?
                                            <div className="col">
                                                {userEdit.active === 1 ?
                                                    <Button variant="warning" name="inactivate" className="btn-block" size="lg" onClick={() => this.handelActivation()}>Desactivar</Button> :
                                                    <Button variant="success" name="activate" className="btn-block" size="lg" onClick={() => this.handelActivation()}>Activar</Button>
                                                }
                                            </div> : null
                                        }

                                        <div className="col">
                                            <Link to={`/admin/reportActivity/${userEdit.merchantID}`}>
                                                <Button variant="primary" className="btn-block" size="lg" >Transacciones del mes actual</Button>
                                            </Link>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        }

                    </Modal.Body>
                    {Number(role) !== 3 ?
                        <Modal.Footer>
                            <Button variant="danger" onClick={this.handleClose}>Cancelar</Button>
                            <Button variant="success" onClick={this.handleSubmit} disabled={!this.state.validForm} >Guardar cambios</Button>

                        </Modal.Footer> : null
                    }

                </Modal >
            </>
        );
    }

}

export default ModalEditUser;