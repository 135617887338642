import { useState, useEffect, useRef } from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Nav from 'react-bootstrap/Nav';
import Accordion from 'react-bootstrap/Accordion';
import '../elements/Sidebar.css'

import { useLocation } from 'react-router-dom';

import { Link } from "react-router-dom";

/**
 * Componente que actua como el menú lateral de la página
 * @returns 
 */
function Sidebar() {
    /**
     * Atributos utilizados para controlar si el menu se despliega o se oculta
     */
    const [show, setShow] = useState(true);
    /**
     *  Atributo que se usa para llevar una referencia de show
     */
    const showRef = useRef(show);
    /**
     * Metodo de función de flecha utilizado para cerrar el menú
     * @returns 
     */
    const handleClose = () => setShow(false);
    /**
    * Metodo de función de flecha utilizado para abrir el menú
    * @returns 
    */
    const handleShow = () => setShow(true);
    /**
     * Atributo utilizado para saber cual es la ruta actual
     */
    const location = useLocation();

    const token = localStorage.getItem('loggedIvuUser')

    /**
    * Rol del usuario
    */
    let role = -1

    if (token !== null) {
        const [header, payload, signature] = token.split('.');

        // Decodificar la carga útil (payload) que contiene la información del usuario
        const decodedPayload = JSON.parse(atob(payload));
        role = decodedPayload['role']
    }





    /**
     * Atributo utilizado para habilitar o deshabilitar el boton que permite cerrar el menú
     */
    const [isCloseButtonEnabled, setIsCloseButtonEnabled] = useState(true);

    /**
     * Metodo utilizado para mantener el menu desplegado y con el boton de cerrar inhabilitado si se encuentra en el dashboard del admin.
     * En caso contrario se habilita el boton de cerrar y se cierra el menu para tener mejor visibilidad
     */
    useEffect(() => {
        // Verifica la ruta actual y cierra el offcanvas si es necesario
        if (location.pathname !== "/dashboard/admin" && location.pathname !== "/") {
            handleClose();
            setIsCloseButtonEnabled(true);
        } else {
            if (window.innerWidth <= 768) {
                if (showRef.current) {
                    setShow(false);
                }
                setIsCloseButtonEnabled(true);
            } else {
                setIsCloseButtonEnabled(false);
            }
        }
    }, [location.pathname]);

    /**
     * Metodo utilizado para cerrar el menú cuando la pantalla es de 768 o menos, en caso contrario tiene la funcionalidad descrita en 
     * el anterior metodo
     */
    useEffect(() => {
        // Cierra el offcanvas cuando se redimensiona la página y se vuelve pequeña
        const handleResize = () => {
            if (window.innerWidth <= 800) {
                if (showRef.current) {
                    setShow(false);
                }
                setIsCloseButtonEnabled(true);
            } else {
                if (location.pathname !== "/dashboard/admin" && location.pathname !== "/") {
                    handleClose();
                    setIsCloseButtonEnabled(true);
                } else {
                    handleShow()
                    setIsCloseButtonEnabled(false);
                }
            }
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [location.pathname]);

    /**
     * Metodo utilizado para llevar la referencia del show
     */
    useEffect(() => {
        showRef.current = show;
    }, [show]);
    /**
     * Html del menu lateral
     */
    return (
        <>
            <a className="navbar-toggler btnToggler" onClick={handleShow}>
                <span className="navbar-toggler-icon"></span>
            </a>
            <Offcanvas backdrop={false} show={show} onHide={handleClose} >
                <Offcanvas.Header closeButton={isCloseButtonEnabled} >
                    <Offcanvas.Title>Menu</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body className="offcanvasB" >
                    <Nav defaultActiveKey="/dashboard/admin" className="flex-column">
                        <Accordion >
                            <Accordion.Item eventKey="0" >
                                <Nav.Link className='title' as={Link} to="/dashboard/admin" >Inicio</Nav.Link>
                            </Accordion.Item>
                            <Accordion.Item eventKey="1">
                                <Nav.Link className='title' as={Link} to="/admin/users">Clientes</Nav.Link>
                            </Accordion.Item>
                            <Accordion.Item eventKey="2">
                                <Nav.Link className='title' as={Link} to="/admin/usersTerminals">Clientes / Negocios / Terminales</Nav.Link>
                            </Accordion.Item>
                            <Accordion.Item eventKey="3">
                                <Accordion.Header >Reportes</Accordion.Header>
                                <Accordion.Body>
                                    <Nav.Link className='subtitle' as={Link} to="/admin/unreportedClients">Clientes sin reportar</Nav.Link>
                                    <Nav.Link className='subtitle' as={Link} to="/admin/reportActivity">Reporte de actividad</Nav.Link>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="4" >
                                <Accordion.Header >Recaudar</Accordion.Header>
                                <Accordion.Body>
                                    {Number(role) !== 3 ?
                                        <Nav.Link className='subtitle' as={Link} to="/admin/payBill">Entrar pago de factura</Nav.Link> :
                                        <Nav.Link className='subtitle' as={Link} to="/admin/payBill">Historial pago de factura</Nav.Link>
                                    }
                                    <Nav.Link className='subtitle' as={Link} to="/admin/noPayClients">Clientes falta de pago</Nav.Link>
                                    <Nav.Link className='subtitle' as={Link} to="/admin/payReport">Reporte de pagos</Nav.Link>
                                </Accordion.Body>
                            </Accordion.Item>
                            {Number(role) !== 3 ?
                                <Accordion.Item eventKey="5">
                                    <Nav.Link className='title' as={Link} to="/admin/adminUsers">Usuarios</Nav.Link>
                                </Accordion.Item> : null
                            }
                            <Accordion.Item eventKey="6">
                                <Nav.Link className='title' as={Link} to="/admin/resellers">Revendedores</Nav.Link>
                            </Accordion.Item>
                            <Accordion.Item eventKey="7">
                                <Nav.Link className='title' as={Link} to="/admin/servicesAndPrices">Precios y servicios</Nav.Link>
                            </Accordion.Item>
                        </Accordion>
                    </Nav>

                </Offcanvas.Body>
            </Offcanvas>
        </>
    );
}

export default Sidebar;