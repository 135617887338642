import React from "react";
import Navbar from "../componentes/Navbar";
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import ReactPaginate from 'react-paginate';
import axios from "axios";
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import Swal from "sweetalert2";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import { FontAwesomeIcon, } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "../../node_modules/@fortawesome/free-solid-svg-icons";

/**
 * Componente encargado de enseñar el historial de cambios realizado los usuarios super user
 */
class LogHistory extends React.Component {

    /**
     * Función constructora para inicializar el estado del componente.
     *
     * @param {object} props - El objeto de props que contiene las propiedades iniciales.
     * @return {void}
     */
    constructor(props) {
        super(props);
        this.state = {
            role: -1,
            token: "",
            searchText: "",
            currentPage: 0,
            perPage: 10, // Número de elementos por página
            pageCount: 0,
            loading: false,
            log: [],
            oneUser: false
        }
    }

    /**
   * Referencia a el campo que contiene la información que ingresa el usuario de busqueda
   */
    searchRef = React.createRef()

    /**
     * Método del ciclo de vida del componente que se ejecuta después de que el componente se monta.
     * Obtiene el token del almacenamiento local, decodifica la carga útil, establece el token y el rol en el estado,
     * y realiza una llamada a la API para obtener los datos de registro en función del parámetro idUser.
     *
     * @return {void}
     */
    componentDidMount() {
        const token = localStorage.getItem('loggedIvuUser')
        const [header, payload, signature] = token.split('.');

        // Decodificar la carga útil (payload) que contiene la información del usuario
        const decodedPayload = JSON.parse(atob(payload));

        const role = decodedPayload['role']
        this.setState({ token: token, role: role })

        const { idUser } = this.props.match.params;

        if (idUser !== undefined) {
            const config1 = {
                method: 'get',
                url: global.URLBack + `/dashBoard/superUser/log/filter?filtro=` + idUser,
                headers: {
                    'Authorization': 'Bearer ' + token
                },
                withCredentials: true
            }
            this.setState({ loading: true, oneUser: true })
            axios(config1).then(res => {
                this.setState({ loading: false })
                if (res) {
                    var pageC = Math.ceil(res.data.length / this.state.perPage);
                    this.setState({ log: res.data, pageCount: pageC })
                }
            }).catch((error) => {
                this.setState({ loading: false })
                Swal.fire({ title: "Ha ocurrido un error", text: "Por favor intentelo más tarde", icon: "error" })
                //localStorage.removeItem('loggedIvuUser')
                //localStorage.removeItem('role')
            })
        } else {
            const config = {
                method: 'get',
                url: global.URLBack + `/dashBoard/superUser/log`,
                headers: {
                    'Authorization': 'Bearer ' + token
                },
                withCredentials: true
            }
            this.setState({ loading: true, oneUser: false })

            axios(config).then(response => {
                this.setState({ loading: false })


                if (response.status === 200) {
                    var pageC = Math.ceil(response.data.length / this.state.perPage);
                    this.setState({ log: response.data, pageCount: pageC })
                }
            }).catch((error) => {
                this.setState({ loading: false })
                Swal.fire({
                    title: "Ha ocurrido un error",
                    icon: "error"
                })
                //localStorage.removeItem('loggedIvuUser')
                //localStorage.removeItem('role')
            })
        }

    }

    /**
    * Metodo utilizado para obtener el valor de la busqueda que ingresó el usuario a partir de la referencia creada
    * @param {*} event 
    */
    handleSearchChange = (event) => {
        this.setState({ searchText: this.searchRef.current.value });
    }

    /**
     * Metodo utilizado para manejar la paginación de la tabla
     * @param {*} param0 Pagina seleccionada
     */
    handlePageChange = ({ selected }) => {
        this.setState({
            currentPage: selected,
            selectedElement: null, // Reinicia el elemento seleccionado al cambiar de página
        });
    };

    /**
     * Metodo utilizado para formatear la fecha hh:mm dd/mm/yyy
     * @param {*} fecha 
     * @returns 
     */
    formatDate = (fecha) => {
        const date = new Date(fecha);

        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Se suma 1 porque los meses en JavaScript van de 0 a 11
        const year = date.getFullYear();

        const formattedDate = `${hours}:${minutes} ${day}/${month}/${year}`;

        return formattedDate;
    };

    /**
     * Renderiza el componente Navbar en función del estado actual.
     *
     * @return {JSX.Element} El componente Navbar renderizado.
     */
    renderNavbar() {
        // Puedes acceder al estado actualizado aquí y pasar el valor a Navbar
        const { role } = this.state;
        if (Number(role) !== -1) {
            return <Navbar SupUser={role} />;
        }

    }

    /**
     * Renderiza el componente y muestra el historial de cambios.
     */
    render() {
        // Aplicar el filtro
        const logHistory = this.state.log.filter(item =>
            String(item.userName).toLowerCase().includes(this.state.searchText.toLowerCase())
        );

        const { currentPage, perPage, log, oneUser } = this.state;
        const offset = currentPage * perPage;
        var pageC = this.state.pageCount
        var currentPageData;
        if (this.state.searchText !== "") {

            currentPageData = logHistory.slice(offset, offset + perPage);
            pageC = Math.ceil(logHistory.length / this.state.perPage)
        } else {
            currentPageData = log.slice(offset, offset + perPage);
        }

        return (
            <React.Fragment>
                {this.renderNavbar()}
                <div className='mainn'>
                    <div className="sonn1" style={{ height: "50px" }}>
                        <p className='titulo'><strong>Historial de Cambios</strong></p>
                    </div>
                    <div className="sonn">
                        {this.state.loading ?
                            <div className="spinner"></div>
                            :
                            <React.Fragment>
                                <Link to="/admin/adminUsers">
                                    <Button variant="outline-primary" className="mt-3">
                                        <FontAwesomeIcon icon={faArrowLeft} /> Atras</Button>
                                </Link>
                                {!oneUser ?
                                    <Row className="g-2 search-bar align-items-center" >
                                        <Col  >
                                            <p className="search-label"><strong>Buscar: </strong></p>
                                        </Col>
                                        <Col style={{ maxWidth: "500px" }}>
                                            <Form>
                                                <Form.Group className="mb-3" id="ControlInput1">
                                                    <Form.Label
                                                        className="mb-3"
                                                    >    </Form.Label>
                                                    <Form.Control type="text" placeholder="Ingrese su busqueda" ref={this.searchRef} onChange={this.handleSearchChange} />
                                                </Form.Group>
                                            </Form>

                                        </Col>
                                    </Row> : null
                                }

                                {currentPageData.length === 0 && this.state.searchText.length === 0 ?
                                    <Alert variant="warning" className="w-100 mt-3" >
                                        No se encuentran cambios registrados
                                    </Alert> :
                                    <React.Fragment>
                                        <Table className="table">

                                            <thead className="thead-dark">
                                                <tr>
                                                    <th scope="col">Nombre de usuario</th>
                                                    <th scope="col">Hora y fecha</th>
                                                    <th scope="col">Merchant ID</th>
                                                    <th scope="col">Cliente</th>
                                                    <th scope="col">Campo</th>
                                                    <th scope="col">Versión anterior</th>
                                                    <th scope="col">Versión modificada</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    currentPageData.map((logValue, i) => {
                                                        return (
                                                            <tr key={i}>
                                                                <td>{logValue.userName}</td>
                                                                <td>{this.formatDate(logValue.dateTime)}</td>
                                                                <td>{logValue.merchantId}</td>
                                                                <td>{logValue.clientName}</td>
                                                                <td>{logValue.field}</td>
                                                                <td>{logValue.oldValue}</td>
                                                                <td>{logValue.newValue}</td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </Table>
                                        <div className="d-flex justify-content-center">
                                            <ReactPaginate
                                                pageCount={pageC}
                                                onPageChange={this.handlePageChange}
                                                containerClassName="pagination"
                                                activeClassName="active"
                                                forcePage={this.state.currentPage}
                                            />
                                        </div>
                                    </React.Fragment>
                                }

                            </React.Fragment>
                        }
                    </div>
                </div>

            </React.Fragment >
        )
    }

}


export default withRouter(LogHistory);