import React from "react";
import Navbar from "../componentes/Navbar";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import { Link } from "react-router-dom";
import { FontAwesomeIcon, } from "@fortawesome/react-fontawesome";
import { faTachometerAlt, faArrowLeft } from "../../node_modules/@fortawesome/free-solid-svg-icons";
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import axios from "axios";
import ReactPaginate from 'react-paginate';
import Logo from "../img/logo.png";

/**
 * Componente encargado de enseñar el historial de transacciones de un usuario
 */
class UserPayHistory extends React.Component {

  state = {
    token: '',
    role: -1,
    loading: false,
    payHistory: [],
    perPage: 10, // Número de elementos por página
    totalPages: [],
    pageCount: 0,
    currentPage: 0
  }

  /**
* Metodo utilizado para manejar la paginación de la tabla
* @param {*} param0 Pagina seleccionada
*/
  handlePageChange = ({ selected }) => {
    const { perPage, payHistory } = this.state;
    const offset = selected * perPage;
    const currentPageData = payHistory.slice(offset, offset + perPage);

    this.setState({
      currentPage: selected,
      selectedElement: null, // Reinicia el elemento seleccionado al cambiar de página
    });
  };

  /**
   * Obtiene el token del usuario y la información del historial de pagos
   */
  componentDidMount() {
    const token = localStorage.getItem('loggedIvuUser')
    const [header, payload, signature] = token.split('.');

    // Decodificar la carga útil (payload) que contiene la información del usuario
    const decodedPayload = JSON.parse(atob(payload));

    const role = decodedPayload['role']
    const config = {
      method: 'get',
      url: global.URLBack + `/dashBoard/profile/business/payments`,
      headers: {
        'Authorization': 'Bearer ' + token
      },
      withCredentials: true
    }
    this.setState({ loading: true, role: role })
    axios(config).then(response => {
      this.setState({ loading: false })
      if (response !== undefined) {
        var pageC = Math.ceil(response.data.length / this.state.perPage);
        this.setState({
          pageCount: pageC,
          payHistory: response.data
        })
      }
    }).catch(err => {
      this.setState({ loading: false })
      localStorage.removeItem('loggedIvuUser')
      //localStorage.removeItem('role')
      this.props.history.push('/')
    })
  }

  /**
  * Metodo utilizado para retornar el html que se muestra al imprimir una factura
  * @param {*} payDetails objeto que contiene la información del cliente, el negocio y el pago realizado
  * @returns El html que se muestra en la vista de impresión
  */
  getPrintableContent = (payDetails) => {
    var expDate = ""
    if (payDetails.renovationDate.length !== 0) {
      // Crear un objeto Date a partir de la fecha actual
      const fecha = new Date(payDetails.renovationDate);

      // Restar un día a la fecha actual
      fecha.setDate(fecha.getDate() - 1);
      // Obtener la nueva fecha en formato "yyyy-MM-dd"
      expDate = fecha.toISOString().split('T')[0];
    }



    return `
        <div class="printable-content">
        <!-- Contenido que deseas imprimir -->
        <div className="print-only">
          <div className="invoice-container" style=" font-family: Arial, sans-serif;
          ">
            <div className="invoice-header" style=" display: flex;
            justify-content: space-between;
            align-items: center;
            padding-bottom: 10px;
            border-bottom: 1px solid #d3d3d3;
            margin-bottom: 10px;">
              <img src="${Logo}" alt="Logo" width="200px" height="60px" />
              <div className="invoice-date" style=" text-align: right;">
                <p style="font-size: large;"><strong>Fecha:</strong> ${payDetails.date}</p>
              </div>
            </div>
           
            <div className="customer-info" style=" 
            padding-left: 10px;
            background-color: #f2f2f2;
            border-radius: 5px;">
            ${payDetails.rejected === 1 ?
        `<h2 style="color:red"><strong>PAGO RECHAZADO</strong></h2> ` : ` `
      }
              <h3>Información del Cliente</h3>
              <p><strong>Nombre:</strong>${payDetails.username + " " + payDetails.lastname + " " + payDetails.secondlastname}</p>
              <p><strong>Nombre de Negocio:</strong>${payDetails.bussinessname}</p>
              <p><strong>Número Mercantil:</strong>${payDetails.merchantID} </p>
              <p><strong>Email:</strong> ${payDetails.email}</p>
              <p><strong>Telefono:</strong> ${payDetails.phone}</p>
              <p><strong>Telefono Movil:</strong> ${payDetails.mobilephone}</p>
            </div>
            <div className="address-info"  style="  
            padding-left: 10px;
            background-color: #f2f2f2;
            border-radius: 5px;">
              <h3>Dirección</h3>
              <p><strong>Dirección :</strong> ${payDetails.address}</p>
              <p><strong>Dirección 2:</strong> ${payDetails.address2}</p>
              <p><strong>Ciudad/Estado/Código Zip:</strong>${payDetails.town_name + "/" + payDetails.state + "/" + payDetails.zipcode}</p>
            </div>
            <div className="service-info"  style="  
            padding-left: 10px;
            background-color: #f2f2f2;
            border-radius: 5px;">
              <h3>Servicio</h3>
              <p><strong>Servicio Seleccionado:</strong> ${payDetails.service}</p>
              <p><strong>Terminales Fiscales:</strong> ${payDetails.fiscalterminals}</p>
            </div>
            <div className="software-info"  style="  
            padding-left: 10px;
            background-color: #f2f2f2;
            border-radius: 5px;">
              <h3>Software</h3>
              <p><strong>Software:</strong> ${payDetails.software}</p>
              <p><strong>Fecha de Expiración:</strong> ${expDate}</p>
            </div>
            <div className="payment-info"  style="  
            padding-left: 10px;
            padding-bottom: 10px;
            background-color: #f2f2f2;
            border-radius: 5px;">
              <h3>Información de Pago</h3>
              <p><strong>Método de Pago:</strong>${payDetails.paymentMethod} </p>  
              <p><strong>Número de referencia:</strong> ${payDetails.referenceNumber}</p>             
            </div>
            <div className="invoice-total" style=" 
            text-align: center;
            padding-top: 20px;
            border-top: 1px solid #d3d3d3;">
              <h2><strong>Total Pagado</strong></h2>
              ${payDetails.rejected === 1 ?
        `<h2 style="color:black"><del><strong>$ ${payDetails.totalAmount}</strong></del></h2>` :
        `<h2 style="color:black"><strong>$ ${payDetails.totalAmount}</strong></h2>`
      }
            </div>
          </div>
        </div>
      </div>
      
        `;
  };

  /**
  * Metodo utilizado para imprimir el reporte
  */
  handlePrint = (payDetails) => {

    const style = `
                
        /* Estilos para ocultar en la vista normal */
        .printable-content .print-only {
          display: none;
        }
        
        /* Estilos específicos para la vista de impresión */
        @media print {
          .printable-content .print-only {
            display: block;
          }
        
          .invoice-container {
            font-family: Arial, sans-serif;
            width: 80%;
            margin: 0 auto;
            padding: 20px;
            background-color: #ffffff;
            border: 1px solid #d3d3d3;
            border-radius: 10px;
          }
        
          .invoice-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-bottom: 10px;
            border-bottom: 1px solid #d3d3d3;
            margin-bottom: 10px;
          }
        
          .invoice-date {
            text-align: right;
          }
        
          .invoice-details {
            margin-top: 20px;
          }
        
          .invoice-total {
            margin-top: 40px;
            text-align: center;
            padding-top: 20px;
            border-top: 1px solid #d3d3d3;
          }
        
          .customer-info,
          .address-info,
          .service-info,
          .software-info,
          .payment-info {
            padding: 10px;
            background-color: #f2f2f2;
            border-radius: 5px;
          }
        
          h1,
          h2,
          h3 {
            color: #007bff;
          }
        
          p {
            margin: 8px 0;
            font-size: larger;
          }
        
          body {
            font-family: Arial, sans-serif;
            font-size: 14px;
          }
        }
        
        

      `;

    const iframe = document.createElement('iframe');
    iframe.style.display = 'none';
    document.body.appendChild(iframe);

    const content = `
          <html>
             <head>
              <style>${style}</style>
            </head>
            <body>
              ${this.getPrintableContent(payDetails)}
            </body>
          </html>
        `;

    const doc = iframe.contentWindow.document;
    doc.open();
    doc.write(content);
    doc.close();

    // Iniciar la impresión
    iframe.contentWindow.print();
  };

  /**
   * Metodo de renderizado
   * @returns html que contiene el botón que despliega la tabla del historial de pagos
   */
  render() {
    const { payHistory, perPage, currentPage } = this.state
    const offset = currentPage * perPage;
    var currentPageData = []
    if (payHistory.length > 0) {
      currentPageData = payHistory.slice(offset, offset + perPage);
    } else {
      currentPageData = payHistory
    }
    return (<React.Fragment>
      <Navbar SupUser={this.state.role} />

      <div className='mainn'>
        <div className="struc">
          <section className="pt-3">

            <Breadcrumbs
              className="breadcrumbs"
              separator=">"
              aria-label="breadcrumb"
            >
              <Link className="link-rp" color="inherit" to="/dashboard">
                <FontAwesomeIcon className="icon" icon={faTachometerAlt} />
                Panel
              </Link>
              <Link
                className="link-rp"
                color="inherit"
                to="/dashboard/payHistory"
              >
                Historial de pagos
              </Link>
            </Breadcrumbs>

          </section>

        </div>
        {this.state.loading ?
          <div className="sonn">
            <div className="spinner"></div>
          </div>

          :
          <div className="sonn " >
            <Link to="/dashboard">
              <Button variant="outline-primary" className="mt-3">
                <FontAwesomeIcon icon={faArrowLeft} /> Regresar a menú principal</Button>
            </Link>
            <h2 className="mt-5">Historial De Pagos</h2>
            <Table responsive className="table">
              <thead >

                <tr>
                  <th scope="col">Negocio</th>
                  <th scope="col">Numero de Registro</th>
                  <th scope="col">Total de Factura</th>
                  <th scope="col">Fecha de Factura</th>
                  <th scope="col">Proxima Renovación</th>
                  <th scope="col">Metodo de Pago</th>
                  <th scope="col"></th>
                </tr>

              </thead>
              <tbody>
                {currentPageData.map((bussines, index) => {
                  return (
                    <tr key={index}>
                      <td>{bussines.bussinessname}</td>
                      <td>{bussines.merchantID}</td>
                      <td>$ {bussines.totalAmount}</td>
                      <td>{bussines.date}</td>
                      <td>{bussines.renovationDate}</td>
                      <td>{bussines.paymentMethod}</td>
                      <td>
                        <Button variant="primary" onClick={() => this.handlePrint(bussines)}>Imprimir</Button>
                      </td>
                    </tr>
                  )
                })
                }
              </tbody>
            </Table>
            <ReactPaginate
              pageCount={this.state.pageCount}
              onPageChange={this.handlePageChange}
              containerClassName="pagination"
              activeClassName="active"
              forcePage={this.state.currentPage} // Establece la página actual activa en el componente de paginación
            />
          </div>
        }
      </div>

    </React.Fragment>)
  }

}

export default UserPayHistory;