import React from 'react';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const ComparisonBarChart = () => {
  const data1 = {
    labels: ['Activaciones'], // Etiqueta para el gráfico
    datasets: [
      {
        label: 'mes actual',
        data: [1], // Datos para el mes actual
        backgroundColor: 'rgba(255, 99, 132, 0.6)', // Color del mes actual
        borderColor: 'rgba(255, 99, 132, 1)', // Borde del mes actual
        borderWidth: 1,
      },
      {
        label: 'mes anterior',
        data: [0], // Datos para el mes anterior
        backgroundColor: 'rgba(54, 162, 235, 0.6)', // Color del mes anterior
        borderColor: 'rgba(54, 162, 235, 1)', // Borde del mes anterior
        borderWidth: 1,
      },
    ],
  };

  const data2 = {
    labels: ['Registros'], // Etiqueta para el gráfico
    datasets: [
      {
        label: 'mes actual',
        data: [0], // Datos para el mes actual
        backgroundColor: 'rgba(255, 99, 132, 0.6)', // Color del mes actual
        borderColor: 'rgba(255, 99, 132, 1)', // Borde del mes actual
        borderWidth: 1,
      },
      {
        label: 'mes anterior',
        data: [0], // Datos para el mes anterior
        backgroundColor: 'rgba(54, 162, 235, 0.6)', // Color del mes anterior
        borderColor: 'rgba(54, 162, 235, 1)', // Borde del mes anterior
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'bottom',
      },
      title: {
        display: true,
        text: '', // Título del gráfico
      },
    },
    scales: {
      y: {
        beginAtZero: true, // Asegura que el eje Y empiece en 0
      },
    },
  };

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
    <div style={{ width: '600px', height: '300px', marginRight: '20px' }}> {/* Margen derecho */}
        <h3 style={{ textAlign: 'center' }}>Activaciones</h3>
        <Bar data={data1} options={options} />
    </div>
    <div style={{ width: '600px', height: '300px', marginLeft: '20px' }}> {/* Margen izquierdo */}
        <h3 style={{ textAlign: 'center' }}>Registros</h3>
        <Bar data={data2} options={options} />
    </div>
    </div>


  );
};

export default ComparisonBarChart;
