import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import React from "react";
import Button from 'react-bootstrap/Button';
import '../elements/UserDashboard.css'
import {
    Input,
    Selectll,
    Label,
    MsjFail,
    SelectPM
} from "../elements/Forms";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faExclamationTriangle
} from "@fortawesome/free-solid-svg-icons";
import QR from "../img/athm_qrcode_sticker.png"
import InputMask from 'react-input-mask';
import axios from 'axios';
import Swal from 'sweetalert2';

/**
 * Componente que representa el modal de realizar pago o reactivar negocio
 */
class PayBillMerchant extends React.Component {
    constructor(props) {
        super(props);
        const fechaActual = new Date();
        this.state = {
            showPayModal: false,
            validated: false,
            merchantID: props.merchant,
            validForm: true,
            buttonName: props.buttonName,
            paymethod: "CREDIT-CARD",
            totalAmount: 0,
            additionalTerminal: 99,
            fiscalterminals: 0,
            serviceValue: 99,
            recurringPayments: false,
            zipcode: "",
            securitycode: 0,
            typeCard: [
                {
                    "id": 1,
                    "value": "Visa"
                },
                {
                    "id": 2,
                    "value": "Master Card"
                }
            ],
            error: "",
            expressiones: {

                nameCard: /^[a-zA-ZÀ-ÿ\s]{10,40}$/,
                nameCount: /^.+/,
                securityCode: /^\d{3}$/,

                state: /^[a-zA-ZÀ-ÿ\s]{3,10}$/,
                routeNumber: /^\d{4,9}$/,
                accountNumber: /^\d{4,16}$/,
            },
            month: fechaActual.getMonth() + 1,
            year: fechaActual.getFullYear(),
            loading: false,
            automaticPay: false,
            onePay: true,
            expDateM: "",
            expDateY: "",
            accountNameBank: "",
            accountNumberBank: "",
            routeNumberBank: "",
            cardtype: "",
            nameoncard: "",
            id_cv: 0,
            service: "",
            void: "",
            loading2: false,
            idtown: 0,
            bussinessname: props.bussinessname,
            rejected: [],
            totalComplete: 0,
            paymentMethods: []
        };
    }

    /**
     * Se obtiene el token del usuario
     */
    componentDidMount() {
        /*    const additionalTer = this.props.location.state.additional;
           this.setState({
               additionalTerminal: additionalTer
           }) */
        const token = localStorage.getItem('loggedIvuUser')
        this.setState({
            token: token
        })

        

    }

    /**
     * Metodo utilizado para almacenar el archivo que se está cargando en un atributo
     * @param {*} e evento input file
     */
    handleFiles = (e) => {
        this.setState({
            [e.target.name]: e.target.files[0]
        })
    }

    /**
     * Metodo utilizado para desplegar u ocultar el modal y traer la información detallada del servicio para ese negocio
     * @param {*} value Si es True entonces se despliega el modal, en caso contrario se oculta
     */
    payModal = (value) => {
        this.setState({
            loading: true
        })
        if (value) {
            let config = {
                method: 'get',
                url: global.URLBack + `/dashBoard/business/service/` + this.state.merchantID,
                headers: {
                    'Authorization': 'Bearer ' + this.state.token
                },
                withCredentials: true
            }
            axios(config).then(res => {
                const config2 = {
                    method: 'get',
                    url: global.URLBack + `/dashBoard/superUser/paymentMethods`,
                }

                axios(config2).then(response2 => {
             
                    this.setState({ paymentMethods: response2.data })
                }).catch(error => {
                    this.setState({ loading: false })
                    Swal.fire({
                        title: "Ha ocurrido un error, por favor intentelo más tarde",
                        icon: "error"
                    })
                    this.setState({
                        showPayModal: false
                    })
                })

                this.setState({ loading: false })
                if (res !== undefined) {
                 
                    let terminalesAdicionales = res.data.cantidadTerminales - 1
                    if (terminalesAdicionales < 0) {
                        terminalesAdicionales = 0
                    }
                    let rejected = []
                    res.data.historialPagos.forEach(element => {
                        if (element.rejected === 1 && element.paid === 0) {
                            rejected.push(element)
                        }
                    });

                    let total = 0
                    let additionalTerminals = 0
                    
                    if(res.data.cantidadTerminales>=2 && res.data.cantidadTerminales<=5){
                        additionalTerminals=res.data.terminals2to5
                    }else if(res.data.cantidadTerminales>=6 && res.data.cantidadTerminales<=9){
                        additionalTerminals=res.data.terminals6to9
                    }else if(res.data.cantidadTerminales>=10){
                        additionalTerminals=res.data.terminals10
                    } 

                    if (rejected.length >= 1) {
                        for (let i = 0; i < rejected.length; i++) {
                            total = total + rejected[i].totalAmount + 25
                        }
                    } else {                       
                        
                        total = res.data.serviceValue + (additionalTerminals * terminalesAdicionales)
                    }

                    this.setState({
                        zipcode: res.data.zipcode,
                        service: res.data.service,
                        additionalTerminal: additionalTerminals,
                        fiscalterminals: res.data.cantidadTerminales,
                        serviceValue: res.data.serviceValue,
                        totalAmount: total,
                        idtown: res.data.idtown,
                        rejected: rejected
                    })
                }
            }).catch(err => {
                this.setState({ loading: false })
                Swal.fire({
                    title: "Ha ocurrido un error, por favor intentelo más tarde",
                    icon: "error"
                })
                this.setState({
                    showPayModal: false
                })
            })
            
        } else {
            this.setState({
                paymethod: "CREDIT-CARD"
            })
        }
        this.setState({
            showPayModal: value
        })
    }

    /**
     * Metodo utilizado para validar el formulario de pago
     * @returns True si los valores de los campos son válidos, False en caso contrario
     */
    validation() {
        if (this.state.paymethod === "CREDIT-CARD") {
            if (
                this.state.nameoncard === "" ||
                !this.state.expressiones.nameCard.test(this.state.nameoncard)
            ) {
                this.setState({
                    validForm: false,
                    error: "El nombre en la tarjeta debe tener de 10 a 20 letras",
                });
                return false;
            } else {
                this.setState({
                    validForm: true,
                    error: "",
                });
            }
            if (
                this.state.creditcarnumber === undefined || this.state.creditcarnumber === ""
            ) {
                this.setState({
                    validForm: false,
                    error: "Debe ingresar un número de tarjeta válido.",
                });

                return false;
            } else {
                this.setState({
                    validForm: true,
                    error: "",
                });
            }
            if (
                this.state.securitycode === "" ||
                !this.state.expressiones.securityCode.test(this.state.securitycode)
            ) {
                this.setState({
                    validForm: false,
                    error:
                        "El código de seguridad solo puede contener un máximo de 3 dígitos.",
                });
                return false;
            } else {
                this.setState({
                    validForm: true,
                    error: "",
                });
            }

            if (
                this.state.expDateM === undefined || this.state.expDateM === "" || parseInt(this.state.month) > parseInt(this.state.expDateM) && parseInt(this.state.year) >= parseInt(this.state.expDateY) || this.state.expDateM > '12'
            ) {
                this.setState({
                    validForm: false,
                    error: "Debe ingresar una fecha de expiración válida.",
                });

                return false;
            } else {
                this.setState({
                    validForm: true,
                    error: "",
                });
            }
            if (
                this.state.expDateY === undefined || this.state.expDateY === "" || parseInt(this.state.year) > parseInt(this.state.expDateY) || parseInt(this.state.expDateY) > (parseInt(this.state.year) + 46)
            ) {

                this.setState({
                    validForm: false,
                    error: "Debe ingresar una fecha de expiración válida.",
                });

                return false;
            } else {
                this.setState({
                    validForm: true,
                    error: "",
                });
            }
        }
        if (this.state.paymethod === "BANK-ACCOUNT") {
            if (
                this.state.accountNameBank === undefined || this.state.accountNameBank === ""
            ) {
                this.setState({
                    validForm: false,
                    error: "Debe ingresar un nombre de cuenta válido.",
                });

                return false;
            } else {
                this.setState({
                    validForm: true,
                    error: "",
                });
            }
            if (
                this.state.accountNumberBank === undefined || this.state.accountNumberBank === ""
            ) {
                this.setState({
                    validForm: false,
                    error: "Debe ingresar un número de cuenta válido.",
                });

                return false;
            } else {
                this.setState({
                    validForm: true,
                    error: "",
                });
            }
            if (
                this.state.routeNumberBank === undefined || this.state.routeNumberBank === ""
            ) {
                this.setState({
                    validForm: false,
                    error: "Debe ingresar un número de ruta válido.",
                });

                return false;
            } else {
                this.setState({
                    validForm: true,
                    error: "",
                });
            }
            if (
                this.state.void === undefined || this.state.void === ""
            ) {
                this.setState({
                    validForm: false,
                    error: "Debe ingresar la foto del cheque void.",
                });

                return false;
            } else {
                this.setState({
                    validForm: true,
                    error: "",
                });
            }
        }
        return true
    }

    /**
     * Metodo que actualiza y valida el valor de los atributos del estado de acuerdo a lo ingresado en el formulario
     * @param {*} e evento input
     */
    handleChange = (e) => {

        let value = e.target.type === "checkbox" ? e.target.checked : e.target.value


        if (e.target.name === "automaticPay" || e.target.name === "onePay") {
            // Si se marca el checkbox "Pago por saldo actual"
            if (e.target.name === "onePay" && value) {
                this.setState({
                    onePay: true,
                    automaticPay: false, // Desmarca el otro checkbox
                    recurringPayments: false
                });
            }
            // Si se marca el checkbox "Pago por saldo actual y pagos siguientes automatizados"
            else if (e.target.name === "automaticPay" && value) {
                this.setState({
                    onePay: false, // Desmarca el otro checkbox
                    automaticPay: true,
                    recurringPayments: true
                });

            }
            // Si se desmarca uno de los checkboxes
            else if (e.target.name === "onePay" && !value) {
                // Asegurarse de que al menos uno esté marcado
                this.setState({
                    onePay: false,
                    automaticPay: true,
                    recurringPayments: true
                });
            } else if (e.target.name === "automaticPay" && !value) {
                // Asegurarse de que al menos uno esté marcado
                this.setState({
                    onePay: true,
                    automaticPay: false,
                    recurringPayments: false
                });
            }
        } else {
            this.setState({
                [e.target.name]: value,
            });
        }


        if (e.target.name === "paymethod") {
            if (e.target.value === "CREDIT-CARD" || e.target.value === "ATHMOVIL") {
                this.setState({
                    sc: "",
                    void: "",
                    routeNumberBank: "",
                    accountNumberBank: ""
                })
            }
            if (e.target.value !== "CREDIT-CARD") {
                this.setState({
                    cardtype: "",
                    creditcarnumber: "",
                    expDateM: "",
                    expDateY: "",
                    nameoncard: "",
                    cardtype: "Visa",
                    securitycode: ""
                })
            }
        }
        if (e.target.name === 'creditcarnumber') {

            switch (e.target.value.substring(0, 1)) {
                case '5':
                    this.setState({
                        cardtype: 'Master Card',
                        creditcarnumber: e.target.value

                    })
                    break;
                case '4':
                    this.setState({
                        cardtype: 'Visa',
                        creditcarnumber: e.target.value
                    })
                    break;
                default:
            }
        }
    };

    /**
     * Metodo utilizado para realizar el pago o reactivación de un negocio
     * @param {*} e evento submit
     */
    pay = (e) => {
        e.preventDefault()
        if (this.validation()) {
            let terminals = this.state.fiscalterminals
            if (terminals === 0) {
                terminals = 1
            }
            //Probar
            let rejectedPaymentsList = []
            rejectedPaymentsList = this.state.rejected.map(({ invoiceNumber, totalAmount }) => ({ invoiceNumber, totalAmount }));
            let paymentInfo = {
                merchantnumber: this.state.merchantID,
                fiscalterminals: terminals,
                paymethod: this.state.paymethod,
                nameoncard: this.state.nameoncard,
                creditcarnumber: this.state.creditcarnumber,
                cardtype: this.state.paymethod === "CREDIT-CARD" ? this.state.cardtype : "",
                securitycode: this.state.securitycode,
                recurringPayments: this.state.recurringPayments,
                amount: this.state.totalAmount,
                expDateM: this.state.expDateM,
                expDateY: this.state.expDateY,
                accountNameBank: this.state.accountNameBank,
                accountNumberBank: this.state.accountNumberBank,
                routeNumberBank: this.state.routeNumberBank,
                service: this.state.service,
                id_cv: this.state.id_cv,
                zipcode: this.state.zipcode,
                idtown: this.state.idtown,
                serviceValue: this.state.serviceValue,
                bussinessname: this.state.bussinessname,
                rejectedPayments: rejectedPaymentsList,
                aditionalTerminal:this.state.additionalTerminal
            }
            if (this.state.paymethod === "BANK-ACCOUNT") {
                const formDataCV = new FormData();

                formDataCV.append("CV", this.state.void);

                const ChequeVoidConfig = {
                    method: 'post',
                    url:
                        global.URLBack +
                        `/dashBoard/business/saveFormCV/`,
                    headers: {
                        "Content-Type": "multipart/form-data"
                    },
                    data: formDataCV
                }
                this.setState({ loading2: true })
                axios(ChequeVoidConfig).then(res => {
                    if (res) {
                        paymentInfo.id_cv = res.data.id_cv
                        this.setState({
                            id_cv: res.data.id_cv
                        }, () => {
                            let config = {
                                method: 'post',
                                url: global.URLBack + `/dashBoard/business/payment`,
                                headers: {
                                    'Authorization': 'Bearer ' + this.state.token
                                },
                                withCredentials: true,
                                data: paymentInfo
                            }
                            axios(config).then(res => {
                                this.setState({ loading2: false })
                                if (res) {
                                    Swal.fire({
                                        title: res.data.msg,
                                        text: "En caso de haber realizado el pago por cuenta bancaria o Ath Movil debe esperar a que se realice la verificación de su pago",
                                        icon: "success"
                                    }).then((result) => {
                                        if (result.isConfirmed) {
                                            this.payModal(false)
                                            this.props.updateDates(true)
                                        }
                                    })
                                }
                            }).catch(error => {
                                this.setState({ loading2: false })
                                Swal.fire({
                                    title: "Ocurrió un error al intentar realizar el pago, por favor intentelo más tarde",
                                    icon: "error"
                                }).then((response) => {
                                    if (response.isConfirmed) {
                                        this.payModal(false)
                                        this.props.updateDates(false)
                                    }
                                })
                            })
                        })
                    }
                }).catch(err => {
                    this.setState({ loading2: false })
                    Swal.fire({
                        title: "Ocurrió un error al subir el cheque void",
                        text: "Por favor intentelo más tarde",
                        icon: "error"
                    })
                })
            } else {
                let config = {
                    method: 'post',
                    url: global.URLBack + `/dashBoard/business/payment`,
                    headers: {
                        'Authorization': 'Bearer ' + this.state.token
                    },
                    withCredentials: true,
                    data: paymentInfo
                }
                this.setState({ loading2: true })
                axios(config).then(res => {
                    this.setState({ loading2: false })
                    if (res) {
                        //

                        Swal.fire({
                            title: res.data.msg,
                            text: "En caso de haber realizado el pago por cuenta bancaria o Ath Movil debe esperar a que se realice la verificación de su pago",
                            icon: "success"
                        }).then((result) => {
                            if (result.isConfirmed) {
                                this.payModal(false)
                                this.props.updateDates(true)
                            }
                        })
                    }
                }).catch(error => {
                    this.setState({ loading2: false })
                    Swal.fire({
                        title: "Ocurrió un error al intentar realizar el pago, por favor intentelo más tarde",
                        icon: "error"
                    }).then((response) => {
                        if (response.isConfirmed) {
                            this.payModal(false)
                            this.props.updateDates(false)
                        }
                    })
                })
            }


        }
    }


    /**
     * Metodo de renderizado
     * @returns html que contiene el botón que despliega el modal y el modal
     */
    render() {
        return (
            <React.Fragment>
                {this.state.buttonName === 0 ?
                    <Button className="btnPago" onClick={() => this.payModal(true)} ><strong>HACER PAGO</strong></Button> :
                    <Button className="btnReactivar" onClick={() => this.payModal(true)}><strong>REACTIVAR</strong></Button>
                }

                <Modal show={this.state.showPayModal} onHide={() => this.payModal(false)} dialogClassName="my-modal">
                    {this.state.validForm === false ? <MsjFail id="error" >
                        <p className="error-message" >
                            <FontAwesomeIcon icon={faExclamationTriangle} />
                            <b>{this.state.error}</b>
                        </p>
                    </MsjFail> : null}
                    {this.state.loading ?
                        <div className="spinner"></div>
                        :
                        <Form noValidate validated={this.state.validated} id="formPay" >
                            <Modal.Header closeButton>
                                <Modal.Title>Hacer Pago</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                {this.state.loading2 &&
                                    <div className="overlay">
                                        <div className="spinner2"></div>
                                    </div>
                                }
                                <div className='row'>
                                    <div className='col-lg-8 col-md-7 col-sm-6'>
                                        <Label >
                                            Metodo de pago
                                            <SelectPM onChange={this.handleChange} name="paymethod" value={this.state.paymethod}>
                                                {this.state.paymentMethods.filter(method => method.status === true).map((method, index) => (
                                                    <option key={index} value={method.code}>{method.name}</option>
                                                ))
                                                }
                                            </SelectPM>

                                            {this.state.paymethod === "ATHMOVIL" ? (
                                                <div className='row mt-5'>

                                                    <h5 className='mt-1'>1. Verifique a la derecha el saldo de la cuenta</h5>
                                                    <h5 className='mt-1'>2. Buscanos como negocio utilizando la información de ATHmovil</h5>
                                                    <h5 className='mt-1'>3. En la descripción por favor indicar "<strong>Nombre de Negocio</strong>"</h5>
                                                    <h5 className='mt-1'>4. Luego presione el botón de "Hacer Pago"</h5>
                                                    <h5 className='mt-1'>5. El pago se estará verificando y despúes aplicando a su cuenta</h5>


                                                </div>

                                            ) : null}

                                            {this.state.paymethod === "CREDIT-CARD" ? (
                                                <div id="form-group-credit-card">
                                                    <Label>
                                                        Nombre en la tarjeta *
                                                        <Input
                                                            onChange={this.handleChange}
                                                            name="nameoncard"
                                                            type="text"
                                                            placeholder="Nombre en la tarjeta"
                                                            required

                                                        />
                                                    </Label>
                                                    <Label>
                                                        Número Tarjeta Crédito/ Tipo de tarjeta *
                                                        <div className="DivForm">
                                                            <InputMask className="inputCcNumber"
                                                                onChange={this.handleChange}
                                                                name="creditcarnumber"
                                                                type="text"
                                                                placeholder="Número"
                                                                mask="9999-9999-9999-9999"
                                                            />

                                                            <Selectll onChange={this.handleChange} name="cardtype" value={this.state.cardtype} disabled>
                                                                {this.state.typeCard.map(s => (
                                                                    <option key={s.id} value={s.value}>
                                                                        {s.value}
                                                                    </option>
                                                                ))}
                                                            </Selectll>
                                                        </div>
                                                    </Label>
                                                    <Label>
                                                        Código de seguridad CVV2 *
                                                        <InputMask className="inputMask"
                                                            onChange={this.handleChange}
                                                            name="securitycode"
                                                            type="text"
                                                            mask="999"
                                                            placeholder="Código de seguridad"
                                                        />
                                                    </Label>
                                                    <Label>
                                                        {" "}
                                                        Fecha de Expiración- Mes/Año *
                                                        <div>
                                                            <InputMask
                                                                onChange={this.handleChange}
                                                                name="expDateM"
                                                                type="text"
                                                                min="01"
                                                                max="12"
                                                                mask="99"
                                                                placeholder="MM"
                                                            /> / <InputMask
                                                                onChange={this.handleChange}
                                                                name="expDateY"
                                                                type="text"
                                                                placeholder="YYYY"
                                                                mask="9999"
                                                            />

                                                        </div>
                                                    </Label>{" "}
                                                </div>
                                            ) : null}
                                            {this.state.paymethod === "BANK-ACCOUNT" ? (
                                                <div id="form-group-debitcard">
                                                    <Label>
                                                        Nombre de cuenta *
                                                        <Input
                                                            onChange={this.handleChange}
                                                            name="accountNameBank"
                                                            type="text"
                                                            placeholder="Nombre de cuenta"
                                                        />
                                                    </Label>
                                                    <Label>
                                                        Número de cuenta *
                                                        <InputMask className="inputMask"
                                                            onChange={this.handleChange}
                                                            name="accountNumberBank"
                                                            type="text"
                                                            placeholder="Número de cuenta"
                                                            mask="9999999999999999"
                                                        />
                                                    </Label>
                                                    <Label>
                                                        Número de ruta *
                                                        <InputMask className="inputMask"
                                                            onChange={this.handleChange}
                                                            name="routeNumberBank"
                                                            type="text"
                                                            placeholder="Número de ruta"
                                                            mask="999999999"
                                                        />
                                                    </Label>
                                                </div>
                                            ) : null}

                                            {this.state.paymethod === "BANK-ACCOUNT" ?
                                                <p className='mt-5'>*Pagos podrían tardar de tres a 6 días laborables para procesar, una vez procesado
                                                    y validado el pago se aplicará a su cuenta.</p> : null

                                            }


                                            <p className='mt-5'>*Recuerde que es su responsabilidad hacer el pago de renovación de servicio, para asistirle
                                                con esto se estarán enviando tres correos electrónicos de aviso, 30 días antes, al vencer y 15 días despúes de vencer.
                                                Es responsabilidad del cliente pagar a tiempo para evitar interrupción en el servicio.</p>

                                        </Label>
                                    </div>
                                    <div className='col-lg-4  col-md-5 col-sm-6'>

                                        {/*  <div style={{ marginLeft: "2%" }}>
                                            <p><strong>ANUALIDAD + 1 TERMINAL: ${this.state.serviceValue} </strong> </p>
                                            <p><strong>TERMINALES ADICIONALES: {this.state.fiscalterminals > 0 ? this.state.fiscalterminals - 1 : this.state.fiscalterminals} x ${this.state.additionalTerminal}</strong></p>
                                            <hr className="solid2"></hr>
                                            <p style={{ marginLeft: "80px" }}><strong>TOTAL DE PAGO: $ {this.state.totalAmount}</strong></p>

                                        </div> */}

                                        {this.state.rejected.length <= 0 ?
                                            <div style={{ marginLeft: "2%" }}>
                                                <p><strong>ANUALIDAD + 1 TERMINAL: ${this.state.serviceValue} </strong> </p>
                                                <p><strong>TERMINALES ADICIONALES: {this.state.fiscalterminals > 0 ? this.state.fiscalterminals - 1 : this.state.fiscalterminals} x ${this.state.additionalTerminal}</strong></p>
                                                <hr className="solid2"></hr>
                                                <p style={{ marginLeft: "80px" }}><strong>TOTAL DE PAGO: $ {this.state.totalAmount}</strong></p>

                                            </div>
                                            :
                                            <React.Fragment>
                                                {this.state.rejected.map((rej, index) => (
                                                    <div className="row mt-3" key={index}>
                                                        <p className="billText"><strong>Valor Factura Anterior # {rej.invoiceNumber}: $ {rej.totalAmount}</strong></p>
                                                        <p className="billText2"><strong>Pago Rechazado: $ 25</strong></p>
                                                        <hr className="solid w-100"></hr>
                                                        <p className="billText2"><strong>Total de pago: $ {rej.totalAmount + 25}</strong></p>

                                                    </div>
                                                ))}
                                                {this.state.rejected.length > 1 ?
                                                    <React.Fragment>
                                                        <hr className="solid w-100"></hr>
                                                        <p className="billText2"><strong>Total completo a pagar: $ {this.state.totalAmount}</strong></p>
                                                    </React.Fragment> : null
                                                }

                                            </React.Fragment>


                                        }
                                        {this.state.paymethod === "ATHMOVIL" ? (
                                            <div id="form-group-ath-movil">
                                                <label className="QR_text">
                                                    Escanea el código QR para proceder con tu pago.
                                                </label>
                                                <label>
                                                    <img style={{ maxWidth: "100%" }} src={QR} alt='QR de Ath Movil' />
                                                </label>
                                            </div>) : null
                                        }
                                        {this.state.paymethod !== "ATHMOVIL" ?
                                            <div>

                                                <Form.Check
                                                    style={{ marginLeft: "10px" }}
                                                    type="checkbox"
                                                    id="one-check"
                                                    name="onePay"
                                                    label={`Pago por saldo actual ($ ${this.state.totalAmount}.00)`}
                                                    checked={this.state.onePay}

                                                    onChange={this.handleChange}
                                                />
                                                <br></br>
                                                <Form.Check
                                                    style={{ marginLeft: "10px" }}
                                                    type="checkbox"
                                                    id="auto-check"
                                                    name="automaticPay"
                                                    checked={this.state.automaticPay}

                                                    label={`Pago por saldo actual y pagos siguientes automatizados ($ ${this.state.totalAmount}.00)`}
                                                    onChange={this.handleChange}
                                                />
                                                {this.state.paymethod === "BANK-ACCOUNT" ?
                                                    <Label className="mt-3">
                                                        *Necesita Subir Una Foto De Un Cheque Void Para Validar La Cuenta
                                                        <div className="div-form-bi">

                                                            <input
                                                                className='mt-3'
                                                                style={{ backgroundColor: 'lightblue', borderRadius: '10px', padding: '10px' }}
                                                                type="file"
                                                                name="void"
                                                                onChange={this.handleFiles}
                                                                required
                                                            />

                                                        </div>
                                                    </Label> : null
                                                }

                                            </div> : null
                                        }
                                    </div>
                                </div>


                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={() => this.payModal(false)}>
                                    Cancelar
                                </Button>
                                <Button variant="primary" onClick={this.pay}>
                                    Hacer Pago
                                </Button>
                            </Modal.Footer>
                        </Form>
                    }
                </Modal>
            </React.Fragment>
        )
    }
}

export default PayBillMerchant;