import React from "react";
import Navbar from "../componentes/Navbar";
import ReactPaginate from 'react-paginate';
import axios from "axios";
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';

/**
 * Componente encargado de listar los clientes con falta de pago
 */
class NoPayClients extends React.Component {

    /**
    * Atributo de estado que contiene los atributos variables
    */
    state = {
        token: '',
        role: -1,
        searchText: "",
        currentPage: 0,
        perPage: 10, // Número de elementos por página
        pageCount: 0,
        clients: [],
        loading: false
    };

    /**
    * Referencia a el campo que contiene la información que ingresa el usuario de busqueda
    */
    searchRef = React.createRef()

    /**
     * Metodo utilizado para obtener el valor de la busqueda que ingresó el usuario a partir de la referencia creada
     * @param {*} event 
     */
    handleSearchChange = (event) => {
        this.setState({ searchText: this.searchRef.current.value });
    }

    /**
     * Metodo utilizado para manejar la paginación de la tabla
     * @param {*} param0 Página seleccionada
     */
    handlePageChange = ({ selected }) => {
        this.setState({
            currentPage: selected,
            selectedElement: null, // Reinicia el elemento seleccionado al cambiar de página
        });
    };

    /**
     * Metodo utilizado para cargar los clientes falta de pago 
     */
    async componentDidMount() {
        const token = localStorage.getItem('loggedIvuUser')
        const [header, payload, signature] = token.split('.');

        // Decodificar la carga útil (payload) que contiene la información del usuario
        const decodedPayload = JSON.parse(atob(payload));

        const role = decodedPayload['role']
        this.setState({ token: token, role: role })
        const config = {
            method: 'get',
            url: global.URLBack + `/dashBoard/superUser/invoice/nonPayment`,
            headers: {
                'Authorization': 'Bearer ' + token
            },
            withCredentials: true
        }
        this.setState({ loading: true })
        await axios(config)
            .then(res => {
                this.setState({ loading: false })
                var pageC = Math.ceil(res.data.length / this.state.perPage);
                this.setState({
                    clients: res.data,
                    pageCount: pageC
                })

            }).catch(err => {
                this.setState({ loading: false })
                localStorage.removeItem('loggedIvuUser')
                //localStorage.removeItem('role')
            })

    }

    /**
     * Renderiza el componente Navbar según el estado actual de la variable role.
     *
     * @return El componente Navbar con la propiedad SupUser establecida al valor de la variable role.
     */
    renderNavbar() {
        // Puedes acceder al estado actualizado aquí y pasar el valor a Navbar
        const { role } = this.state;
        if (Number(role) !== -1) {
            return <Navbar SupUser={role} />;
        }

    }


    /**
     * Metodo de renderizado
     * @returns Html que contiene un campo de busqueda para filtrar los clientes por algun valor y la tabla con los clientes
     */
    render() {

        // Aplicar el filtro
        const filteredTerminals = this.state.clients.filter(client =>
            Object.entries(client).some(([key, value]) =>
                key !== 'merchantNumber' && String(value).toLowerCase().includes(this.state.searchText.toLowerCase())
            )
        );

        const { currentPage, perPage, clients } = this.state;
        const offset = currentPage * perPage;
        var pageC = this.state.pageCount
        var currentPageData;
        if (this.state.searchText.length !== 0) {

            currentPageData = filteredTerminals.slice(offset, offset + perPage);
            pageC = Math.ceil(filteredTerminals.length / this.state.perPage)
        } else {
            currentPageData = clients.slice(offset, offset + perPage);
        }

        return (
            <React.Fragment>
                {this.renderNavbar()}
                <div className='mainn'>
                    <div className="sonn1">
                        <p className='titulo '><strong>Clientes con falta de pago</strong></p>

                    </div>
                    <div className="sonn">
                        {this.state.loading ?
                            <div className="spinner"></div>
                            :
                            <React.Fragment>
                                <Row className="g-2 search-bar" >
                                    <Col  >
                                        <p className="search-label"><strong>Buscar: </strong></p>
                                    </Col>
                                    <Col style={{ maxWidth: "500px" }}>
                                        <Form>
                                            <Form.Group className="mb-3" id="ControlInput1">
                                                <Form.Label
                                                    className="mb-3"
                                                >    </Form.Label>
                                                <Form.Control type="text" placeholder="Ingrese su busqueda" ref={this.searchRef} onChange={this.handleSearchChange} />
                                            </Form.Group>
                                        </Form>

                                    </Col>
                                </Row>

                                <table className="table">
                                    <thead className="tr1">
                                        <tr>
                                            <th scope="col">Nombre</th>
                                            <th scope="col">Negocio</th>
                                            <th scope="col">Dirección</th>
                                            <th scope="col">Teléfono</th>
                                            <th scope="col">Número de comerciante</th>
                                            <th scope="col">Estado</th>
                                            <th scope="col">Útlimo pago</th>
                                            <th scope="col">Balance</th>
                                            <th scope="col">Ultima transmisión</th>
                                            <th scope="col">Fecha de expiración</th>
                                            <th scope="col"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {currentPageData.map((client, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>{client.username + " " + client.lastName + " " + client.secondLastName}</td>
                                                    <td>{client.merchantName}</td>
                                                    <td>{client.address}</td>
                                                    <td>{client.phone}</td>
                                                    <td>{client.merchantID}</td>
                                                    <td>{client.active === 1 ? "Activo" : "Inactivo"}</td>
                                                    <td>{client.lastPayment}</td>
                                                    <td>{client.balance}</td>
                                                    <td>{client.lastTransmision}</td>
                                                    <td>{client.expirationDate}</td>
                                                    <td>
                                                        <Link to={`/admin/payBill/${client.merchantID}`}>
                                                            <Button variant="primary">Entrar pago</Button>
                                                        </Link>
                                                    </td>
                                                </tr>
                                            )
                                        }

                                        )}
                                    </tbody>
                                </table>
                                {/* Componente de paginación */}
                                <ReactPaginate
                                    pageCount={pageC}
                                    onPageChange={this.handlePageChange}
                                    containerClassName="pagination"
                                    activeClassName="active"
                                    forcePage={this.state.currentPage} // Establece la página actual activa en el componente de paginación
                                />
                            </React.Fragment>
                        }
                        {/*  <div className="pagination">{
              this.pagination()?.pgs.map((item, i) => (
                <a key={i} onClick={() => this.setState({ currentPage: item })}>{item + 1}</a>
              ))
            }</div> */}
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default NoPayClients