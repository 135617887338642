import React from "react";
import Navbar from "../componentes/Navbar";
import { Link } from "react-router-dom";
import { faTachometerAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import { Titulo } from "../elements/Forms";
import "../styles.css";

class AccountCreated extends React.Component {
  render() {
    return (
      <div>
        <Navbar />
        <div id="container-rp">

        <div className="son2">
          <div>
            <Titulo>Registrarse</Titulo>
          </div>
          <div>
            <Breadcrumbs
              className="breadcrumbs"
              separator=">"
              aria-label="breadcrumb"
              >
              <Link className="link-rp"      to="/">
                <FontAwesomeIcon className="icon" icon={faTachometerAlt} />
                Panel
              </Link>
              <Link className="link-rp"  to="/register/new/accountCreated/">
                Registrarse
              </Link>
            </Breadcrumbs>
          </div>
        </div>
        <section className="content">
          <div className="row">
            <div id="col-card-rp" className="col-md-offset-3 col-md-8">
              <div className="card">
                <div className="card-body">
                  <h5 className="card-title">Cuenta creada exitosamente </h5>
                  <hr/>
                  <p id="card-txt-AcC" className="card-text">
                    Bienvenido a Ivu Control. <br /> <br /> Su cuenta se creó con éxito. 
                    <br />
                    <br />Para adquirir el servicio de ivu control es necesario 
                    que ingrese a la plataforma con su usuario y contraseña 
                    y suba el formulario SC2916 y  el 'Registro Comerciante'.{" "}  <br/><br/> Un
                    especialista en cuentas se comunicará con usted para obtener
                    más información y configurar el servicio.<br/><br/><br/><br/>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
                </div>
        <footer>
		© 2021 IvuControlPR 
	</footer>
      </div>
    );
  }
}
export default AccountCreated ;
