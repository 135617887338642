import React from "react";
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Table from 'react-bootstrap/Table';
import Navbar from "../componentes/Navbar";
import ReactPaginate from 'react-paginate';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import { withRouter } from "react-router-dom";
import axios from "axios";

/**
 * Componente encargado de enseñar los reportes de cobro de todos los resellers o de un reseller en especifico
 */
class ResellerPayReport extends React.Component {

    /**
     * Constructor para el componente.
     *
     * @param  props - El objeto de props pasado al componente.
     */
    constructor(props) {
        super(props);
        this.state = {
            role: -1,
            token: "",
            currentPage: 0,
            perPage: 10, // Número de elementos por página
            pageCount: 0,
            loading: false,
            reports: [],
            searchText: "",
            idUser: this.props.match.params.idUser
        }
    }

    /**
    * Referencia a el campo que contiene la información que ingresa el usuario de busqueda
    */
    searchRef = React.createRef()


    /**
   * Metodo utilizado para obtener el valor de la busqueda que ingresó el usuario a partir de la referencia creada
   * @param {*} event
   */
    handleSearchChange = (event) => {
        this.setState({ searchText: this.searchRef.current.value });
    }

    /**
     * Método del ciclo de vida del componente que se llama después de que el componente se monta.
     * Recupera el ID de usuario de los parámetros de la URL, decodifica la carga útil del
     * token del usuario registrado, establece el rol del usuario en el estado del componente
     * y realiza una solicitud HTTP para obtener informes de cuentas basados en el ID de usuario.
     *
     */
    componentDidMount() {
        const { idUser } = this.props.match.params;

        const token = localStorage.getItem('loggedIvuUser')
        const [header, payload, signature] = token.split('.');

        // Decodificar la carga útil (payload) que contiene la información del usuario
        const decodedPayload = JSON.parse(atob(payload));

        const role = decodedPayload['role']
        this.setState({ token: token, role: role })

        let config = {}
        if (idUser !== undefined) {
            config = {
                method: 'get',
                url: global.URLBack + `/dashBoard/reseller/accountsReport/${idUser}`,
                headers: {
                    'Authorization': 'Bearer ' + token
                },
                withCredentials: true
            }

            this.setState({ loading: true })
            axios(config).then(response => {
                this.setState({ loading: false })
                if (Number(response.status) === 200) {
                    var pageC = Math.ceil(response.data.length / this.state.perPage);
                    this.setState({ reports: response.data, pageCount: pageC })
                }
            }).catch(error => {
                this.setState({ loading: false })
                localStorage.removeItem('loggedIvuUser')
                window.location.reload()
            })
        } else {
            config = {
                method: 'get',
                url: global.URLBack + `/dashBoard/reseller/accountsReport`,
                headers: {
                    'Authorization': 'Bearer ' + token
                },
                withCredentials: true
            }

            this.setState({ loading: true })
            axios(config).then(response => {
                this.setState({ loading: false })
                if (Number(response.status) === 200) {
                    let grupedReports = this.groupData(response.data)

                    let grupedReportsList = Object.values(grupedReports)
                    var pageC = Math.ceil(grupedReportsList.length / this.state.perPage);
                    this.setState({ reports: response.data, pageCount: pageC })
                }
            }).catch(error => {
                this.setState({ loading: false })
                localStorage.removeItem('loggedIvuUser')
                window.location.reload()
            })
        }


    }

    /**
     * Renderiza el componente Navbar en base al estado actual.
     *
     * @return  El componente Navbar si el rol no es -1, de lo contrario, null.
     */
    renderNavbar() {
        // Puedes acceder al estado actualizado aquí y pasar el valor a Navbar
        const { role } = this.state;

        if (Number(role) !== -1) {
            return <Navbar SupUser={role} />;
        }

    }

    /**
 * Metodo utilizado para manejar la paginación de la tabla
 * @param {*} param0 Pagina seleccionada
 */
    handlePageChange = ({ selected }) => {
        this.setState({
            currentPage: selected,
            selectedElement: null, // Reinicia el elemento seleccionado al cambiar de página
        });
    };

    /**
     * Metodo encargado de retornar la información agrupada por revendedor
     * @param {*} data reportes de cobros de los revendedores
     * @returns diccionario con los datos agrupados
     */
    groupData = (data) => {

        // Agrupar datos por idReseller
        const groupedData = data.reduce((acc, item) => {
            if (!acc[item.idReseller]) {
                acc[item.idReseller] = [];
            }
            acc[item.idReseller].push(item);
            return acc;
        }, {});

        return groupedData;
    };

    /**
   * Renderiza el componente.
   *
   * @return  El componente renderizado.
   */
    render() {
        let grupedReports = {}
        let grupedReportsList = []
        // Aplicar el filtro
        const filteredTerminals = this.state.reports.filter(terminal =>
            Object.values(terminal).some(value =>
                String(value).toLowerCase().includes(this.state.searchText.toLowerCase())
            )
        );

        const { currentPage, perPage, reports } = this.state;
        const offset = currentPage * perPage;
        var pageC = this.state.pageCount


        var currentPageData;
        if (this.state.searchText !== "") {
            grupedReports = this.groupData(filteredTerminals)

            grupedReportsList = Object.values(grupedReports)
            currentPageData = grupedReportsList.slice(offset, offset + perPage);
            pageC = Math.ceil(grupedReportsList.length / this.state.perPage)
        } else {
            grupedReports = this.groupData(this.state.reports)
            grupedReportsList = Object.values(grupedReports)
            currentPageData = grupedReportsList.slice(offset, offset + perPage);
        }


        return (
            <React.Fragment>
                {this.renderNavbar()}
                <div className='mainn'>
                    <div className="sonn1" style={{ height: "50px" }}>
                        <p className='titulo'><strong>Reporte de cuenta{this.state.idUser == undefined ? "s" : null}</strong></p>
                    </div>
                    <div className="sonn">
                        {this.state.loading ?
                            <div className="spinner"></div>
                            :
                            <React.Fragment>
                                {grupedReports.length <= 0 ?
                                    <Alert variant="primary" className="mt-5">
                                        Aun no cuenta con reportes
                                    </Alert> :
                                    <React.Fragment>
                                        {this.state.idUser === undefined ?
                                            <Row className="g-2 search-bar" >
                                                <Col >
                                                    <p className="search-label"><strong>Buscar: </strong></p>
                                                </Col>
                                                <Col style={{ maxWidth: "500px" }} >
                                                    <Form>
                                                        <Form.Group className="mb-3" id="ControlInput1">
                                                            <Form.Label
                                                                className="mb-3"
                                                            >    </Form.Label>
                                                            <Form.Control type="text" placeholder="Ingrese su busqueda" ref={this.searchRef} onChange={this.handleSearchChange} />
                                                        </Form.Group>
                                                    </Form>

                                                </Col>
                                            </Row> : null
                                        }


                                        <Table className="table table-striped">
                                            <thead className="thead-dark">
                                                <tr>
                                                    <th scope="col" className="col-2 text-center">Nombre del revendedor</th>
                                                    <th scope="col" className="col-2 text-center">Merchant ID</th>
                                                    <th scope="col" className="col-2 text-center">Cliente</th>
                                                    <th scope="col" className="col-3 text-center">Ultimo Pago</th>
                                                    <th scope="col" className="col-1 text-center">Comision</th>
                                                    <th scope="col" className="col-1 text-center">Fecha de pago</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {currentPageData.map((account, index) => {
                                                    const merchantID = account.map(report => report.merchantId).join('<br>');
                                                    const client = account.map(report => report.clientName).join('<br>');
                                                    const lastPayment = account.map(report => report.detailService).join('<br>');
                                                    const comissions = account.map(report => `$ ${report.commission}`).join('<br>');
                                                    const payDate = account.map(report => report.paymentDate).join('<br>');

                                                    return (
                                                        <tr key={index}>
                                                            <td className="col-2 text-center">{account[0].resellerName}</td>
                                                            <td dangerouslySetInnerHTML={{ __html: merchantID }} className="col-2 text-center"></td>
                                                            <td dangerouslySetInnerHTML={{ __html: client }} className="col-2 text-center"></td>
                                                            <td dangerouslySetInnerHTML={{ __html: lastPayment }} className="col-2 text-center"></td>
                                                            <td dangerouslySetInnerHTML={{ __html: comissions }} className="col-2 text-center"></td>
                                                            <td dangerouslySetInnerHTML={{ __html: payDate }} className="col-2 text-center"></td>
                                                        </tr>
                                                    );
                                                })}
                                            </tbody>
                                        </Table>



                                        <div className="d-flex justify-content-center">
                                            <ReactPaginate
                                                pageCount={pageC}
                                                onPageChange={this.handlePageChange}
                                                containerClassName="pagination"
                                                activeClassName="active"
                                                forcePage={this.state.currentPage}
                                            />
                                        </div>
                                    </React.Fragment>

                                }

                            </React.Fragment>
                        }
                    </div>
                </div>
            </React.Fragment>
        )

    }


}

export default withRouter(ResellerPayReport);