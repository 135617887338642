import Navbar from "../componentes/Navbar";
import React from "react";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTachometerAlt,
  faExclamationTriangle,
} from "../../node_modules/@fortawesome/free-solid-svg-icons";
import "../elements/ResetPassword.css";
import '../componentes/GlobalVariables'
import axios from "axios";

class BusinessInfo extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {
      sc: '',
      mc: '',
      formSC: '',
      formMC: '',
      success: null,
      error: null,
      token: null,
      tokenForm : ''
    }       
  };
  
  
  
  async componentDidMount(){
    const token = localStorage.getItem('loggedIvuUser')
    this.setState({token:token})
    const config = {
      method: 'get',
      url: global.URLBack + `/dashBoard/business/haveForms/` + this.props.match.params.idAddress ,
      headers: {         
        'Authorization': 'Bearer ' + token
      },
      withCredentials: true
    }

    await axios(config)
    .then(res => { 
      if (res.data.error){
        window.location.replace("/dashboard")
      }      
      this.setState({
        formSC:res.data.formSC,
        formMC:res.data.formMC,
        tokenForm: res.data.token})           
         
    }).catch(err => {
      
      localStorage.removeItem('loggedIvuUser')
      //localStorage.removeItem('role')
    })
  }
  handleOnFileChange = (e) => {
    let files = e.target.files;
    this.setState({ [e.target.name]: files[0] });    
  }
  submitLogin(e) {
    const formData = new FormData();
    this.setState({success:null, error:null})
    formData.append(
      'pdf',
      this.state.sc      
    );  
    const config = {
      method: 'post',
      url: global.URLBack + `/dashBoard/business/saveFormSC/` + this.props.match.params.idAddress,
      headers: { "Content-Type": "multipart/form-data",
        'Authorization': 'Bearer ' + this.state.token },      
      data: formData,
      withCredentials: true,
    }

    axios(config)
      .then(res => {
        this.setState({success:true, formSC: true,          
          tokenForm: res.data.tokenForm,
          sc: ''         
        })       
                 
      })
      .catch(err => { this.setState({error:true})
    })

  }

  submitUpdateSC(e) {
    const formData = new FormData();
    this.setState({success:null, error:null})
    formData.append(
      'pdf',
      this.state.sc      
    );

    
    const config = {
      method: 'put',
      url: global.URLBack + `/dashBoard/business/updateFormSC/` + this.props.match.params.idAddress,
      headers: { "Content-Type": "multipart/form-data",
        'Authorization': 'Bearer ' + this.state.token },      
      data: formData,
      withCredentials: true,
    }

    axios(config)
      .then(res => {
        this.setState({success:true})        
            
      })
      .catch(err => {  this.setState({error:true})
    })

  }

  submitLoginMC(e) {
    const formData = new FormData();     
    this.setState({success:null, error:null})
    formData.append(
      'pdf',
      this.state.mc      
    );

    const config = {
      method: 'post',
      url: global.URLBack + `/dashBoard/business/saveFormMC/` + this.props.match.params.idAddress,
      headers: { "Content-Type": "multipart/form-data" ,
      'Authorization': 'Bearer ' + this.state.token},   
      data: formData,
      withCredentials: true,
    }

    axios(config)
      .then(res => {        
        
        this.setState({success:true, formMC: true,
          tokenForm: res.data.tokenForm,
          mc: ''
        })  

      })
      .catch(err => {this.setState({error:true})})
      

  }

  submitUpdateMC(e) {
    const formData = new FormData();
    this.setState({success:null, error:null})
    formData.append(
      'pdf',
      this.state.mc      
    );  

    const config = {
      method: 'put',
      url: global.URLBack + `/dashBoard/business/updateFormMC/` + this.props.match.params.idAddress,
      headers: { "Content-Type": "multipart/form-data",
        'Authorization': 'Bearer ' + this.state.token },      
      data: formData,
      withCredentials: true,
    }

    axios(config)
      .then(res => {
        this.setState({success:true})        
               
      })
      .catch(err => { this.setState({error:true})
    })

  }

  handleDownloadSC(e) {    
    window.open( global.URLBack + `/dashBoard/business/downloadFormSC/` + this.state.tokenForm, "_blank")
  }

  handleDownloadMC(e) {    
    window.open( global.URLBack + `/dashBoard/business/downloadFormMC/` + this.state.tokenForm, "_blank")
    
  }


  
  handleClick = e =>{
    e.preventDefault();
    window.history.back()
    
  };
  handleClick2 = e =>{
    e.preventDefault();
    window.location.reload()
    
  };
  
  
  
  render() {
    return (
      <div>
        <Navbar />
        <div id="container-rp" className="container">
          <section id="content-header-rp" className="content-header">
            <h1 id="h1-grid">
              Información Comercial
              <Breadcrumbs
                className="breadcrumbs"
                separator=">"
                aria-label="breadcrumb"
              >
                <Link className="link-rp" color="inherit" to="/dashboard">
                  <FontAwesomeIcon className="icon" icon={faTachometerAlt} />
                  Panel
                </Link>
                <Link className="link-rp" color="inherit" onClick={this.handleClick}>
                  Mi dirección
                </Link>
                <Link className="link-rp" color="inherit" onClick={this.handleClick2}>
                  Información Comercial
                </Link>
              </Breadcrumbs>
            </h1>
          </section>
          {this.state.error  === true && this.state.success  === null ? (<div className="alert alert-danger" role="alert"><FontAwesomeIcon icon ={faExclamationTriangle} /> 
  Ha ocurrido un error al subir el archivo
</div> ): null}
{this.state.error  === null && this.state.success  === true ? (<div className="alert alert-success" role="alert"><FontAwesomeIcon icon ={faExclamationTriangle} /> 
  Se ha subido el archivo exitosamente
</div> ): null}
          <section className="content">
            <div className="row">
              <div className="col-6">
                <div className="card">
                  <div className="card-body">
                    <h5 className="card-title">Formulario SC2916</h5>
                    <hr></hr>
                    <p id="certificado-bi" className="card-text">
                    (CERTIFICADO DE COMPRAS EXENTAS Y DE SERVICIOS SUJETOS AL IVU-ESPECIAL DE 4%)Por favor <a href="https://ivucontrolpr.com/images/Formulario%20SC_2916_2015_1.pdf" target="_blank" rel="noreferrer">Descargar, </a>completar,firmar y cargar
                    </p>
                    <form className="form-group">
                          {this.state.formSC ? <div>
                            <label><b>Archivo existente</b></label>
                            <p>Si desea actualizar la información presione "seleccionar archivo"</p>
                            <div className="div-form-bi">
                            
                            <input type="file" id="file" name="sc" placeholder="Seleccionar archivo"
                            onChange={this.handleOnFileChange}/>
                        </div>
                            <div className="row">

                              {this.state.sc ?
                            <div className="col-5">
                            <button id="btn1-bi" className="btn btn-light" type="button"
                            onClick={this
                              .submitUpdateSC
                              .bind(this)}>Actualizar formulario SC2916</button>
                              </div>
                              : null}
                            <div className="col-6">
                            <button id="btn1-bi" className="btn btn-light" 
                            onClick={this.handleDownloadSC.bind(this)}
                        >Visualizar formulario SC2916</button>
                            </div>
                            </div>
                          </div>
                          : <div>
                            <div className="div-form-bi">
                            <label for="file1"> Seleccionar Archivo</label>
                            <input type="file" id="file" name="sc" placeholder="Seleccionar archivo"
                            onChange={this.handleOnFileChange}/>
                        </div>
                        <div className="row">
                        {this.state.sc ? <div className="col-5">
                        <button id="btn1-bi" className="btn btn-light" type="button"
                        onClick={this
                          .submitLogin
                          .bind(this)}>Subir formulario SC2916</button>

                        </div> : null }
                        
                          
                          </div>
                            </div>}
                    </form>
                    <div>{/* Insertar aqui el documento para descargar  */}</div>
                  </div>
      </div>
                 
              </div>
              <div className="col-6">
                <div className="card">
                  <div className="card-body">
                    <h5 className="card-title">Por favor subir su Certificado de Registro de Comerciante</h5>
                    <hr></hr>
                    <form className="form-group">
                        
                          {this.state.formMC ?<div> <label><b>Archivo existente</b></label>
                          <p>Si desea actualizar la Información presione "seleccionar archivo"</p>
                            <div className="div-form-bi">
                            <input type="file" id="file" name="mc" placeholder="Seleccionar archivo"
                            onChange={this.handleOnFileChange}/> </div> <div className="row">
                              {this.state.mc ? 
                          <div className="col-6">

                        <button id="btn2-bi" className="btn btn-light" type="button"
                        onClick={this
                          .submitUpdateMC
                          .bind(this)}>Actualizar Certificado de Registro de Comerciante</button>
                          </div>
                        : null}
                           <div className="col-6">
                          <button id="btn2-bi" className="btn btn-light" type="button"
                          onClick={this.handleDownloadMC.bind(this)}
                        >Visualizar Registro de Comerciante</button>
                        </div>
                          
                        </div></div>: <div> <div className="div-form-bi"> 
                        <label for="file2"> Seleccionar Archivo</label>
                            <input type="file" id="file2" name="mc" placeholder="Seleccionar archivo"
                            onChange={this.handleOnFileChange}/>
                        </div>
                        <div className="row">
                          {this.state.mc ? <div className="col-7">

<button id="btn2-bi" className="btn btn-light" type="button"
onClick={this
  .submitLoginMC
  .bind(this)}>Subir Certificado de Registro de Comerciante</button>
  </div> : null }
                          
                        </div> </div> }
                            
                    </form>
                          <div>{/* Insertar aqui el documento para descargar  */}</div>
                  </div>
                  <div></div>
                </div>
                
              </div>
            </div>
          </section>
        </div>
        <footer>
		© 2021 IvuControlPR 
	</footer>
      </div>
    );
  }
}

export default BusinessInfo;
