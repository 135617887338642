import React from "react";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Swal from "sweetalert2";
import axios from "axios";

/**
 * Componente encargado de registrar los usuarios (super users o administradoresr read only ))
 * 
 */
class AdminUserRegister extends React.Component {

    /**
     * Constructor para inicializar el estado del componente.
     *
     * @param {object} props - El objeto props que contiene los valores iniciales para el estado.
     * @return {void} 
     */
    constructor(props) {
        super(props);
        this.state = {
            role: -1,
            token: "",
            show: false,
            loading: false,
            user: {
                userName: "",
                lastName: "",
                secondlastname: "",
                email: "",
                password: "",
                idRole: 2
            },
            initial: props.user,
            validated: false,
            validForm: true,
            edit: props.edit
        }
    }

    /**
     * Método del ciclo de vida del componente que se ejecuta después de que el componente ha sido montado.
     * Recupera el token de usuario del almacenamiento local y decodifica la carga útil para obtener la información del usuario.
     * Luego actualiza el estado del componente con el token y el rol del usuario.
     * Si el componente está en modo de edición, establece el estado del usuario en el estado inicial.
     */
    componentDidMount() {
        const token = localStorage.getItem('loggedIvuUser')
        const [header, payload, signature] = token.split('.');

        // Decodificar la carga útil (payload) que contiene la información del usuario
        const decodedPayload = JSON.parse(atob(payload));

        const role = decodedPayload['role']
        this.setState({ token: token, role: role })
        if (this.state.edit) {
            this.setState({
                user: this.state.initial
            })
        }
    }

    /**
 * Metodo utilizado para desplegar el modal
 */
    handleShow = () => {
        this.setState({ show: true })

    };

    /**
   * Metodo utilizado para ocultar el modal
   */
    handleClose = () => {
        if (this.state.edit) {
            this.setState({ show: false, user: this.state.initial, validated: false, validForm: true })
        } else {
            this.setState({ show: false, validated: false, validForm: true })

        }
    };

    /**
     * Restablece el estado del componente estableciendo el objeto de usuario con los valores predeterminados.
     *
     * Sin parámetros.
     * Sin valor de retorno.
     */
    reset() {
        this.setState({
            user: {
                userName: "",
                lastName: "",
                secondlastname: "",
                email: "",
                password: "",
                idRole: 2
            }
        })
    }

    /**
     * Valida el correo electrónico haciendo una solicitud GET al servidor
     * para verificar si el correo ya está registrado.
     *
     * @return {boolean} Retorna true si el correo es válido y no está registrado, false en caso contrario.
     */
    validateEmail() {
        const configEmail = {
            method: "get",
            url: global.URLBack + `/query/register/checkEmail/` + this.state.user.email,
            headers: {
                "Content-Type": "text/plain",
            }
        }


        axios(configEmail)
            .then((res) => {
                if (res.data.res === false) {
                    return false
                } else {
                    return true
                }
            })
            .catch((err) => { });

    }

    /**
     * Metodo encargado de registrar un usuario
     * @param {*} e evento de submit 
     */
    handleSubmit = (e) => {
        e.preventDefault();
        if (this.valdidateForm(e)) {
            const configEmail = {
                method: "get",
                url: global.URLBack + `/query/register/checkEmail/` + this.state.user.email,
                headers: {
                    "Content-Type": "text/plain",
                }
            }


            axios(configEmail)
                .then((res) => {
                    if (res.data.res === false) {
                        Swal.fire({
                            title: "Correo electronico existente",
                            icon: "error"
                        })
                    } else {
                        let method = "post"
                        let urlConfig = "/dashBoard/superUser/superUsers"
                        if (this.state.edit) {
                            method = "put"
                            urlConfig = "/dashBoard/superUser/superUsers/" + this.state.user.idUser
                        }

                        const config = {
                            method: method,
                            url: global.URLBack + urlConfig,
                            headers: {
                                'Authorization': 'Bearer ' + this.state.token
                            },
                            data: this.state.user,
                            withCredentials: true

                        }
                        this.setState({ loading: true })
                        axios(config).then(response => {
                            this.setState({ loading: false })
                            if (response.status === 200) {

                                let message = "Usuario registrado exitosamente"
                                if (this.state.edit) {
                                    message = "Usuario editado exitosamente"
                                    this.props.updateList(response.data, true)
                                    this.setState({
                                        initial: this.state.user
                                    })
                                } else {
                                    this.props.updateList(response.data, false)
                                    this.reset()
                                }
                                Swal.fire({
                                    title: message,
                                    icon: "success"
                                })
                                this.handleClose()

                            } else {
                                this.setState({ loading: false })
                                Swal.fire({
                                    title: "Ha ocurrido un error",
                                    text: response.data.msg,
                                    icon: "error"
                                })
                            }
                        }).catch(err => {
                            this.handleClose()
                            if (this.state.edit) {
                                this.reset()
                            }

                            this.setState({ loading: false })
                            Swal.fire({
                                title: "Ha ocurrido un error",
                                text: "Por favor intentelo más tarde",
                                icon: "error"
                            })
                        })
                    }
                })
                .catch((err) => { });





        }
    }

    /**
    * Metodo utilizado para validar el formulario 
    * @param {*} e Elemento que disparó el evento
    * @returns True si el formulario se encuentra correcto, False en caso contrario.
    */
    valdidateForm = (e) => {
        const form = document.getElementById("formUser");
        this.setState({
            validated: true
        })
        if (form.checkValidity() === false) {
            e.preventDefault();
            e.stopPropagation();
            this.setState({
                validForm: false
            })
            return false
        } else {

            this.setState({
                validForm: true
            })
            return true
        }
    }


    /**
     * Metodo que actualiza el valor de los atributos del estado de acuerdo a lo ingresado en el formulario
     * @param {*} e evento del input
     */
    handleChange = (e) => {
        var { name, value } = e.target;
        if (name === "idRole") {
            value = Number(value)
        }
        this.setState((prevState) => ({
            user: { ...prevState.user, [name]: value }
        }));

        this.valdidateForm(e)
    };

    /**
     * Función de renderizado que devuelve el JSX para el componente.
     *
     * @return {JSX} El JSX para el componente.
     */
    render() {
        const { show, edit, validated, user } = this.state
        return (
            <React.Fragment>
                {edit ?
                    <div className="editButton" style={{ cursor: "pointer" }} onClick={this.handleShow} >   <svg
                        viewBox="0 0 24 24"
                        width="25"
                        height="25"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="m3.99 16.854-1.314 3.504a.75.75 0 0 0 .966.965l3.503-1.314a3 3 0 0 0 1.068-.687L18.36 9.175s-.354-1.061-1.414-2.122c-1.06-1.06-2.122-1.414-2.122-1.414L4.677 15.786a3 3 0 0 0-.687 1.068zm12.249-12.63 1.383-1.383c.248-.248.579-.406.925-.348.487.08 1.232.322 1.934 1.025.703.703.945 1.447 1.025 1.934.058.346-.1.677-.348.925L19.774 7.76s-.353-1.06-1.414-2.12c-1.06-1.062-2.121-1.415-2.121-1.415z"
                            fill="#000000"
                        />
                    </svg></div> :
                    <Button variant="success" onClick={this.handleShow} className="mt-3"> <h5>Nuevo usuario</h5></Button>
                }

                <Modal
                    show={show}
                    onHide={this.handleClose}
                >
                    <Modal.Header closeButton>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 48 48"
                            width="30"
                            height="30"
                            fill="none"
                        >
                            <rect width="48" height="48" fill="white" fillOpacity="0.01" />
                            <circle
                                cx="24"
                                cy="12"
                                r="8"
                                fill="#2F88FF"
                                stroke="#000000"
                                strokeWidth="4"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                            <path
                                d="M42 44C42 34.0589 33.9411 26 24 26C14.0589 26 6 34.0589 6 44"
                                stroke="#000000"
                                strokeWidth="4"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                            <path
                                d="M24 44L28 39L24 26L20 39L24 44Z"
                                fill="#2F88FF"
                                stroke="#000000"
                                strokeWidth="4"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>
                        {!edit ?
                            <Modal.Title id="example-custom-modal-styling-title" className="mod-title" >
                                Registrar usuario
                            </Modal.Title> :
                            <Modal.Title id="example-custom-modal-styling-title" className="mod-title" >
                                Editar usuario
                            </Modal.Title>
                        }

                    </Modal.Header>
                    <Modal.Body>
                        {this.state.loading &&
                            <div className="overlay">
                                <div className="spinner2"></div>
                            </div>
                        }
                        <Form noValidate className="form-style" id="formUser" validated={validated} >
                            <Form.Group className="mb-3" >
                                <Form.Label>Nombre del usuario *</Form.Label>
                                <Form.Control type="text" value={user.userName} required name="userName" onChange={this.handleChange} />
                                <Form.Control.Feedback type="invalid" >
                                    Por favor ingrese el nombre del usuario
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group className="mb-3" >
                                <Form.Label>Apellido del usuario *</Form.Label>
                                <Form.Control type="text" value={user.lastName} required name="lastName" onChange={this.handleChange} />
                                <Form.Control.Feedback type="invalid" >
                                    Por favor ingrese el apellido del usuario
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group className="mb-3" >
                                <Form.Label>Segundo apellido del usuario *</Form.Label>
                                <Form.Control type="text" value={user.secondlastname} required name="secondlastname" onChange={this.handleChange} />
                                <Form.Control.Feedback type="invalid" >
                                    Por favor ingrese el segundo apellido del usuario
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group className="mb-3" >
                                <Form.Label>Usuario *</Form.Label>
                                <Form.Control type="email" value={user.email} pattern="^(?!\s*$).+" required name="email" onChange={this.handleChange} />
                                <Form.Control.Feedback type="invalid" >
                                    Por favor ingrese el usuario valido (correo electrónico)
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group className="mb-3" >
                                <Form.Label>Contraseña del usuario *</Form.Label>
                                <Form.Control type="password" value={user.password} required name="password" pattern="^.{4,30}$" onChange={this.handleChange} />
                                <Form.Control.Feedback type="invalid" >
                                    Por favor ingrese la contraseña del usuario.
                                    La contraseña debe ser de 4 a 12 digitos
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group className="mb-3" >
                                <Form.Label>Función *</Form.Label>
                                <Form.Select aria-label="Default select example" required name="idRole" onChange={this.handleChange} value={user.idRole}>
                                    <option value="2">Administrador</option>
                                    <option value="3">Usuario</option>
                                </Form.Select>
                                <Form.Control.Feedback type="invalid" >
                                    Por favor ingrese la función
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="danger" onClick={this.handleClose}>Cancelar</Button>
                        <Button variant="success" onClick={this.handleSubmit} disabled={!this.state.validForm} >Guardar cambios</Button>
                    </Modal.Footer>
                </Modal >
            </React.Fragment>
        )
    }
}

export default AdminUserRegister;